import { IPerms, IUserData } from "../redux/constants";
import { GetDeclarationsResult, GetKYCComplianceAnswersResult, GetKYCDetailsResult, GetKYCPermissionsResult, GetScoringPointsResult } from "./IApplicationForm";

//#region LoadAllTradersTable
export interface LoadAllTradersTable {
    CompanyName: string;
    Token: string;
    ObjData: LoadAllTradersTableObjData;
}
export interface LoadAllTradersTableObjData {
    PoolIDs: number;
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    KYCStat: string;
}
//#endregion
//#region GetProductDetails
export interface GetProductDetails {
    CompanyName: string;
    Token: string;
    UserName: string;
    ObjData: GetProductDetailsObjData;
}
export interface GetProductDetailsObjData {
    AllocationID: number;
    EnviromentID: number;
    SubscriptionID: number;
    UserID: number;
}
//#endregion
export interface LoadIBDepositReport {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadIBDepositReportObjData;
}
export interface LoadIBDepositReportObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    FromDate: any;
    ToDate: any;
    IBs: any;
}
export interface LoadIBDetailedReport {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadIBDetailedReportObjData;
}
export interface LoadIBDetailedReportObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    FromDate: any;
    ToDate: any;
    IBs: any;
    CommissionType: any;
}
export interface LoadMonthlyFeesTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    Login: string;
    ObjData: LoadMonthlyFeesTableObjData;
}
export interface LoadMonthlyFeesTableObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    Month: string;
    Year: string;
}
export interface LoadRegistrationLogsTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadRegistrationLogsObjData;
}
export interface LoadRegistrationLogsObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    PoolIDs: any;
}
export interface LoadOperationLogsTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadOperationLogsObjData;
}
export interface LoadOperationLogsObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    PoolIDs: any;
    SearchIndex: number;
}
export interface LoadAutoCopyLogsTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadAutoCopyLogsObjData;
}
export interface LoadAutoCopyLogsObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    PoolIDs: any;
    TimePeriod: any;
    FromDate: any;
    ToDate: any;
}
export interface LoadMammLogsTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadMammLogsObjData;
}
export interface LoadMammLogsObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    PoolIDs: any;
    ActionType: any;
    FromDate: any;
    ToDate: any;
}
export interface LoadDocumentsExpirationTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadDocumentsExpirationObjData;
}
export interface LoadDocumentsExpirationObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    PoolIDs: any;
}
export interface LoadOperationRequestTable {
    CompanyName: string;
    Token: string;
    ServerID: number;
    ObjData: LoadOperationRequestObjData;
}
export interface LoadOperationRequestObjData {
    SearchTerm: string;
    SkipTerm: number;
    TakeTerm: number;
    OrderByTerm: string;
    OrderByDir: number;
    PoolIDs: any;
}
//#region LoadWLsTable
export interface LoadWLsTable {
    CompanyName: string;
    Token: string;
    ObjData: LoadWLsTableObjData;
    ServerID: number;
}
export interface LoadWLsTableObjData {
    //some produced from datatable
    PoolIDs: string; //from store may be a list
}
//#endregion
//#region LoadMasterTable
export interface LoadMasterTable {
    CompanyName: string;
    Token: string;
    ObjData: LoadMasterTableObjData;
    ServerID: number;
}
export interface LoadMasterTableObjData {
    PoolIDs: number;
    Strategies: string;
}
//#endregion
//#region Pending Trades
export interface GetPendingTrades {
    CompanyName: string;
    Token: string;
    ObjData: GetPendingTradesObjData;
    ServerType: string;
    ServerName: string;
    Login: string;
    BUID: string;
}
export interface GetPendingTradesObjData {
    //fills skipterm and other from table
}
//#endregion
export interface YoonitRequestNoParam {
    Token: string;
    CompanyName: string;
    CompanyID?: string;
    UserName?: string;
    ServerID?: number;
    ServerIDs?: string;
    ProfileID?: number;
    ServerName?: string;
    ServerType?: string;
    Login?: number;
    UserAuthID?: string;
    AUID?: string;
}
export interface YoonitResponse<T> {//all same type T here
    IsManagerExist: boolean;
    ListRes: Array<T>;
    LiveRes: T;
    TablesRes: TableResults<T>;
    IntRes: number;
    ActionRes: boolean;
    IsException: boolean;
    IsAuth: boolean;
    IsTokenExpired: boolean;
    recordsTotal: number;
    recordsFiltered: number;
    data: T;
    ManagerResponse: any | null;
}
export interface YoonitResponseT2<T1, T2> { // used in APPlication form with different types
    IsManagerExist: boolean;
    ListRes: Array<T2>;
    LiveRes: T1;
    TablesRes: TableResults<T1>;
    IntRes: number;
    ActionRes: boolean;
    IsException: boolean;
    IsAuth: boolean;
    IsTokenExpired: boolean;
}
export interface TableResults<T> {
    ReturnList: Array<T>;
    TotalRows: number;
    TotalDisplayRecords: number;
}
export class GUTraders {
    MasterLogin: string = '';
    ProfileID: number = 0;
    AccountID: number = 0;
    ServerID: number = 0;
    ServerType: string = "";
    ServerName: string = "";
    ARuleID: number = 0;
    ARuleName: string = "";
    Currency: string = "";
    AutoForcePFOnExclude = false;
}

export class DashboardBSTradesPerSymbol {
    public NumberOfBuyTrades: number[] = [];
    public NumberOfSellTrades: number[] = [];
    public Symbol: string[] = [];
}

export class DashboardClass {
    //turn to class
    public AccountID: number = 0;
    public AllocPerLogins: string = "";
    public Balance: number = 0;
    public BSTradesPerSymbol: DashboardBSTradesPerSymbol;
    public Credit: number = 0;
    public Currency: string = "";
    public Equity: number = 0;
    public FreeMargin: number = 0;
    public GUKYCStatus: GUKYCStatus;
    public IBBalance: number = 0;
    public IsMM: boolean = false;
    public Leverage: string = "";
    public Login: string = "";
    public Margin: number = 0;
    public MarginLevel: number = 0;
    public NetAssetValue: number = 0;
    public NetReturnPer: number = 0;
    public OpenPositionSummary: OpenPositionSummary[] = [];
    public Platform: string = "";
    public Profit: number = 0;
    public RecentActivities: TraderRecentActivities[] = [];
    public ROIPer: number = 0;
    public ServerName: string = "";
    public SignalActivities: SignalActivities;
    public TradesPerSymbol: DashboardTradesPerSymbol[] = [];
    public YTDROIChart: string = "";

    constructor() {
        this.BSTradesPerSymbol = new DashboardBSTradesPerSymbol();
        this.SignalActivities = new SignalActivities();
        this.GUKYCStatus = new GUKYCStatus();
    }
}
export interface DashboardTradesPerSymbol {
    name: string;
    y: number;
}
export class GUKYCStatus {
    Creationdate: Date = new Date();
    KYCID: number = 0;
    Reason: string = "";
    Status: string = "";
}
export interface OpenPositionSummary {
    FloatingPL: number;
    MarketPrice: number;
    NetLots: number;
    PriceDelta: number;
    Symbol: string;
    Type: string;
    VWapPrice: number;
}
export interface ProfileCopyDetails {
    Amount: number;
    ARuleName: string;
    CopyingAccount: string;
    CreationDate: Date;
    CreationDateStr: string;
    IsReverse: string;
    ProfileCopied: string;
    Status: string;
}
export class SignalActivities {
    ProfilesCopyMe: ProfileCopyDetails[] = [];
    ProfilesICopy: ProfileCopyDetails[] = [];
}
export interface TraderRecentActivities {
    CreationDate: Date;
    CreationDateStr: string;
    Status: string;
    Type: string;
}
export interface DashboardTradesPerSymbol {
    name: string;
    y: number;
}
//#region new API Interfaces
///Trader
//dashboard
//export class GetBUTraders {
//    Token: string = "";
//    ObjData: number = -1;
//    CompanyName: string = "";
//    UserName: string = "";
//    ServerID: number = -1;
//    ServerName: string = "";
//    ServerType: number = -1;
//}
export class GetBUTraders {
    Token: string = "";
    ObjData: string = "";//BUID
    CompanyName: string = "";
}
export class GetDashboardData {
    public CompanyName: string = "";
    public ServerName: string = "";
    public ServerType: string = "";
    public ServerID: number = -1;
    public Token: string = "";
    public Login: string = "";
    public UserName: string = "";
    public ObjData: {
        ProfileID: number
        AccountID: number
        ClientID: string
        BUID: string
    } = {
            ProfileID: -1, AccountID: -1, ClientID: "", BUID: ""
        }
}

export class BUTraders {
    MasterLogin: string = '';
    ProfileID: number = 0;
    AccountID: number = 0;
    ServerID: number = 0;
    ServerType: string = '';
    ServerName: string = '';
    ARuleID: number = 0;
    ARuleName: string = '';
    Currency: string = '';
    AutoForcePFOnExclude = false;
}

export class GUTradersOptions extends GUTraders {
    label: string = "No logins";
    value: string = "-1";// return ""  just added for test
}
//Signals
//IB
//Portofolio
//Manage
export class GetAccTypesObject {
    accTypeID: number = -1;
    accType: string = "";
    byPassAccReq: boolean = false;
    byPassAdminReq: boolean = false;
    isDemo: boolean = false;
}
export class AccLeverage {
    accLeverageID: number = -1;
    accLeverage: number = -1;
    accTypeID: number = -1;
}
export class Currency {
    AccCurrencyID: number = -1;
    AccCurrency: string = "";
    AccCurrencyPerm: boolean = false;
    AccTypeID: number = -1;
}
export class LoadNewSubAccDataClass {
    accLeverage: Array<AccLeverage> = [];
    accType: any[] = [];//change here
    currency: Array<Currency> = [];
    myAccount: string = "";
}

export class TransactionDataAccounts {
    accountID: number = -1;
    login: string = "";
}
export class TransactionDataPaymentList {
    pmid: number = -1;
    paymentName: string = "";
    isDeposit: boolean = false;
    pmDescription: string = "";
}
export class TransactionDataBankAccountList {
    baid: number = -1;
    guid: number = -1;
    benifName: string = "";
    benifAddress: string = "";
    benifAccNumber: string = "";
    bankName: string = "";
    bankAddress: string = "";
    swiftCode: string = "";
    iban: string = "";
    creationDate: string = "";
}
export class LoadTransactionDataClass {
    currency: Array<Currency> = [];
    accounts: Array<TransactionDataAccounts> = [];
    myCurrency: string = "";
    myAccount: string = "";
    paymentList: Array<TransactionDataPaymentList> = [];
    bankAccountList: Array<TransactionDataBankAccountList> = [];
}
export class LoadEditAccInfoDataAccType {
    accTypeID: number = -1;
    accType: string = "";
}
export class LoadEditAccInfoDataClass {
    accLeverage: Array<AccLeverage> = [];
    accType: Array<LoadEditAccInfoDataAccType> = [];
    currency: Array<any> = [];
    myAccount: string = "";
}
//Logs
//Analytics
export class GetLiveOverviewTableClass {
    gainValue: number = -1;
    netAssetValue: number = -1;
    allocPer: any = "";//make sure they are string and change
    allocPerLogins: any = "";
    netAsset: any = "";
    gain: any = "";
    depWith: any = "";
    isAuth: boolean = false;
}
export class GetTradeAnalysisChartClass {
    isAuth: boolean = false;
    avgGain: number = -1;
    avgLoss: number = -1;
    bestWorst: any = "";
    winLoose: any = "";
    volAllocSymbols: any = "";
    avgDurationSymbols: any = "";
}
//#endregion

// LoadIBLeadsTable:

export interface GetIBLeadsTableResult {
    Name: string;
    Country: string;
    City: string;
    State: string;
    Address: string;
    Email: string;
    Phone: string;
    IsConverted: boolean;
    CreationDateStr: string;
    CreationDate: Date | string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadActiveClientsTable:

export interface GetActiveClientsTableResult {
    Login: string;
    Name: string;
    Country: string;
    Equity: number;
    ServerType: string;
    Currency: string;
    CreationDateStr: string;
    CreationDate: Date | string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadInActiveClientsTable:

export interface GetInActiveClientsTableResult {
    Login: string;
    Name: string;
    Country: string;
    Equity: number;
    ServerType: string;
    Currency: string;
    CreationDateStr: string;
    CreationDate: Date | string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadClientBreakDownTable:

export interface GetClientBreakDownTableResult {
    AccountID: number;
    Account: string;
    Name: string;
    Lots: number;
    NumberOfTrades: number;
    RealizedCommission: number;
    RealizedProfit: number;
    RealizedSwaps: number;
    TotalDeposit: number;
    TotalWithdrawal: number;
    ProfitAdjustments: number;
    ClosingBalance: number;
    ClosingEquity: number;
    ClosingCredit: number;
    RealProfit: number;
    UnrealizedProfitCForward: number;
    UnrealizedProfitBForward: number;
    UnrealizedNetProfit: number;
    NetRevenue: number;
    TotalCommission: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadIBBreakDownTable:

export interface GetIBBreakDownTableResult {
    IBAccountID: number;
    IBAccount: string;
    Lots: number;
    NumberOfTrades: number;
    RealizedCommission: number;
    RealizedProfit: number;
    RealizedSwaps: number;
    TotalDeposit: number;
    TotalWithdrawal: number;
    ProfitAdjustments: number;
    ClosingBalance: number;
    ClosingEquity: number;
    ClosingCredit: number;
    RealProfit: number;
    UnrealizedProfitCForward: number;
    UnrealizedProfitBForward: number;
    UnrealizedNetProfit: number;
    NetRevenue: number;
    TotalCommission: number;
    DirectClients: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadTransactionGrpByIBTable:

export interface GetTransactionGrpByIBTableResult  //Remove Email
{
    ClientID: string; //change accountid(int) to ClientID(string)
    IBCode: string;
    Wallet: string; // change Account to Wallet
    Commission: number | null;
    VolumeLot: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}


// LoadTransactionGrpByAccTable:

export interface GetTransactionGrpByAccTableResult {
    accountid: number;
    Account: string;
    Email: string;
    Commission: number;
    VolumeLot: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadTransactionGrpBySymbolTable:

export interface GetTransactionGrpBySymbolTableResult {
    accountid: number;
    Account: string;
    Email: string;
    Symbol: string;
    Commission: number;
    VolumeLot: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadIBTransactionGroupingTable:

export interface GetIBTransactionGroupingTableResult {
    TimeStr: string;
    Time: Date | string;
    IBName: string;
    AccNbr: string;
    Ticket: string;
    EntryType: string;
    Symbol: string;
    Side: string;
    VolumeLot: number;
    Commission: number;
    Balance: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadSubIBTotalTransactionTable:

export interface GetSubIBTotalTransactionTableResult {
    accountid: number;
    IBCode: string;
    Account: string;
    Email: string;
    Commission: number;
    Volume: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

// LoadIBDepositTransactionTable:


// ExportIBDepositReport:
export interface ExportIBDepositReportResult {
    Time: string;
    IBAccount: string;
    Ticket: string;
    Type: string;
    Amount: number;
    Commission: number;
    Balance: number;
}

// ExportIBDetailedReport:
export interface ExportIBDetailedReportResult {
    Time: string;
    IBEmail: string;
    IBAccount: string;
    Ticket: string;
    EntryType: string;
    Symbol: string;
    Side: string;
    VolumeLot: number;
    Commission: number;
    Balance: number;
    Account: string;
    Email: string;
}

//ExportIBTransactionGroupingReport:
export interface ExportIBTransactionGroupingReportResult {
    Time: Date | string;
    IBEmail: string;
    IBAccount: string;
    Ticket: string;
    EntryType: string;
    Symbol: string;
    Side: string;
    Volume: number;
    Commission: number;
    Balance: number;
    Account: string;
    Email: string;
}

//ExportSubIBDetailedReport:
export interface ExportSubIBDetailedReportResult {
    Time: string;
    IBEmail: string;
    IBAccount: string;
    Ticket: string;
    EntryType: string;
    Symbol: string;
    Side: string;
    VolumeLot: number;
    Commission: number;
    Balance: number;
    Account: string;
    Email: string;
}

//ExportTransactionGrpByAccReport:
export interface ExportTransactionGrpByAccReportResult {
    Account: string;
    Email: string;
    Commission: number;
    VolumeLot: number;
}

//ExportTransactionGrpByIBReport:
export interface ExportTransactionGrpByIBReportResult {
    IBCode: string;
    Account: string;
    Email: string;
    Commission: number;
    VolumeLot: number;
}

//ExportTransactionGrpBySymbolReport:
export interface ExportTransactionGrpBySymbolReportResult {
    Account: string;
    Email: string;
    Symbol: string;
    Commission: number;
    VolumeLot: number;
}

//LoadIBDepositTransactionTable:

export interface GetIBDepositTransactionTableResult {
    TimeStr: string;
    Time: Date | string;
    AccNbr: string;
    Ticket: string | null;
    Type: string;
    Amount: number;
    Commission: number;
    Balance: number;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}

//LoadSubIBTotalTransactionTable:

export interface GetSubIBTotalTransactionTableResult {
    accountid: number;
    IBCode: string;
    Account: string;
    Email: string;
    Commission: number;
    Volume: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

//GetLiveOverviewTable: public bool      IsAuth

export class OverviewTables {
    Login: string = '';
    BegNetAssetValue: number = 0;
    EndNetAssetValue: number = 0;
    Deposits: number = 0;
    Withdrawals: number = 0;
    Credit: number = 0;
    Swaps: number = 0;
    Commission: number = 0;
    Gain: number = 0;
}

//GetTradeAnalysisTable:

export interface TradeAnalysisTable {
    IsAuth: boolean;
    AvgTradeDuration: number;
    AvgLotSize: number;
    AvgGain: number;
    //AvgGainPips: number;
    AvgLoss: number;
    //AvgLossPips: number;
    NumTrades: number;
    WinTrades: number;
    LooseTrades: number;
    LongWon: number;
    ShortWon: number;
    BestTrade: number;
    //BestTradePips: number;
    MaxLotSize: number;
    MinLotSize: number;
    WorstTrade: number;
    //WorstTradePips: number;
}

//GetLiveHistPerTable:
export interface HistoricalPerformanceTableHTTP {
    MD: number;
    Year: number;
    ROIPer: number;
    AnnualROIPer: number;
    RowCnt: number;
}

//GetBUData:
export class GetGUDataResult {
    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    UserStatus: string = "";
    ProfileImage: string = "";
    Phone: string = "";
    CountryName: string = "";
    Nationality: string = "";
    Address: string = "";
    ZipCode: string = "";
    EmploymentStatus: string = "";
    TimeZoneCode: string = "";
}

///CRM/GetDropDownLists

export interface GetCountriesResult {
    CountryID: number;
    CountryCode: string;
    CountryName: string;
    CountryFlag: string;
    countryNameCN: string;
    CountryPerm: boolean;
    CompanyID: string;
}

export interface GetSalutationResult {
    SalutationID: number;
    Salutation: string;
}

export interface GetKYCTypesResult {
    KTID: number;
    TypeName: string;
    PoolID: number;
    RequireKYC: boolean;
    KPID: number;
}

//CRM/GetRegSettingPerms
export interface GetRegSettingPermsResult {
    RSID: number;
    Salutation: boolean;
    FirstName: boolean;
    MiddleName: boolean;
    LastName: boolean;
    DateOfBirth: boolean;
    Phone: boolean;
    SecondPhone: boolean;
    EmailPerm: boolean;
    CountryOfBirth: boolean;
    Nationality: boolean;
    MaritalStatus: boolean;
    USAResidentQsn: boolean;
    USATaxPurposesQsn: boolean;
    PoliticallyPersonQsn: boolean;
    DeclaredBankruptcyQsn: boolean;
    Country: boolean;
    ResidentialAddress: boolean;
    City: boolean;
    State: boolean;
    StreetName: boolean;
    BuildingNumber: boolean;
    PostalCode: boolean;
    ConditionsAgreement: boolean;
    ConditionsAgreementTxt: string;
    KTID: number;
}

// Portfolio>>>
// closeTradesPerTicket
// GetOpenPositionBysymbol

// GetOpenPosition
export interface OpenPositionSummaryClass {
    Symbol: string;
    Type: string;
    NetLots: number;
    VWapPrice: number;
    MarketPrice: number;
    PriceDelta: number;
    FloatingPL: number;
}
// GetInvestors
export interface InvestorsClass {
    Login: string;
    InitialInvestment: number;
    Balance: number;
    Credit: number;
    Equity: number;
    MarginUt: number;
    AvailableMargin: number;
    FloatingPL: number;
    InvTag: string;
    LoginTxt: string;
    AmountTxt: string;
    Amount: number;
    Name: string;
    IsReverse: boolean;
    IsReverseTxt: string;
}
export interface GetARuleAmountsResult {
    SID: number;
    Login: string;
    Amount: number;
}
//////
// GetClosedTradesFromStorage

export interface GetMonthlyFeesTableResult {
    Login: string;
    Type: string;
    BeginningNAV: number;
    ENV: number;
    PeriodGL: number;
    CFloss: number;
    MF: number;
    PF: number;
    NetReturn: number;
    NetReturnPer: number;
    HWM: number;
    DW: number;
    EndingGAV: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

export interface GetPerformanceFeesTableResult {
    DateTime: string;
    Date: string;
    Type: string;
    BeginningNAV: number;
    PeriodGL: number;
    MF: number;
    PF: number;
    ENV: number;
    NetReturn: number;
    NetReturnPer: number;
    HWM: number;
    CFloss: number;
    DW: number;
    EndingGAV: number;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

export interface GetBUTransferReqTableResult {
    TRID: number;
    Account: string;
    TransferType: string;
    Amount: number;
    ConvertedAmount: number;
    ToPlatform: number;
    ToLogin: string;
    ToCurr: string;
    ToAccount: string;
    ReqDateStr: string;
    ReqDate: string;
    Status: number;
    StatusStr: string;
    Reason: string;
    Delete: string;
    PaymentDetails: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

export interface GetBUDepositReqTableResult {
    DRID: number;
    Account: string;
    PaymentMethod: string;
    Amount: any;
    ConvertedAmount: number;
    Currency: string;
    ReqDateStr: string;
    ReqDate: string;
    Status: number;
    StatusStr: string;
    Reason: string;
    Delete: string;
    PaymentDetails: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}

export interface GetBUNewReqTableResult {
    ARID: number;
    Platform: string;
    AccLeverage: number;
    AccLeverageStr: string;
    AccType: string;
    AccCurrency: string;
    ReqDateStr: string;
    ReqDate: string;
    Status: number;
    StatusStr: string;
    Reason: string;
    Delete: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//LoadBUInfoReqTable
export interface GetBUInfoReqTableResult {
    LRID: number;
    Account: string;
    Leverage: number;
    LeverageStr: string;
    NewLeverage: number;
    NewLeverageStr: string;
    AccType: string;
    NewAccType: string;
    ReqDateStr: string;
    ReqDate: string;
    Status: number;
    StatusStr: string;
    Reason: string;
    Delete: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//LoadBUCloseReqTable
export interface GetBUCloseReqTableResult {
    DRID: number;
    Account: string;
    ReqDateStr: string;
    ReqDate: string;
    Status: number;
    StatusStr: string;
    Reason: string;
    Delete: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//My accounts >>>
// LoadNewSubAccData
export interface AccLeverages {
    AccLeverageID: number;
    AccLeverage: number;
    AccTypeID: number;
}

export interface AccCurrencies {
    AccCurrencyID: number;
    AccCurrency: string;
    AccCurrencyPerm: boolean;
    AccTypeID: number;
}

// LoadMyAccountsTable
export interface MyAccountsTable {
    LeverageStr: string | null;//change
    Account: string;
    Login: string;
    Currency: string;
    Leverage: number;
    Platform: number;
    ServerName: string;
    PlatformStr: string;
    Type: string;
    CreationDateStr: string;
    CreationDate: string;
    Balance: number;
    IsDemo: boolean;
    AccountID: number;
    ServerID: number;
    AccTypeID: number;
}

//export interface GetPaymentCurrenciesResult {
//    AccCurrency: string;
//    DWRID: number;
//    PMID: number;
//    CurrencyID: number;
//    Rate: number;
//    IsDefault: boolean;
//    CreationDate: string;
//}
//export interface GetBankWireDetailsResult {
//    AccCurrencyID: number;
//    AccCurrency: string;
//    BWID: number | null;
//    BID: number | null;
//    CurrencyID: number | null;
//    BeneficiaryName: string;
//    Address: string;
//    AccountNumber: string;
//    Swift: string;
//    IBAN: string;
//    IsEnabled: boolean | null;
//    IntermediaryBank: string;
//    CorrespondentBank: string;
//}
export interface AccTransactionData {
    Currency: AccCurrencies[];
    Accounts: Accounts[];
    MyCurrency: string;
    MyAccount: string;
    PaymentList: PaymentMethods[];
    BankAccountList: BankAccount[];
    PoolID: number;
}

export interface AccCurrencies {
    AccCurrencyID: number;
    AccCurrency: string;
    AccCurrencyPerm: boolean;
    AccTypeID: number;
}

export interface Accounts {
    AccountID: number;
    Login: string;
}
export interface PaymentMethods {
    PMID: number;
    PaymentName: string;
    Instruction: string;
    IsDeposit: boolean;
    HasTTCopy: boolean;
    PMDescription: string;
    PaymentIcon: string;
    Currencies: string;
}

// GetAccTypes
export interface GetAccTypesResult {
    AccTypeID: number;
    ServerID: number;
    AccType: string;
    ByPassAccReq: boolean
    ByPassAdminReq: boolean
    IsDemo: boolean
    Description: string;
}

// GetWLServers
export interface GetWLServersResult {
    ServerID: number;
    ServerName: string;
    Type: number;
    WLID: number;
    WLName: string;
}
//CopyTraderReq
//Function Name: AddClient

//Request objData Class:
//client page
export class CreateClientClass {
    Client: Client = new Client();
    Contact: Contact = new Contact();
    Trader: TraderMainClass | null = new TraderMainClass();
    Role: Role = new Role();
    CompanyID: string = "";
    Password: string = "";
}

export class Client {
    ClientID: string | null = null;//not needed in actions
    ClientRangeID: string = "";
    CRID: number = 0;
    PoolID: number = 0;
    CountryID: number = 0;
    KTID: number = 0;
    StatusID: number = 0;
    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    ReferralID: string = "";
    OwnerAssigned: string = "";
    OwnerAssignedTxt: string = "";
    AnnualRevenue: string = "";
    AssociatedDeals: string = "";
    RecentDealAmount: string = "";
    CompanyName: string = "";
    CompanyOwner: string = "";
    CompanyDescription: string = "";
    Industry: string = "";
    YearFounded: number = 0;
    CompanyDomainName: string = "";
    CompanySize: number = 0;
    NumberEmployees: number = 0;
    CompanyPhoneNumber: string = "";
    State: string = "";
    City: string = "";
    StreetAddress: string = "";
    StreetAddress2: string = "";
    PostalCode: string = "";
    TimeZone: number = 0;
    WebsiteURL: string = "";
    FacebookPage: string = "";
    GooglePlusPage: string = "";
    LinkedInPage: string = "";
    IsIB: boolean = false;
    PoolName: string = "";
    CountryName: string = "";
    KYCType: string = "";
    StatusType: string = "";
    ClientName: string = "";
    PromoCode: string = "";
    AssociatedContacts: number = 0;
}

export class Contact {
    ContactOwnerTxt: string = "";
    CountryID: number = -1;
    SalutationID: number = -1;
    SalutationTxt: string = "";
    StatusID: number = -1;
    MaritalStatusID: number = -1;
    MilitaryStatusID: number = -1;
    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    DOB: string = "";
    Phone: string = "";
    MobilePhonenumber: string = "";
    City: string = "";
    ResidentialAddress: string = "";
    ZipCode: string = "";
    ProfileImage: string = "";
    ContactOwner: string = "";
    Faxnumber: string = "";
    Gender: string = "";
    School: string = "";
    Seniority: string = "";
    State: string = "";
    StreetAddress: string = "";
    Degree: string = "";
    FieldOfStudy: string = "";
    GraduationDate: string = "";
    JobTitle: string = "";
    JobFunction: string = "";
    Industry: string = "";
    WorkEmail: string = "";
    Skype: string = "";
    Wechat: string = "";
    AnnualRevenue: string = "";
    AssociatedDeals: string = "";
    ProductInterest: string = "";
    RequestType: string = "";
    MembershipNotes: string = "";
    Message: string = "";
    Notes: string = "";
    TwitterUsername: string = "";
    WebsiteURL: string = "";
    UnsubscribedFromAllEmail: boolean = false;
    IPCity: string = "";
    IPCountry: string = "";
    IPTime: string = "";
    ReferralID: string = "";
    Language: string = "";
    ResetPassword: string = "";
    ActivationCode: string = "";
    IsActive: boolean = false;
    CountryName: string = "";
    Salutation: string = "";
    StatusType: string = "";
    MaritalDescription: string = "";
    CreationDate: string = "";
    TimeRegistrationEmailSent: string = "";
    MilitaryDescription: string = "";
    AssociatedClient: string = "";
    CreationDateStr: string = "";
    WhitelistedIPs?: string = "";
}

export class Role {
    RoleID: number = 0;
    RoleName: string = "";
    LicPerms: LicPerms[] = [];
}

export class LicPerms {
    LicID: number = 0;
    PermID: number = 0;
}
export class LicPermsAddClient {
    LicID: number = 0;
    PermID: number = 0;
    PermValID: number = 0;
}

//logs
// DeletePendingClientReq
export class ClientRequestInfo {
    ReqType: string = "";
    ReqID: number = -1;
}
//#region clientPage
//GetClientsTable
//request
export interface TraderDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}
//response
export interface GetClientsTableResult {
    ClientID: string;
    Status: string;
    ClientRangeID: string;
    ClientName: string;
    PoolName: string;
    Email: string;
    PhoneNumber: string;
    OwnerAssigned: string;
    LastActivity: string;
    CreationDate: string;
    CreationDateStr: string;
    CountryName: string;
    IsOnline: boolean;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//ClientDropDownList
export interface ClientDropDownList {
    Salutation: GetSalutationResult[];
    Countries: GetCountriesResult[];
    ClientIDRanges: GetClientIDRangesResult[];
    Pools: GetPoolsResult[];
    Status: GetStatusResult[];
    MaritalStatus: GetMaritalStatusResult[];
    RolesListResult: RolesList[];
    PermsLicListResult: PermsLicList[];
    ContactOwners: AdminList[];
}

export interface GetSalutationResult {
    SalutationID: number;
    Salutation: string;
}
export interface AdminList {
    AUID: string;
    Email: string;
    FullName: string;
}
export interface GetCountriesResult {
    CountryID: number;
    CountryCode: string;
    CountryName: string;
    CountryFlag: string;
    CountryNameCN: string;
    CountryPerm: boolean;
    CompanyID: string;
}

export interface GetClientIDRangesResult {
    CRID: number;
    CRDescription: string;
    CRStart: number;
    CREnd: number;
}
export interface GetStatusResult {
    StatusID: number;
    StatusDescription: string;
}

export interface GetMaritalStatusResult {
    MaritalStatusID: number;
    MaritalDescription: string;
}
export class PermsLicList {
    PermOrder: number = 0;
    LicID: number = -1;
    LicName: string = "";
    LicIsAdmin: boolean = false;
    PermID: number = -1;
    LicOrder: number = 0;
    PermName: string = "";
    ParentPermID: number = -1;
    TotalUsers: number = -1;
    RemainingUsers: number = -1;
}
export class PermsLicListChecked extends PermsLicList {
    isChecked: number = 0;
}

export interface RolesList {
    RoleID: number;
    RoleName: string;
    LicID: number;
    LicName: string;
    PermID: number;
    PermName: string;
    RLID: number;
    ParentPermID: number;
}
// Get API:

// Function name: GetTaskDropDownLists
// Param: YoonitRequest<string>
// Return:
export interface TaskDropDownList {
    TaskStatus: GetTaskStatusResult[];
    TaskPriority: GetTaskPriorityResult[];
    Users: GetUsersResult[];
}

export interface GetUsersResult {
    ContactID: string;
    FullName: string;
    Email: string;
}

// Function name: GetTasksList
// Param: YoonitRequest<GetTask>
export class GetTask {
    OwnerID: string = "";
    OwnerType: string = "";
}
// Return:
export class TaskClass {
    TaskID?: number | null = -1;
    TaskPriorityID?: number = -1;
    TaskStatusID?: number = -1;
    TaskSubject: string = "";
    DueDate: string = "";
    AssignedTo?: string = "";
    Notes: string = "";
    OwnerID?: string = "";
    OwnerType?: string = "";
    CreationDate?: string | null = "";
    StatusIcon?: string | null = "";
    PriorityIcon?: string | null = "";
    PriorityDescription?: string | null = "";
    StatusDescription?: string | null = "";
    AssignedToName?: string | null = "";
    CreatedBy: string = "";
    CreatedByName?: string | null = "";
}

// Function name: GetNotesList
// Param: YoonitRequest<GetTask>
//export interface GetTask
// Return:
export class NoteClass {
    NoteID: number | null = -1;
    NoteSubject: string = "";
    NoteText: string = "";
    OwnerID: string = "";
    OwnerType: string = "";
    CreationDate: string | null = "";
    CreatedBy: string = "";
}
// Function name: GetTasksTable
// Param: YoonitRequest<TraderDataTable>
//TraderDataTable

// Return:
export interface GetTasksTableResult {
    TaskID: number;
    TaskSubject: string;
    PriorityDescription: string;
    StatusDescription: string;
    Notes: string;
    DueDate: string;
    CreationDate: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
// Function name: GetNotesTable
// Param: YoonitRequest<TraderDataTable>
//TraderDataTable
// Return:
//export interface GetNotesTableResult {
//    NoteID: number;
//    NoteSubject: string;
//    NoteText: string;
//    CreationDate: string;
//    RowTot: number;
//    RowFilter: number;
//    Rn: number;
//}

// Action API:

// Function name: AddTask
// Param: YoonitRequest<TaskClass>
//export interface TaskClass {
// Return:
// Int

// Function name: AddNote
// Param: YoonitRequest<NoteClass>
//export interface NoteClass {
//    NoteID: string;
//    NoteSubject: string;
//    NoteText: string;
//    OwnerID: string;
//    OwnerType: string;
//}
// Return:
// Int

// Function name: DeleteTask
// Param: YoonitRequest< int>
// Return:
// Int

// Function name: DeleteNote
// Param: YoonitRequest< int>
// Return:
// Int

export interface ClientDropDownPerPoolList {
    Countries: GetCountriesResult[];
    ClientIDRanges: GetClientIDRangesResult[];
    ApplicationTypeResult: GetApplicationTypeResult[];
    GetIBCodeRangesResult: IBCodeRangesInfo[];
    GetWalletRangesResult: WalletRanges[];
    GetWalletCurrencies: GetAccCurrencyResult[];
}
export interface WalletRanges {
    WRID: number;
    WRDescription: string;
    WRStart: number;
    WREnd: number;
    WRCurrent: number;
    PoolID: number;
}
export interface GetTaskStatusResult {
    TaskStatusID: number;
    StatusDescription: string;
    StatusIcon: string;
}

export interface GetTaskPriorityResult {
    TaskPriorityID: number;
    PriorityDescription: string;
    PriorityIcon: string;
}
//#endregion
//MAMM/UpdateUserProfileImage: as for application form
export class UploadKYCDocumentRequest {
    //ClientID: string="";
    ContactID: string = "";
    Type: string = "";
    BinaryDocument: string = "";
}

//UpdateUserEmail,UpdateMailLang,UpdateUserPassword:
export interface ProfileInfoClass {
    ContactID: number;
    Password: string;
    CurrPassword: string;
    Email: string;
    Language: string;
    WLName: string;
    Code: string;
}
// SendTransactionReq:
export interface AccTransactionReq {
    IsAdmin: boolean;
    Status: number;
    AccountID: number;
    ReqType: string;
    ToAccID: number;
    Login: string;
    Platform: string;
    PaymentMethod: string;
    CurrencyID: number;
    Amount: number;
    OrderID: string;
    Currency: string;
    PaymentName: string;
    Comment: string;
    Note: string;
    IsBankWire: number;
    BankName: string;
    BID: number;
    BankAddress: string;
    AccountNumber: string;
    BeneficiaryAddress: string;
    BeneficiaryName: string;
    IBAN: string;
    Swift: string;
    IntermediaryBank: string;
    CorrespondentBank: string;
    ttCopy: string;
    BAID: number;
    ReqStatus: number;
    Email: string;
    ID: string;
    ClientID: string;
}

// EditAccInfoReq
export interface AccInfoReq {
    ClientID: string;
    BUID: number;
    ServerID: number;
    WLName: string;
    LRID: number;
    Login: string;
    Password: string;
    IBCode: string;
    AccountID: number;
    ReqType: string;
    AccLeverageID: number;
    AccLeverage: number;
    AccTypeID: number;
    AccCurrencyID: number;
    AccCurrency: string;
    AccountPass: string;
    CreationReason: string;
    Status: number;
    KTID: number;
    ReferralID: string;
    IsIB: boolean;
    MasterIB: number;
    NewLogin: boolean;
    ByPassAdminReq: boolean;
}
export interface ProfileInfo {
    BUID: string;
    Password: string;
    CurrPassword: string;
    Email: string;
    Language: string;
    WLName: string;
    Code: string;
}

export interface OpenDealsService { // mt5
    Name: string;
    Login: string;
    ServerName: string;
    ActionInt: number;
    Action: string;
    Deal: number;
    Price: number;
    ClosePrice: number;
    Profit: number;
    Symbol: string;
    Volume: number;
    Swaps: number;
    Type: number;
    Time: number;
    TimeStr: string;
    PriceSL: number;
    PriceTP: number;
    Comment: string;
    Digits: number;
    MarginRate: number;
    OrderBD: string;
    CloseTradeBtn: string;
    DT_RowId: string;
    DT_RowClass: string;
}
export interface TradeRecordService { //some are not capitial
    Name: string;
    servername: string;
    login: string;
    order: number;
    symbol: string;
    open_price: number;
    close_price: number;
    open_time: string;
    open_timeStr: string;
    close_time: string;
    close_timeStr: string;
    cmd: string;
    cmdint: number;
    sl: number;
    tp: number;
    taxes: number;
    storage: number;
    commission: number;
    digits: number;
    profit: number;
    //profitPips: number;
    comment: string;
    volume: number;
    OrderBD: string;
    CloseTradeBtn: string;
    Lots: number;
    DT_RowId: string;
    DT_RowClass: string;
    Type: number;
}
//leades
export class Lead {
    LeadID: number = -1;
    CountryID: number = -1;
    SalutationID: number = -1;
    SalutationTxt: string = "";
    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    FullName: string = "";
    LeadNote: string = "";
    DOB: string = "";
    Phone: string = "";
    SecondPhone: string = "";
    City: string = "";
    ResidentialAddress: string = "";
    ZipCode: string = "";
    PoolID: number = -1;
    EmailLang: string = "";
    ReferralCode: string = "";
    LeadOwner: string = "";
    StatusID: number = -1;
    StreetAddress: string = "";
    DomainRegistrationEmailSent: string = "";
    Gender: string = "";
    MaritalStatusID: number = -1;
    Language: string = "";
    JobTitle: string = "";
    JobFunction: string = "";
    Industry: string = "";
    WorkEmail: string = "";
    Skype: string = "";
    Wechat: string = "";
    OwnerAssignedDate: string = "";
    NumberTimesContacted: number = -1;
    Product: string = "";
    RelationshipStatus: string = "";
    RequestType: string = "";
    MembershipNotes: string = "";
    LastActivityDate: string = "";
    LastContactedDate: string = "";
    LastModifiedDate: string = "";
    TimeRegistrationEmailSent: string = "";
    TwitterUsername: string = "";
    WebsiteURL: string = "";
    FirstConversion: string = "";
    FirstConversionDate: string = "";
    NumberFormSubmitted: number = -1;
    NumberUniqueFormsSubmitted: number = -1;
    RecentConversion: string = "";
    RecentConversionDate: string = "";
    IPCity: string = "";
    IPCountry: string = "";
    IPCountryCode: string = "";
    IPState: string = "";
    IPStateCode: string = "";
    IPTimeZone: string = "";
    CloseDate: string = "";
    CreationDate: string = "";
    ContactID: string = "";
    CountryName: string = "";
    Salutation: string = "";
    StatusType: string = "";
    MaritalDescription: string = "";
    PoolName: string = "";
}
//LEads
export interface GetLeadsTableResult {
    LeadID: string;
    FullName: string;
    Email: string;
    Phone: string;
    LeadOwner: string;
    CountryName: string;
    CreationDate: string;
    StatusType: string;
    LastActivity: string;
    CreationDateStr: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//client page each client app form tab
export interface GetAppFormViewResult {
    permissionslist: GetKYCPermissionsResult;
    KYCDetails: GetKYCDetailsResult;
    Declarations: GetDeclarationsResult[];
}
//mam class
//getpapf
//savepapf
export interface PFClass {
    Agents: AgentAccClass[];
    AccountID: number;
    Login: string;
    ProfileID: number;
    ProfileStrategyName: string;
}
export interface GetContactsTableResult {
    ClientID: string;
    ContactID: string;
    FullName: string;
    Email: string;
    Phone: string;
    ContactOwner: string;
    CountryName: string;
    CreationDate: string;
    AssociatedClient: string;
    StatusType: string;
    LastActivity: string;
    CreationDateStr: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//ForcePerformanceFee
//export interface PerfFeeClass {
//    ProfileID: number;
//    SlaveLogin: string;
//    MasterLogin: string;
//}

//Request: public class MAMAdminRequests : CRMClasses.DataTable
export interface LoadCopierAccountsTableParms {
    ProfileID: number;
    IsDefault: boolean;
    AccountID: number;
    AUID: string;
}
export interface LoadCopierAccountsTableResult {
    ACID: number;
    ARule: string;
    IsReverse: boolean;
    IsReverseTxt: string;
    Amount: number;
    Login: string;
    Currency: string;
    Equity: number;
    Balance: number;
    FullName: string;
    AccountName: string;
    Unfollow: string;
    rowTot: number;
    rowFilter: number;
    rn: number;
}
//clinet list MAM Profiles
//GetMMClientList:

export interface LoadMMClientListResult {
    ClientRangeID: number;
    ClientID: string;
    text: string;
    rowFilter: number;
}
//get account types
export interface WLAccSettings {
    AccLeverage: AccLeverages[];
    IBCodeRanges: IBCodeRanges[];
    AccCurrency: Currency[];
    AccTypes: AccTypes[];
    LoginRanges: LoginRanges[];
    AllocRules: LoadAllocationRulesResult[];
    STPVals: LoadSTPValuesResult[];
}
export interface IBCodeRanges {
    IBRID: number;
    WLID: number;
    IBRDescription: string;
    IBRStart: number;
    IBREnd: number;
}

export interface LoginRanges {
    LRID: number;
    LRDescription: string;
    LRStart: number;
    LREnd: number;
    LRCurrent: number;
}

export interface LoadAllocationRulesResult {
    ARuleID: number;
    ARuleName: string;
    ARuleOrder: number;
}

export interface LoadSTPValuesResult {
    STPID: number;
    STPValue: number;
    STPText: string;
}

//9 - GetMMInfoByAccType
//YoonitRequest: Token, CompanyName
//ObjData: int

export interface WLAccSettings {
    AccLeverage: AccLeverages[];
    IBCodeRanges: IBCodeRanges[];
    AccCurrency: Currency[];
    AccTypes: AccTypes[];
    LoginRanges: LoginRanges[];
    AllocRules: LoadAllocationRulesResult[];
    STPVals: LoadSTPValuesResult[];
}
//10 - GetAdminAccTypes

//YoonitRequest: Token, CompanyName
//GetAdminAccTypesRequest: AUID, IsDemo

export interface GetServersPlatforms {
    Servers: ServerDetails[];
    Platforms: string[];
}

export interface ServerDetails {
    ServerID: number;
    ServerName: string;
    Type: string;
}
export interface ServerData {
    ServerID: number;
    ServerName: string;
    ServerType: string;
    IsConnected: boolean;
}
//class temp for add account
export class TempAddAccount {
    ClientID: number = -1;
    ServerID: number = -1;
    PlatformID: number = -1;
    LoginRange: string = "";
    AcctypID: number = -1;
    CurrencyID: number = -1;
    LeverageID: number = -1;
}
export class TraderMainClass {
    BUID: string = "";
    Amount: number = 0;
    ServerID: number = 0;
    ServerName: string = "";
    ServerType: string = "";
    AccountID: number = 0;
    FullName: string = "";
    Email: string = "";
    Login: string = "";
    IsPrimary: boolean = false;
    IsEnabled: boolean = false;
    IsReadOnly: boolean = false;
    IsIB: boolean = false;
    AccCurrencyID: number = 0;
    AccCurrency: string = "";
    AccTypeID: number = 0;
    AccLeverageID: number = 0;
    CountryID: number = 0;
    IBCode: string = "";
    ReferralID: string = "";
    LRID: number = 0;
    IBRID: number = 0;
    MasterIB: number = 0;
    NewLogin: boolean = false;
    Balance: number = 0;
    Equity: number = 0;
    Credit: number = 0;
    Floating: number = 0;
}

export class TraderClass extends TraderMainClass {
    ClientID: string = "";
}

//add IB acc
export class AddIBAccProfileRequest {
    Trader: TraderClass = new TraderClass();
    Profile: IBProfile = new IBProfile();
}

export class IBProfile {
    ProfName: string = "";
    CopyProfID: number = -1;
}
export class GetIBDetailsResult {
    Name: string = "";
    Login: string = "";
    Currency: string = "";
    Leverage: string = "";
    IBCode: string = "";
    ServerName: string = "";
    Type: number = -1;;
    AccType: string = "";
}

export interface IBProfilesByLevel {
    ProfToLink: GetProfilesByLevelResult[];
    ProfToCopy: GetProfilesByLevelResult[];
    MyProf: GetProfilesByLevelResult[];
}
export interface GetProfilesByLevelResult {
    PID: number;
    ServerID: number;
    PName: string;
    PLevel: number;
    PCreationDate: string;
}

export interface LoadIBProfCogTableResult {
    Priority: string;
    ProfileID: number | null;
    CogName: string;
    Type: string;
    ProfType: string;
    CreationDateStr: string;
    CreationDate: string | null;
    CogType: string;
    CogID: number | null;
    ProfName: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export class ComProfCog {
    Level: number = 0;
    Commission: number = 0;
    Markup: number = 0;
    Swaps: number = 0;
}

export class LotProfCog {
    Level: number = 0;
    LotAmount: number = 0;
}

export class DepositProfCog {
    From: number = 0;
    To: number = 0;
    Amount: number = 0;
}

export interface GetIBProfilesResult {
    IBPID: number;
    AccountID: number;
    PID: number;
    IsDefault: boolean;
    IBPCreationDate: string;
    PID1: number;
    ServerID: number;
    PName: string;
    PLevel: number;
    PCreationDate: string;
    IsDeposit: number;
    IsPerc: number;
    IsLot: number;
}

export interface GetProfileConfigDataResult {
    Prof: IBProfileData[];
    Securities: GetServerSecuritiesResult[];
    ProfTypes: GetProfileTypesResult[];
    AmountTypes: GetAmountTypesResult[];
    DistProfTypes: GetProfileDistTypesResult[];
}

export interface ProfileData {
    ProfileStrategyName: string;
    ProfileGroups: string;
    ProfileLogins: string;
}

export interface GetServerSecuritiesResult {
    SecurityID: number;
    ServerID: number;
    SecurityName: string;
    SecurityIndex: number;
}

export interface GetProfileTypesResult {
    PTID: number;
    TypeName: string;
    ProfType: string;
}

export interface GetProfileDistTypesResult {
    PDTID: number;
    PTID: number;
    PDTName: string;
}

export interface GetAmountTypesResult {
    ATID: number;
    ATName: string;
}
export interface IBProfileData {
    PLevel: number;
    Type: number;
    ServerName: string;
}
export class SelectOptionWithData {
    value: string | number = -1;
    label: string = "Select...";
    Data: any = {}
}
export class IBProfileCog {
    CogID: number = 0;
    PID: number = 0;
    PTID: number = 0;
    DistName: string = "";
    Priority: number = 0;
    DistSymbols: string = "";
    DistSecurities: string = "";
    EntryID: number | null = 0;
    ComDist: ComProfCog[] = [];
    PDTID: number = 0;
    LotDist: LotProfCog[] = [];
    FirstATID: number | null = 0;
    FirstMaxComsn: number | null = 0;
    DepDist: DepositProfCog[] = [];
    AnyATID: number | null = 0;
    AnyMaxComsn: number | null = 0;
    AnyAmount: number | null = 0;
    ProfType: string = "";
    DepProfType: string | null = "";
    IsCashback: boolean = false;
    IsDeductFromIB: boolean = false;
    CashbackATID: number | null = 0;
    CashbackPercentage: number = 0;
    Scalping: number = 0;
}

export interface SettingsInfo {
    CogID: number;
    Type: string;
}
// YoonitRequest<SettingsInfo>  , YoonitResponse<IBProfileCog>
export class IBAccounts {
    AccountID: number = 0;
    PID: number = 0;
    LinkType: string = "";
    RefferalID: string = "";
    PIDs: string = "";
    Currency: string = "";
    GroupName: string = "";
    Accounts: string = "";
    Login: string = "";
    IsWL: boolean = false;
    AccTypeID: number = 0;
}

// Function Name: LoadIBDetailedReportTable
// Param: YoonitRequest<IBRequest>
export interface IBDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
    FromDay: number;
    ToDay: number;
    FromMonth: number;
    ToMonth: number;
    FromYear: number;
    ToYear: number;
}

export interface IBRequest {
    AccountID: number;
    IsDirect: number;
    CommissionType: number;
    SubAccountID: number;
    IBAccountID: number;
    Symbol: string;
    Accounts: string;
}
export interface GetIBsListMSResult {
    AccountID: number;
    Email: string;
    FullName: string;
    Login: string;
    Currency: string;
    IBCode: string;
}

export interface GetIBTransactionTableResult {
    TimeStr: string;
    Time: string | null;
    IBName: string;
    AccNbr: string;
    Ticket: string | null;
    EntryType: string;
    Symbol: string;
    Side: string;
    VolumeLot: number | null;
    Commission: number | null;
    Balance: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface GetIBDepositReportTableResult  //remove email field
{
    ClientName: string;
    ClientID: number;   // changed from AccountID -> ClientID
    IBCode: string;
    Wallet: string;  //changed from Account -> Wallet
    Amount: number | null;
    Commission: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}

//#region parameter interfaces
export interface APIRequest<T> {//rename
    ObjData: T;
}
export class GetGUTradersParams {
    CompanyName: string = "";
    Token: string = "";
    ObjData: APIRequest<number>
    constructor() {
        this.ObjData = { ObjData: -1 } as APIRequest<number>;
    }
}

export class getDashboardDataObjData {
    public ProfileID: number = -1;
    public AccountID: number = -1;
    public GUID: number = -1;
}
export class getDashboardDataParameters {
    public CompanyName: string = "";
    public ServerName: string = "";
    public ServerType: string = "";
    public ServerID: number = -1;
    public Token: string = "";
    public Login: string = "";
    public UserName: string = "";
    public ObjData: APIRequest<getDashboardDataObjData>
    constructor() {
        this.ObjData = { ObjData: {} as getDashboardDataObjData };
    }
}
//#endregion

//#region redux interfaces
export class Servers {
    serverID: number = -1;
    serverName: string = "No Servers";
    type: string = "";
    wlName: string = "";
    wlid: number = -1;
}
export class ServersOptions extends Servers {
    label: string = "No Servers";
    value: number = -1;
}
//#endregion

export interface menuTitles {
    name: string;
    link: string;
    subTabs: any;
    icon: string;
}

//#region common interfaces
// tabs
export interface paneData {
    title: string;
    content: any;
    key: string | any;
    closable: boolean;
}
//translation
export type translationType = 'en' | 'cn' | 'ar';
//Server Select
export interface ServerSelectType {
    serverName: any;
    type: any;
    value: any;
}
//client Page
export interface clientPageCheckBoxData {
    id: string;
    FullName: string;
    Email: string;
    PhoneNumber: string;
    OwnerAssigned: string;
}
export interface clientPageCheckBoxData {
    id: string;
    ClientID: string;
    FullName: string;
    Email: string;
    PhoneNumber: string;
    OwnerAssigned: string;
}
export interface tasksTableCheckBoxData {
    id: string
    TaskID: number;
    TaskSubject: string;
    PriorityDescription: string;
    StatusDescription: string;
    Notes: string;
    DueDate: string;
    CreationDate: string;
}
//#endregion
export class YoonitRequest<T> {
    ServerIDs?: string;
    CompanyName?: string;
    CompanyID?: string;
    Data?: T[];
    IP?: string;
    TimeZone?: number;
    Login?: string;
    ObjData?: T;
    OperationName?: string;
    ServerID?: number;
    ProfileID?: number;
    ServerName?: string;
    ServerType?: string;
    SlaveLogin?: string;
    Token?: string;
    UserName?: string;
    AUID?: string;
    BUID?: string;
    Language?: string;
    Perms?: string;
    ClientName?: string;
    ProfileName?: string;
    ConfigName?: string;
    ClientRangeID?: string;
}
export interface LoadGUDepositReqTableResult {
    Account: string;
    Amount: number;
    ConvertedAmount: number;
    Currency: string;
    Delete: string;
    DRID: number;
    PaymentDetails: string;
    PaymentMethod: string;
    Reason: string;
    ReqDate: Date;
    ReqDateStr: string;
    rn: number;
    rowFilter: number;
    rowTot: number;
    Status: number;
    StatusStr: string;
}
export class GetClientRangeInfo {
    PoolID: number = 0;
    CRStart: number = 0;
    CREnd: number = 0;
    CRCurrent: number = 0;
}

//#region AddClient

export class ActionClient {
    ExternalID: string = '';
    IBRID: number | null = 0;
    IBCodeRange: number | null = 0;
    CRID: number | null = 0;
    ClientRangeID: number | null = 0;
    PoolID: number | null = 0;
    CountryID: number | null = 0;
    KTID: number | null = 0;
    StatusID: number | null = null;
    Email: string = "";
    ClientName: string = "";
    ReferralID: string = "";
    OwnerAssigned: string = "";
    AnnualRevenue: string = "";
    AssociatedContacts: number = 0;
    AssociatedDeals: string = "";
    RecentDealAmount: string = "";
    CompanyName: string = "";
    CompanyOwner: string = "";
    CompanyDescription: string = "";
    Industry: string = "";
    YearFounded: number | null = 0;
    CompanyDomainName: string = "";
    CompanySize: number | null = 0;
    NumberEmployees: number | null = 0;
    CompanyPhoneNumber: string = "";
    State: string = "";
    City: string = "";
    StreetAddress: string = "";
    StreetAddress2: string = "";
    PostalCode: string = "";
    TimeZone: number | null = 0;
    WebsiteURL: string = "";
    FacebookPage: string = "";
    GooglePlusPage: string = "";
    LinkedInPage: string = "";
    IsIB: boolean = false;

}

export class ActionContact {
    ClientID: string | null = null;
    CountryID: number | null = 0;
    SalutationID: number | null = 0;
    SalutationTxt: string = "";
    StatusID: number | null = 0;
    MaritalStatusID: number | null = 0;
    MilitaryStatusID: number | null = null;
    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    DOB: string | null = null;
    Phone: string = "";
    MobilePhoneNumber: string = "";
    City: string = "";
    ResidentialAddress: string = "";
    ZipCode: string = "";
    ProfileImage: string = "";
    ContactOwner: string | null = "";
    FaxNumber: string = "";
    Gender: string = "";
    School: string = "";
    Seniority: string = "";
    State: string = "";
    StreetAddress: string = "";
    Degree: string = "";
    FieldOfStudy: string = "";
    GraduationDate: string | null = null;
    JobTitle: string = "";
    JobFunction: string = "";
    Industry: string = "";
    WorkEmail: string = "";
    Skype: string = "";
    Wechat: string = "";
    AnnualRevenue: string = "";
    AssociatedDeals: string = "";
    ProductInterest: string = "";
    RequestType: string = "";
    MembershipNotes: string = "";
    Message: string = "";
    Notes: string = "";
    TwitterUsername: string = "";
    WebsiteURL: string = "";
    UnsubscribedFromAllEmail: boolean = false;
    IPCity: string = "";
    IPCountry: string = "";
    IPTime: string = "";
    ReferralID: string = "";
    Language: string = "en";
    ResetPassword: string = "";
    ActivationCode: string = "";
    //AssociatedClient: string = "";
    //CreationDateStr: string = "";
    IsActive: boolean = false;
    ContactID: string | null = null;
}

export class ActionTraderClass {
    BUID: string = "";
    Amount: number = 0;
    ServerID: number = 0;
    ServerName: string = "";
    ServerType: string = "";
    ClientID: string = "";
    AccountID: number = 0;
    FullName: string = "";
    Email: string = "";
    Login: string = "";
    IsPrimary: boolean = false;
    IsEnabled: boolean = false;
    IsReadOnly: boolean = false;
    IsIB: boolean = false;
    AccCurrencyID: number = 0;
    AccCurrency: string = "";
    AccTypeID: number = 0;
    AccLeverageID: number = 0;
    CountryID: number = 0;
    IBCode: string = "";
    ReferralID: string = "";
    LRID: number = 0;
    IBRID: number = 0;
    MasterIB: number = 0;
    NewLogin: boolean = false;
    Balance: number = 0;
    Equity: number = 0;
    Credit: number = 0;
    Floating: number = 0;
}

export class ActionRole {
    RoleID: number = 0;
    RoleName: string = "";
    LicPerms: LicPerms[] = [];
}
//#endregion
// GetSlaveOpenPos:  YoonitRequest<OpenPositionRequest>
export interface OpenPositionRequest extends MAMMDataTable {
    Symbol: string;
    ProfileID: number;
    Ticket: number;
}
export interface MAMMDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}
// Function Name: GetTradingAccList
// Param: YoonitRequest<string> (req.objData = ClientID)
export interface GetTradingAccounts {
    AccountID: number;
    Digits: number;
    Account: string;
    Name: string;
    GroupName: string;
    Balance: number | null;
    Equity: number | null;
    Credit: number | null;
    Floating: number | null;
}

export interface ContactLic {
    ContactID: string;
    FullName: string;
    Email: string;
    ContactOwner: string;
    AssociatedClient: string;
    LastActivity: string;
    UserAuthID: string;
    IsLicensed: boolean;
    StatusType: string;
    CreationDate: string;
}
//@point class with my account same so dont confuse
export class LoadEditAccInfoData {
    AccLeverage: Array<AccLeverage2> = [];
    AccType: Array<LoadEditAccInfoDataAccType2> = [];
    Currency: Array<Currency2> = [];
    MyAccount: AccountsInfo = new AccountsInfo();
}
export class AccLeverage2 {
    AccLeverageID: number = -1;
    AccLeverage: number = -1;
    AccTypeID: number = -1;
}
export class LoadEditAccInfoDataAccType2 {
    AccTypeID: number = -1;
    AccType: string = "";
}
export class Currency2 {
    AccCurrencyID: number = -1;
    AccCurrency: string = "";
    AccCurrencyPerm: boolean = false;
    AccTypeID: number = -1;
}
export interface LinkAccToClientReq {
    ClientID: string;
    AccountID: number;
    AccTypeID: number;
}
export interface GetNotesTableResult {
    NoteID: number | null;
    NoteSubject: string;
    NoteText: string;
    CreatedBy: string;
    CreationDate: Date | string | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
// Function Name: GetCopyMMs
// Param: YoonitRequest<GetCopyMMResult>
export interface GetCopyMMResult {
    AccountID: number;
    ClientID: string;
    AUID: string;
}

// Return:
export class GetCopyMMsResult {
    Name: string = "";
    ClientID: string = "";
    AccountID: number = 0;
    ProfileID: number = 0;
    Login: string = "";
    Currency: string = "";
    ARuleName: string = "";
    ServerName: string = "";
    ServerType: string | null = "";
}
export interface AccTransactionReq {
    Status: number;
    AccountID: number;
    ReqType: string;
    ToAccID: number;
    Login: string;
    Platform: string;
    PaymentMethod: string;
    CurrencyID: number;
    Amount: number;
    OrderID: string;
    Currency: string;
    PaymentName: string;
    Comment: string;
    Note: string;
    IsBankWire: number;
    BankName: string;
    BID: number;
    BankAddress: string;
    AccountNumber: string;
    BeneficiaryAddress: string;
    BeneficiaryName: string;
    IBAN: string;
    Swift: string;
    IntermediaryBank: string;
    CorrespondentBank: string;
    ttCopy: string;
    BAID: number;
    ReqStatus: number;
    Email: string;
    ID: string;
    ClientID: string;
}
// Function Name: GetAllContactList
// Param: YoonitRequest<string> req (req.AUID = redux.AUID)
// Return:
export interface AllContact {
    ContactID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    ClientID: string;
}

export class GetDWInfoClass {
    Name: string = "";
    Balance: number = 0;
    FreeMargin: number = 0;
    Credit: number = 0;
    CurrencyID: number = 0;
    PMID: number = 0;
    PaymentName: string = "";
    IsDeposit: boolean = false;
}

export class Account {
    Login: string = "";
    Type: string = "";
    ServerName: string = "";
    ServerID: string = "";
    Name: string = "";
    Email: string = "";
    Phone: string = "";
    Address: string = "";
    Country: string = "";
    City: string = "";
    State: string = "";
    Zip: string = "";
    LeadDate: Date | string | null = "";
    CreationDate: Date | string | null = "";
    Equity: number | null = 0;
    EquityUSD: number | null = 0;
    Currency: string = "";
    GroupName: string = "";
    IBCode: string = "";
    IDNumber: string = "";
    LeadSource: string = "";
    MetaQuotesID: string = "";
    Status: string = "";
    AgentAccount: string = "";
    Margin: number | null = 0;
    MarginLevel: number | null = 0;
    FreeMargin: number | null = 0;
    Credit: number | null = 0;
    Balance: number | null = 0;
    UnrealizedPL: number | null = 0;
    Leverage: number | null = 0;
    AccType: string = "";
    MasterFloatingPnl: number | null = null;//keep it null:as in null we hide iy
    Digits: number = 0;
}
export interface ExportTradingAccountsTable {
    Account: string;
    Name: string;
    GroupName: string;
    Balance: number | null;
    Equity: number | null;
    Credit: number | null;
    Floating: number | null;
}
export interface TradingAccountsList {
    AccountID: number;
    Login: string;
    Email: string;
}
export interface PermissionsLicClass {
    CompanyID: string;
    IsAdmin: boolean;// set true if you want to create admin user else false
}
export interface GetUserMainPageRolesPermsList {
    PermsLicListResult: PermsLicList[];
    RolesListResult: UserRolesList[];
    AccTypeListResult: AccTypes[];
    PoolListResult: GetPoolsResult[];
    TeamListResult: GetEditTeamResult[];
}
export interface GetClientList {
    ClientID: string;
    ClientRangeID: string;
    ClientName: string;
}
export interface GetUsersTableResult {
    ContactID: string;
    FullName: string;
    Email: string;
    Lic: string;
    IsAdmin: boolean;
    IsActive: boolean;
    IsBlocked: boolean;
    CreationDate: Date | string | null;
    CreationDateStr: string;
    ClientID: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface CreateActiveUser {
    ContactID: string;
    ClientID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    IsAdmin: boolean;
    TradinAccPerm: string;
    //PoolPerm: string;
    //AccTypePerm: string;
    CompanyID: string;
    RoleID: number;
    TeamID: string;// new field
    Perms: UserPermsLic[];// new list
}
export interface UpdateUserPassClass {
    ContactID: string;
    Email: string;
    Password: string;
    SendByEmail: boolean;
    SendToEmail: string;
    IsAdmin: boolean;
}
export interface UsersPermissionsLicClass {
    CompanyID?: string;
    IsAdmin: boolean;
    ContactID: string;
}
export class GetUserDetails {
    RoleID: number | null = null;
    FirstName: string = "";
    LastName: string = "";
    Email: string = "";
    Phone: string = "";
    TradinAccPerm: number[] = [];
    //PoolPerm: number[] = [];
    //AccTypePerm: number[] = [];
}
export class UpdateCreateActiveUser {
    ContactID: string = "";
    // FirstName: string;//not used
    // LastName: string;//not used
    // Email: string;//not used
    // Password: string;//not used
    IsAdmin: boolean = false;
    TradinAccPerm: string = "";
    PoolPerm: string = "";
    AccTypePerm: string = "";
    CompanyID: string = "";
    RoleID: number = 0;
}
export interface DeleteUserClass {
    ContactID: string;
    IsAdmin: boolean;
}
export interface RoleTableResult extends TraderDataTable {
    CompanyID: string;
}
export interface TraderDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}
export interface GetRolesTableResult {
    RoleID: number;
    RoleName: string;
    RoleDescription: string;
    CreationDate: Date | string | null;
    CreationDateStr: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}

export class RoleData {
    RoleID: number | null = 0;// set 0 if you want to create new role else RoleID
    CompanyID: string | null = "";
    RoleName: string = "";
    Permissions: PermsLic[] = [];
    RoleIsAdmin: boolean | null = false;
}
export class PermsLic {
    LicID: number | null = 0;
    PermID: number | null = 0;
}
export class ActionPermsLic {
    LicID: number | null = 0;
    PermID: number | null = 0;
    PermValID: number | null = 0;//checked or not
}
export interface GetGroupRegistryTable {
    GRID: number | null;
    GroupRegName: string;
    Type: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface GetGroupRegistryListByAccTypeID {
    IBCodeList: string[];
    PoolList: GetPoolsResult[];
    CurrencyList: GetAccCurrencyResult[];
}
export interface GetPoolsResult {
    PoolID: number;
    PoolName: string;
    IsDefault: boolean | null;
    RequireUserActivation: boolean | null;
    IsChecked: boolean;
}
export interface GetAccCurrencyResult {
    AccCurrencyID: number;
    AccCurrency: string;
    AccCurrencyPerm: boolean | null;
}
export interface GroupRegistry {
    AccTypeID: number;
    KTID: number | null;
    GRID: number;
    GroupRegistryName: string;
    Type: string;
    Source: string;
    GroupCurrencyList: GroupCurrency[];
}
export interface GroupCurrency {
    CurrencyID: number;
    GroupName: string;
}
export interface GetGroupRegistryResult {
    KTID: number;
    GroupRegName: string;
    Type: string;
    Source: string;
    PoolID: number;
    GroupyByCurrency: GetGroupyByCurrency[];
}

export interface GetGroupyByCurrency {
    CurrencyID: number;
    Currency: string;
    GroupName: string;
}

//Return:
export interface LoadRegistrationLogsTableResult {
    RLID: number;
    Email: string;
    FullName: string;
    CountryName: string;
    Phone: string;
    CreationDate: Date | string | null;
    CreationDateStr: string;
    ReferralCode: string;
    KTID: number | null;
    DOB: Date | string | null;
    DayDOB: number | null;
    MonthDOB: number | null;
    YearDOB: number | null;
    Salutation: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export class RegistrationLogClass {
    FirstName: string = '';
    LastName: string = '';
    Email: string = '';
    PoolName: string = '';
    CountryName: string = '';
    Phone: string = '';
    ReferralCode: string = '';
    RegistrationDate: Date | string = '';
}
//Note: please remove email from the design of the table

//Function Name:
//LoadDocumentsExpirationTable

//Param: YoonitRequest<DataTable>(req.AUID = redux.AUID)
export interface DataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}

//Return:
export interface GetDocumentsExpirationTableResult {
    ClientID: number | null;
    FullName: string;
    IDType: string;
    DocExpiryDate: Date | string | null;
    DocExpiryDateStr: string;
    IDType2: string;
    DocExpiryDate2: Date | string | null;
    DocExpiryDate2Str: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface AddLead {
    FirstName: string;
    LastName: string;
    DOB: Date | string | null;
    JobTitle: string;
    Phone: string;
    SecondPhone: string;
    CountryID: number | null;
    City: string;
    ResidentialAddress: string;
    ZipCode: string;
    LeadNote: string;
    Email: string;
    LeadOwner: string; //redux.ContactID
}
//Function Name: GetCountriestList

//Param: YoonitRequest < GetCountriesListResult >
export interface GetCountriesListResult {
    PoolID: number;
    SearchKey: string;
}

//Return:
export interface GetCountriesResult {
    CountryID: number;
    CountryCode: string;
    CountryName: string;
    CountryFlag: string;
    CountryNameCN: string;
    CountryPerm: boolean;//if type | null so if null put false
}
//Function Name: UpdateCountriesList
//Param: YoonitRequest < UpdateCountriesListResult >
export interface UpdateCountriesListResult {
    PoolID: number;
    CountryIDs: string; // send the country IDs of the checked countries separated by comma
}

//Return:
//new YoonitResponse < int > { IntRes = ret }
//If IntRes > 0 -> Updated successfully
//Else Action Failed or Error Contacting Server
export interface TraderRequests {
    Amount: number;
    BUID: number;
    AccountID: number;
    ReqID: number;
    ReqIDs: string;
    Status: number;
    ReqType: string;
    Password: string;
    InvestorPassword: string;
    Note: string;
    IsModified: boolean;
    Trader: TraderClass;
    OrderID: string;
}
export interface KYCDocumentsRequest {
    ClientID: string;
    FrontIDBinary: string;
    FrontIDName: string;
    BackIDBinary: string;
    BackIDName: string;
    FrontIDBinary2: string;
    FrontIDName2: string;
    BackIDBinary2: string;
    BackIDName2: string;
    ProofAddrBinary: string;
    ProofAddrName: string;
    SelfieBinary: string;
    SelfieName: string;
    InfrontBankBinary: string;
    InfrontBankName: string;
    BackBankBinary: string;
    BackBankName: string;
    ESignatureName: string;
    ESignatureBinary: string;
    SpecialDocName: string;
    SpecialDocBinary: string;
}
//Function Name: GetApplicationTypeList

//Param: YoonitRequest<int>(req.objData = PoolID)

//Return:
export interface GetApplicationTypeResultList {
    KTID: number;
    TypeName: string;
    RequireKYC: boolean;
    IsEnabledType: boolean;
    PoolID: number;
    KTCreationDate: Date | string | null;
}
//#region application settings
//Function Name: GetApplicationSettings

//Param: YoonitRequest<int>(req.objData = KTID)

//Return: YoonitResponse < KYCPermissions > { LiveRes = KycRes }

export interface KYCPermissions {
    permissionslist: GetKYCPermissionsResult;
    declarationslist: GetDeclarationsResult[];
    dropDownlist: GetDynamicDropDownResult[];
    scoringpoints: GetScoringPointsResult[];
}
export interface GetDynamicDropDownResult {
    DDID: number;
    SelectName: string;
    OID: number;
    DDID1: number;
    OptionName: string;
    OptionVal: string;
}
//#endregion
//Function Name: GetApplicationDeclarations
//Param: YoonitRequest < int > req
//Return:
export interface GetDeclarationsResultApplicationSettings {
    DID: number;
    DeclarationText: string;
    WithRadioBtn: boolean | null;
}
//Function Name: GetApplicationDDOptions
//Param: YoonitRequest < string > req(req.objData = dropdownName)

export class GetDDOptions {
    DDID: number = 0;
    Value: string = "";
    OID: number = 0;
}

//Function Name: UpdateDDOptions

//Param: YoonitRequest<DDOption>(req.Data = List)
export class DDOption {
    DDID: number = 0;
    OID: number = 0;
    OptionValue: string = "";
}

//Return:
//intRes > 0 deleted successfully
//else action failed

//Function Name: UpdateDeclaration

//Param: YoonitRequest<Declaration>(req.Data = List)
export class Declaration {
    ID: number = 0;
    Value: string = "";
    IsQsnt: boolean = false;
}
//Function Name: GetFieldScore
//Param: YoonitRequest < string > req(req.objData = )
export interface GetScoringFieldsResult {
    SID: number;
    FieldID: string;
    Value: string;
    Score: number | null;
    FieldIndex: string;
}
//Function Name: UpdateScoringFields
//Param: YoonitRequest < KYCScoringFields > req
export interface KYCScoringFields {
    FieldID: string;
    ScoringFields: KYCScoring[];
}
export interface KYCScoring {
    Value: string;
    Score: number;
    FieldIndex: string;
}
export class FieldIDClass {
    averageLeverageLevel: string = "averageLeverageLevel";
    averageYearlyVolume: string = "averageYearlyVolume";
    CFDsKnowledgeLevel: string = "CFDsKnowledgeLevel";
    CFDsTrades: string = "CFDsTrades";
    CommoditiesKnowledgeLevel: string = "CommoditiesKnowledgeLevel";
    CommoditiesTrades: string = "CommoditiesTrades";
    currentLevel: string = "currentLevel";
    derivativesFutures: string = "derivativesFutures";
    education: string = "education";
    eduqualification: string = "eduqualification";
    empstat: string = "empstat";
    EstimatedAnnualIncome: string = "EstimatedAnnualIncome";
    EstimatedLiquidNetWorth: string = "EstimatedLiquidNetWorth";
    familiarOTCderivatives: string = "familiarOTCderivatives";
    foreignExchTrades: string = "foreignExchTrades";
    foreignKnowledgeLevel: string = "foreignKnowledgeLevel";
    FuturesMarket: string = "FuturesMarket";
    GovernmentKnowledgeLevel: string = "GovernmentKnowledgeLevel";
    GovernmentTrades: string = "GovernmentTrades";
    HowTradedPreviously: string = "HowTradedPreviously";
    industryEmp: string = "industryEmp";
    industryRetired: string = "industryRetired";
    industryStudent: string = "industryStudent";
    initialMarginReq: string = "initialMarginReq";
    instrumentsFamiliar: string = "instrumentsFamiliar";
    instrumentsWithMargin: string = "instrumentsWithMargin";
    LevelOfEducation: string = "LevelOfEducation";
    MarginConcepts: string = "MarginConcepts";
    MarginRequired: string = "MarginRequired";
    marketMovingAgainstU: string = "marketMovingAgainstU";
    MaximumLeverageUsed: string = "MaximumLeverageUsed";
    mnthlyavgvol: string = "mnthlyavgvol";
    monthlyTrades: string = "monthlyTrades";
    MutualfundsKnowledgeLevel: string = "MutualfundsKnowledgeLevel";
    MutualfundsTrades: string = "MutualfundsTrades";
    OptionsKnowledgeLevel: string = "OptionsKnowledgeLevel";
    OptionsTrades: string = "OptionsTrades";
    OtherInvesKnowledgeLevel: string = "OtherInvesKnowledgeLevel";
    OtherInvesTrades: string = "OtherInvesTrades";
    PlannedInitialInvestment: string = "PlannedInitialInvestment";
    ProfessionRelateFinancialIndustryQsn: string = "ProfessionRelateFinancialIndustryQsn";
    PurposeOpening: string = "PurposeOpening";
    purposetrading: string = "purposetrading";
    QualThatAssistUnderstanding: string = "QualThatAssistUnderstanding";
    riskiestinv: string = "riskiestinv";
    RiskOfLoss: string = "RiskOfLoss";
    SharesKnowledgeLevel: string = "SharesKnowledgeLevel";
    SharesTrades: string = "SharesTrades";
    stoploss: string = "stoploss";
    StopOutBeTriggered: string = "StopOutBeTriggered";
    timeframePosition: string = "timeframePosition";
    tradedForex: string = "tradedForex";
    tradedFunds: string = "tradedFunds";
    tradingWithHigherLeverage: string = "tradingWithHigherLeverage";
    TrueAboutFuturesQns: string = "TrueAboutFuturesQns";
    UnderstandingOfRisks: string = "UnderstandingOfRisks";
    understandOTCderivatives: string = "understandOTCderivatives";
    UsedLeveragedProductsQsn: string = "UsedLeveragedProductsQsn";
    volatilityConcepts: string = "volatilityConcepts";
    WhySpreadFloatingQns: string = "WhySpreadFloatingQns";
    //new
    IFCurrencies: string = "IFCurrencies";
    IFCommodities: string = "IFCommodities";
    IFIndices: string = "IFIndices";
    IFStocks: string = "IFStocks";
    IFCryptoCurrency: string = "IFCryptoCurrency";
}
export interface ContactToClient {
    ContactID: string;
    CRID: number;
    ClientRangeID: number;
    PoolID: number;
    KTID: number | null;
}
export interface LeadToContact {
    LeadID: string;
    Email: string;
}
export interface AccTypesRequest {
    CompanyID: string;
    isDemo: boolean;
    ServerID: number;
}
//#region Bonus
export class DataTableBasicResult {
    rowTot: number = 0;
    rowFilter: number = 0;
}
export class LoadBonusMainTableTableResult extends DataTableBasicResult {
    PID: number = 0;
    Title: string = '';
    StartingDate: string = '';
    EndingDate: string = '';
    Status: boolean = false;
}
//#endregion
//Function Name: GetTeamsTable

//Param: YoonitRequest < TraderDataTable >
export interface TraderDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}

//Return:
export interface GetTeamsTableResult {
    TeamID: string | null;
    TeamName: string;
    TeamDescription: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}

//Function Name: GetAdminUsersList

//Param: YoonitRequest < string >

//    Return:
export interface GetUsersResult {
    ContactID: string;
    Email: string;
    FullName: string;
}


//Function Name: UpdateTeam

//Param: YoonitRequest < Teams >
export interface Team {
    TeamID: string;
    TeamName: string;
    TeamDescription: string;
    UserList: string;
}

//Return:
//YoonitResponse < int > { IntRes = ret }
//IntRes = -2 -> Team name already exists!
//IntRes > 0 -> Updated successfully
//IntRes < 0 -> Error contacting server | action failed

//Function Name: DeleteTeam

//Param: YoonitRequest<string>(req.objData = TeamID)

//Return:
//YoonitResponse < int > { IntRes = ret }
//IntRes = -2 -> you can’t delete the selected Team because it is linked to one or more user
//IntRes > 0 -> deleted successfully
//IntRes < 0 -> Error contacting server | action failed


//#region Bonus 
export class AddNewPromotionParams {
    IsEnabled: boolean = false;
    Title: string = "";
    Priority: number = 0;
    CurrencyID: number = 0;
    StartingDate: Date | string = '';
    EndingDate: Date | string = '';
    RemovalDate: Date | string = '';
    RTID: number = 0;
    RemovalDays: number | null = 0;
    PoolIDs: string = '';
    CountryIDs: string = "";
    ReferralID: string = "";
    IsCashBonusEnabled: boolean = false;
    Minimumdeposit: number = 0;
    LosableBonus: number = 0;
    DepositRange: number = 0;
    WelcomeParallel: boolean = false;//Deny Concurrent Bonus
    //main
    IsWelcomeBonusEnabled: boolean = false;
    WelcomeBonusTypeID: number = 0;
    WelcomeAmount: number = 0;
    WelcomeMax: number = 0;
    WelcomeHours: number = 0;
    //second
    IsWelcomeBonusEnabledSecond: boolean = false;
    WelcomeBonusTypeIDSecond: number = 0;
    WelcomeAmountSecond: number = 0;
    WelcomeMaxSecond: number = 0;
    //Loyalty
    IsLoyaltyBonusEnabled: boolean = false;
    LoyaltyBonusTypeID: number = 0;
    LoyaltyCumulative: boolean = false;
    LoyaltyAmount: number = 0;
    LoyaltyMax: number = 0;
    //Next
    IsNextBonusEnabled: boolean = false;
    NextBonusTypeID: number = 0;
    NextAmount: number = 0;
    NextMax: number = 0;
    NextStartingDate: Date | string = '';
    NextEndingDate: Date | string = '';
    IsCashPromotionDate: boolean = false;
    CashAccounts: string = "";
    CashBalanceLots: number = 0; //CashAmount
    CashScalping: number = 0;
    Comment: string = '';
    isAnd: boolean = false;
    ///for cash as to use same class
    DepositAmount: number = 0;
    BonusAmount: number = 0;
    AllowedPercentage: number = 0;
    BonusHours: number = 0;
    CashDays: number = 0;
    BonustoBalanceLots: number = 0;//CashLots
    ScalpingTrades: number = 0;
    CashCurrencyID: number = 0;
    isCommentType: boolean = false;
    isStopOut: boolean = false;
    CashBonuses: CashBonuses[] = [];
    RegistrationDate: Date | string = '';
    constructor($Priority?: number) {
        this.Priority = $Priority ? $Priority : 0;
    }

}
export interface CashPromotion {
    CashEnabled: boolean;
    Title: string;
    StartingDate: string;
    EndingDate: string;
    MinimumDeposit: number;//must check as not in desgin
    //DepositAmount: number;
    BonusAmount: number;
    AllowedPerc: number;
    BonusHours: number;
    CashDays: number;
    CashLots: number;//CashLots
    ScalpingTrades: number;
    CashCurrencyID: number;
    CashScalping: number;
    BonusComment: string;
    CashBonuses: CashBonuses[];
}
export class UpdateMarginCashPromotionParams {
    //common
    PID: number = 0;
    IsEnabled: boolean = false;
    Title: string = "";
    StartingDate: Date | string = new Date().toISOString();
    EndingDate: Date | string = new Date().toISOString();
    Minimumdeposit: number = 0;
    //IsParallel: boolean = false;
    Comment: string = "";
    BonustoBalanceLots: number = 0;//CashLots
    //margin
    PGID: number = 0;
    Priority: number = 0;
    RemovalDate: Date | string | null = new Date().toISOString();
    RTID: number = 0;
    RemovalDays: number = 0;
    LosableBonus: number = 0;
    DepositRange: number = 0;
    IsWelcomeBonusEnabled: boolean = false;
    WelcomeParallel: boolean = false;
    WelcomeBonusTypeID: number = 0;
    WelcomeAmount: number = 0;
    WelcomeMax: number = 0;
    WelcomeHours: number = 0;
    IsWelcomeBonusEnabledSecond: boolean = false;
    WelcomeBonusTypeIDSecond: number = 0;
    WelcomeAmountSecond: number = 0;
    WelcomeMaxSecond: number = 0;
    IsLoyaltyBonusEnabled: boolean = false;
    LoyaltyBonusTypeID: number = 0;
    LoyaltyCumulative: boolean = false;
    LoyaltyAmount: number = 0;
    LoyaltyMax: number = 0;
    IsNextBonusEnabled: boolean = false;
    isStopOut: boolean = false;
    NextBonusTypeID: number = 0;
    NextAmount: number = 0;
    NextMax: number = 0;
    NextStartingDate: Date | string = new Date().toISOString();
    NextEndingDate: Date | string = new Date().toISOString();
    IsCashPromotionDate: boolean = false;
    CashAccounts: string = "";
    CashBalanceLots: number = 0; //CashAmount
    ReferralID: string = "";
    CountryIDs: string = "";
    Groups: string = "";
    isCommentType: boolean = false;
    PoolIDs: string = '';
    OHTypeID: number = 0;
    isAnd: boolean = false;
    CashCurrencyID: number = 0;
    RegistrationDate: Date | string = new Date().toISOString();
}
export class UpdateCashPromotionParams {//CashPromotion class
    //common
    PID: number = 0;
    CashEnabled: boolean = false;
    isMargin: boolean = false;
    Title: string = '';
    StartingDate: Date | string = new Date().toISOString();
    EndingDate: Date | string = new Date().toISOString();
    CashAmount: number = 0;
    CashLots: number = 0;
    ScalpingTrades: number = 0;
    CashCurrencyID: number = 0;
    CashScalping: number = 0;
    BonusComment: string = '';
    CashBonuses: CashBonuses[] = [];
}

export interface DataTableBasicResult {
    rowTot: number;
    rowFilter: number;
}

export interface LoadBonusMainTableTableResult extends DataTableBasicResult {
    PID: number;
    Title: string;
    StartingDate: string;
    EndingDate: string;
    Status: boolean;
    CashEnabled: boolean;
    Type: string;
}

export interface LoadBonusClientsTableTableResult extends DataTableBasicResult {
    CreationDate: string;
    Email: string;
    Login: string;
    ServerType: string;
    ServerName: string;
    Name: string;
    GroupName: string;
    CountryName: string;
    Account: string;
    ExternalID: string;
    ClientID: string;
    BonusComment: string;
    ServerID: number;
    AccountID: number;
    IsWelcomeBonusEnabledSecond: boolean;
    IsWelcomeBonusEnabled: boolean;
    IsLoyaltyBonusEnabled: boolean;
    IsNextBonusEnabled: boolean;
}

export interface LoadBonusClientsTableParams extends TraderDataTable {
    PID: number;


}
export class GetBonusDetailsInfoResult {
    IsEnabled: boolean = false;
    Title: string = "";
    CurrencyID: number = 0;
    OHTypeID: number = 0;
    Priority: number = 0;
    StartingDate: Date | string = new Date().toISOString();
    EndingDate: Date | string = new Date().toISOString();
    RTID: number = 0;
    RemovalDays: number | null = 0;
    ReferralID: string = "";
    Minimumdeposit: number = 0;
    LosableBonus: number = 0;
    DepositRange: number = 0;
    IsWelcomeBonusEnabled: boolean = false;
    WelcomeParallel: boolean = false;
    WelcomeBonusTypeID: number = 0;
    WelcomeAmount: number = 0;
    WelcomeMax: number = 0;
    WelcomeHours: number = 0;
    IsWelcomeBonusEnabledSecond: boolean = false;
    WelcomeBonusTypeIDSecond: number = 0;
    WelcomeAmountSecond: number = 0;
    WelcomeMaxSecond: number = 0;
    IsLoyaltyBonusEnabled: boolean = false;
    LoyaltyBonusTypeID: number = 0;
    LoyaltyCumulative: boolean = false;
    LoyaltyAmount: number = 0;
    LoyaltyMax: number = 0;
    IsNextBonusEnabled: boolean = false;
    NextBonusTypeID: number = 0;
    NextAmount: number = 0;
    NextMax: number = 0;
    NextStartingDate: Date | string = "";
    NextEndingDate: Date | string = "";
    IsCashPromotionDate: boolean = false;
    CashAccounts: string = "";
    CountryIDs: string = "";
    Groups: string = "";
    isCommentType: boolean = false;
    Comment: string = "";
    ExcludedAccountTypes?: string = "";
}

export interface GetAddPromotionDDsResult {
    PromotionTypes: BasicSelectType[];
    Countries: BasicSelectType[];
    Pools: BasicSelectType[];

}

export interface BasicSelectType {
    Value: number;
    Label: string;
}
export interface AllocateClientParams {
    ClientID: string;
    PID: number;
    isDeleteClients?: boolean;
    isRemoveBonus?: boolean;
}
export interface AllocateCashClientParams {
    AccountID: number;
    CPID: number;
}
export class UploadCSVBonusParams {
    BinaryDocument: string = "";
    PID: number = 0;
    Currency: string = "";
    IsCheck: boolean = false;//new field
}
export class AllocateTradingAccountParams {
    PID: number = 0;
    ServerID: number = 0;
    ServerType: string = "";
    ServerName: string = "";
    Accounts: string = "";
    ExternalID: string = "";
    ClientIDs: string = "";
}
export interface GetUsersResult {
    ContactID: string;
    FullName: string;
    Email: string;
}
//#endregion
//#region Roles
export interface GetRolesResult {
    RoleID: number;
    RoleName: string;
    RoleDescription: string;
}
export class RoleDataNew {//  old class can be  removed
    RoleID: number | null = 0;
    CompanyID: string = "";
    RoleName: string = '';//not used in this request
    RoleDescription: string = '';//not used in this request
    Permissions: RolePermsLic[] | null = null;
}
export interface RolePermsLic {
    LicID: number;
    LicName: string;
    PermID: number;
    ParentPermID: number;
    PermName: string;
    PermValID: number;
    PermOrder: number;// tested on edit
    LicOrder: number;
}
//public class PermsLic
//   {
//       public int? LicID { get; set; }
//       public int? PermID { get; set; }
//       public int? PermValID { get; set; }
//   }

export interface PermissionsLicClass {
    CompanyID: string;
    IsAdmin: boolean;
    ContactID: string;
}
export interface GetUserRolesPermsList {
    TotalAdminUser: number;
    UsedAdminUser: number;
    TeamID: string;
    PermsLicListResult: PermsLicList[];
    RolesListResult: RolesList[];
    TeamListResult: GetEditTeamResult[];
}
export class UserPermsData {
    ContactID: string = '';
    TeamID: string = '';// in case of admin user
    RoleID: number | null = null;// in case of admin user
    IsAdmin: boolean = false;
    Perms: UserPermsLic[] = [];// in case of client you need to send all permissions but for admin user you need to send only the non- inherits permissions
}
export interface UserPermsLic {
    LicID: number | null;
    PermID: number | null;
    PermValID: number | null;//more then 2 options
}
export class UserRolesList {
    RoleID: number = 0;
    RoleName: string = "";
    LicID: number = 0;
    LicName: string = "";
    PermID: number = 0;
    PermName: string = "";
    ParentPermID: number = 0;
    PermValID: number = 0;
    OverridePerm: number = 0;
    PermOrder: number = 0;//tested
}
//#endregion
export class IBCodeRangesInfo {
    IBRID: number = 0;
    IBRDescription: string = '';
    IBRStart: number = 0;
    IBREnd: number = 0;
    IBRCurrent: number = 0;// currenct ibcode needed
    PoolID: number = 0;
}
export interface GetBonusPromotionTimeLineResult {
    PID: number;
    PromotionTitle: string;
    StartingDate: Date | string;
    EndingDate: Date | string;
}
export interface TeamMember {
    TeamID: string;
    ContactID: string;
}
export interface LoadPlatformLinksResult {
    PLID: number;
    PLDesc: string;
    PLLink: string;
    ServerID: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export class LoadCompanySMTPSettingsResult {
    SettingID: number = 0;
    Icon: string = "";
    CompanyName: string = "";
    CompanySite: string = "";
    CompanyAddress: string = "";
    IsCircular: boolean = false;
    LightLogo: string = "";
    DarkLogo: string = "";
    CompanyPhone: string = "";
    Logo: string = "";
    ClientWebsite: string = "";
    EnableSSL: boolean = false;
    Host: string = "";
    Port: number = 0;
    Email: string = "";
    Password: string = "";
    BOEmail: string = "";
    EmailUserName: string = "";
    EmailDisplayName: string = "";
    ETDefaultHTML: string = "";
    ETHeader: string = "";
    TradeComment: string = "";
    AccountComment: boolean = false;
    TransferComment: string = "";
    CompanyImage: string = "";
}
export interface PaymentTable extends DataTable {
    IsDeposit: boolean | null;//if both checked then IsDeposit=null
}
export class GetAllocatedClientsListResult {
    PID: number = 0;
    ClientRangeID: number = 0;
    ClientID: string = '';
    ClientName: string = '';
}
//#region payments 
export interface PaymentTableResult {
    PMID: number;
    PaymentName: string;
    DisplayName: string;
    PMComment: string;
    PMIsActive: boolean;
    IsDeposit: boolean;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface PaymentType {
    PTID: number;
    PaymentName: string;
    IsDeposit: boolean;
}
export interface PaymentList {
    IsDeposit: boolean | null;
    AllPayment: boolean | null;
}
export interface GetPaymentInfo {
    PaymentDetails: GetPaymentMethodsResult;//used to fill the payment info tab
    PaymentCurrencyRate: GetPaymentCurrenciesResult[]; //used to fill the payment rate
    GetBankNameList: GetBankNameResult[];  //used in case the transaction type is deposit payment type is bankwire to fill the bank name dropdown
}
export class GetPaymentMethodsResult {
    PMID: number = 0;
    PTID: number = 0;
    PMPayURL: string = '';
    PMClientID: string = '';
    PMClientSecret: string = '';
    PMEmail: string = '';
    PMSecretWord: string = '';
    PMMD5Code: string = '';
    PMCurrency: string = '';
    PMDescription: string = '';
    PMImageURL: string = '';
    PMFee: number = 0;
    PMFeeInfo: string = '';
    PMComment: string = '';
    PMIsActive: boolean = false;
    PMCreationDate: Date | string = '';
    PMFixedFee: number = 0;
    PMOrder: number = 0;
    MinimumAmount: number = 0;
    //IsAutoPaid: boolean = false;
    Instruction: string = '';
    FeeType: string = '';
    IsCoveredByCompany: boolean = false;
    HasTTCopy: boolean = false;
}
export interface GetPaymentCurrenciesResult {
    CurrencyID: number;
    AccCurrency: string;
    DWRID: number;
    PMID: number;
    Rate: number;
    IsDefault: boolean;
    CreationDate: Date | string;
}
export interface GetPaymentCurrenciesResultDisplay extends GetPaymentCurrenciesResult {
    IsEnabled: boolean;
}

export class GetBankNameResult {
    BID: number = 0;
    BankName: string = '';
    PMID: number = 0;
    BankAddress: string = '';
}
export interface GetBankWireDetailsResult {
    AccCurrencyID: number;
    AccCurrency: string;
    BWID: number | null;
    BID: number | null;
    CurrencyID: number | null;
    BeneficiaryName: string;
    Address: string;
    AccountNumber: string;
    Swift: string;
    IBAN: string;
    IsEnabled: boolean | null;
    IntermediaryBank: string;
    CorrespondentBank: string;
}
//#endregion
export interface CashBonusParams {
    CPCSymbols: string;
    CPCSecurities: string;
    CPCName: string;
    CPCRatio: number;
}
export interface AddCashBonusParams extends CashBonusParams {
    CPID: number;
}
export interface UpdateBonusParams extends CashBonusParams {
    CPCID: number;
}
export class CashBonuses {
    CPID: number = 0;
    CPCID: number = 0;
    CPCName: string = "";
    CPCSymbols: string = "";
    CPCSecurities: string = "";
    CPCRatio: number = 0;
}
export class OldCashGroup {
    PID: number = 0;
    CashID: number = 0;
    CashName: string = "";
    CashSymbols: string = "";
    CashSecurities: string = "";
    CashRatio: number = 0;
}

export class GetCashBonunsDetails {
    GetBonusCashListResult: GetBonusCashListResult[] = [];
    GetCashPromotion: GetCashPromotionClass = new GetCashPromotionClass();
}
export interface GetBonusCashListResult {
    CPCID: number;
    CPCSymbols: string;
    CPCName: string;
    CPCSecurities: string;
    CPCRatio: boolean;
}
export class GetBonusSettingsDetailsResult {
    BonusSettingsDetails: BonusSettingsDetails = new BonusSettingsDetails();
    APIType: BasicSelectType[] = [];
    NegativeBalanceType: BasicSelectType[] = [];
    RemoveCreditType: BasicSelectType[] = [];
    StopOutType: BasicSelectType[] = [];
    OnHoldType: BasicSelectType[] = [];
}
export class BonusSettingsDetails {
    SettingsID: number = 0;
    APITypeID: number = 0;
    SOTypeID: number = 0;
    NBTypeID: number = 0;
    RCTypeID: number = 0;
    IsEmail: number = 0;
    IsZipCode: number = 0;
    //IsMQID: number = 0;
    //IsIP: number = 0;
    TradeComment: string = '';
    WithdrawalComment: string = '';
    APIToken: string = '';
    ReqType: string = '';
}
export interface PaymentMethodInfo {
    PaymentInfo: PaymentMethodDetails;// to be filled from Payment Info
    PaymentRate: DWRates[] | null;// to be filled from Payment Rate tab
    BankWireInfo: BankWireDetails | null;//used in case ofBankWire: Deposit; to be filled from Bank Info tab
}
export interface PaymentMethodDetails {
    PTID: number;
    PMOrder: number;
    MinimumAmount: number;
    PMPayURL: string;
    PMClientID: string;
    PMClientSecret: string;
    PMEmail: string;
    PMSecretWord: string;
    PMMD5Code: string;
    PMCurrency: string;
    PMDescription: string;
    PMComment: string;
    PMIsActive: boolean;
    //IsAutoPaid: boolean;
    FeeType: string;
    PMFee: string;
    PMFixedFee: string;
    IsCoveredByCompany: boolean;
    HasTTCopy: boolean;
    Instruction: string;
}
export interface DWRates {
    DWRID: number;
    PMID: number;
    CurrencyID: number;
    Rate: number;
    isDefault: boolean;
}
export class BankWireDetails {
    BankName: string = '';
    BankAddress: string = '';
    PMID: number = 0;
    BID: number = 0;
    BankWireDetailsList: BankWireDetailsList[] = [];
}
export interface BankWireDetailsList {
    BWID: number;
    CurrencyID: number;
    IsEnabled: boolean;
    BeneficiaryName: string;
    Address: string;
    AccountNumber: string;
    Swift: string;
    IBAN: string;
    IntermediaryBank: string;
    CorrespondentBank: string;
}
export interface PermissionsValue {
    PermValID: number;
    PermValName: string;
}

export class AccountsInfo {
    Login: string = '';
    IsReadOnly: boolean = false;
    Name: string = '';
    Email: string = '';
    Phone: string = '';
    MTStatus: string = '';
    IDNumber: string = '0';
    Country: string = '';
    City: string = '';
    State: string = '';
    Address: string = '';
    ZipCode: string = '';
    MetaQuotesID: string = '0';
    PhonePassword: string = '';
    TaxRate: string = '0';
    LeadSource: string = '';
    Comment: string = '';
}

export interface AccInfoData {
    AccLeverage: AccLeverages[];
    AccType: AccTypes[];
    Currency: AccCurrencies[];
    MyAccount: AccountsInfo;
}

export class RequestsDataSB {
    //AutoCopy
    CopierAccount: string = "";
    MasterAccount: string = "";
    ARule: string = "";
    IsReverse: boolean = false;

    //Deposit - Withdrawal
    PaymentMethod: string = "";
    PaymentName: string = "";
    Currency: string = "";
    BankName: string = "";
    BenifName: string = "";
    BenifAddress: string = "";
    BenifAccNumber: string = "";
    BankAddress: string = "";
    Swift: string = "";
    IBAN: string = "";
    ttCopy: string = "";
    ClientEmail: string = "";
    MerchantID: string = "";
    //missing from API
    Branch: string = "";
    CardHolderName: string = "";
    Province: string = "";
    //withdrawal banwires 
    CardNumber: string = "";
    BankCurrency: string = "";
    BenifBankName: string = "";
    //account info
    OldAccType: string = "";//only can edit leverage in client /admin no 
    AccType: string = "";
    AccLeverage: string = "";
    OldAccLeverage: string = "";
    IsReadOnly: boolean = false;
    Name: string = "";
    Email: string = "";
    Phone: string = "";
    MTStatus: string = "";
    IDNumber: string = "";
    Country: string = "";
    City: string = "";
    State: string = "";
    Address: string = "";
    ZipCode: string = "";
    MetaQuotesID: string = "";
    PhonePassword: string = "";
    TaxRate: string = "";
    LeadSource: string = "";
    Comment: string = "";
    //Transfer
    ToAcc: string = "";
    Platform: string = ""; //4 or 5, sent to method to find server acc to platform / used in new acc as well, check as none on null

    //New Account
    CreationReason: string = "";
    ReferralCode: string = "";
    AccLeverageID: number = 0;
    AccCurrencyID: number = 0;
    AccTypeID: number = 0;
    ClientID: string = "";
    FullName: string = "";
    ServerPlatform: string = "";
    AccCurrency: string = "";
    MIBTxt: string = "";


    //Admin Request
    AutomaticLogin: boolean = false;
    ServerID: number = 0;
    Login: string = "";
    LRID: number = 0;
    CountryID: number = 0;
    CountryName: string = "";
    NewLogin: boolean = false;
    //IB
    IsIB: boolean = false;
    IBRID: number = 0;
    MasterIB: number = 0;
    MasterServerID: number = 0;
    IBCode: string = "";
    Account: string = "";
    Amount: string = "";
    ConvertedAmount: string = "";
    Note: string = "";

    OrderID: string = "";
    Governorate: string = "";
    AccountType: string = "";
}
export interface GetClientListBySearchKey {
    SearchKey: string;
    AUID: string;
}

export interface LoadAppClientListResult {
    ClientRangeID: string;
    ClientID: string;
    text: string;
    rowFilter: number | null;
}
export interface LoadMMClientAccountsParams {
    ClientID: string;
    AccTypeID: number;
}
export interface LoadMMClientAccounts {
    AccountID: number;
    Login: string;
}
export interface AccountValidation {
    IsDBExists: boolean;
    IsManagerExists: boolean;
    HasMMProfile: boolean;
}

export interface AddMAMAccProfileRequest {
    //ActionIsClient: boolean;
    Trader: MAMTraderClass;
    Profile: ProfilesClass;
    ProfileLogins: string;
    groups: ProfileGroups[];
    PFAgents: AgentAccClass[];
    LoginGroup: LoginGroup[];
    FeeAgents: FeeAgentsClass;
}
export class MAMTraderClass {
    ServerName: string = '';
    ServerType: string = "";
    Login: string = "";
    AccountID: number = 0;
}
export class UserPermissionOBjectClass {
    Allowed: boolean = false;
    Perms: IPerms[] = [];
}
export interface LoadMasterTableResult {
    ClientID: string;
    AccountID: number;
    Name: string;
    Email: string;
    ServerID: number;
    ServerType: string;
    ServerName: string;
    MasterLogin: string;
    ProfileID: number | null;
    ProfileStrategyName: string;
    ARuleID: number | null;
    ARuleName: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export class GetEditTeamResult {
    TeamID: string = '';
    TeamName: string = '';
    TeamDescription: string = '';
    UserList: GetUsersResult[] = [];
    AccTypeListResult: AccTypes[] = [];
    PoolListResult: GetPoolsResult[] = [];
}
export interface AccTypes {
    AccTypeID: number;
    AccType: string;
    IsChecked: boolean;
}
export interface GetPoolsResult {
    PoolID: number;
    PoolName: string;
    IsChecked: boolean;
}
//GetClientAccPermissions
//param: req.AUID
//return:
export interface GetClientAccPermissions {
    AccTypeListResult: AccTypes[];
    PoolListResult: GetPoolsResult[];
    GetUsersResult: GetUsersResult[];
}
export interface LoadProfileTemplatesResult {
    ProfileID: number | null;
    ProfileName: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface DeleteProfileRequest {
    IsTemplate: boolean;
    ProfileID: number;
}
export interface BlockUserClass {
    ContactID: string;
    Email: string;
    IsBlocked: boolean; //true to block user and false to unblock
}
export interface GetProfileTemplateDataResult {
    PFIsEnabled: number;
    ProfileID: number;
    CloseSubPositions: number;
    ProfileWDClosure: number;
    AllowSignalOwnTrades: number;
    AutoFeePayments: number;
    ProfileEnabled: number;
    ProfileStrategyName: string;
    ProfileGroups: string;
    ProfileLogins: string;
    MF: number;
    ProfilePeriodPay: string;
    ProfilePeriodPayID: number;
    ProfileLoginPay: number;
    ProfileEnableMarkup: number;
    ProfileEnableCommission: number;
    ProfileEnableSwaps: number;
    ProfileMarkupAccount: number;
    ProfileIsMinLot: boolean;
    isReverse: boolean;
    SyncBalance: boolean;
    Percentage: number;
    //PermIsSignal: boolean;
    ARuleID: number;
    ARuleName: string;
    STPText: string;
    STPID: number;
    CopierSTPText: string;
    CopierSTPID: number;
    CopiersNb: number;
}
export interface GetProfileTemplatesDropDown {
    ProfileID: number;
    ProfileName: string;
}

export interface MAMAdminRequests {
    ProfileID: number;
    IsDefault: boolean;
    AccountID: number;
    AUID: string;
}
export interface AddInvestorRequest {
    ProfileID: number;
    //ProfileGroups: string;
    ProfileLogins: string;
    MasterLogin: string;
    ProfileStrategyName: string;
    //loginGroups: LoginGroup[];
}
export class TraderInfo {
    ProfileID: number = 0;
    AccountID: number = 0;
    ARuleID: number = 0;
    IsReverse: boolean = false;
    Ticket: number = 0;
    Login: string = "";
    Amount: number = 0;
    AruleName: string = '';
    InvType: number = 0;
    OldInvType: number = 0;
    FromAccount: string = '';
    Status: number = 0;
}
export interface TraderInfo {
    BulkAll: boolean;
    ProfileID: number;
    SignalID: number;
    AccountID: number;
    ARuleID: number;
    IsReverse: boolean;
    Ticket: number;
    Login: string;
    Amount: number;
    AruleID: number;
    STPID: number;
    InvType: number;
    FromAccount: string;
    Status: number;
    IsMinLot: boolean;
    IsAdmin: boolean;
}
export class UpdateProfileRequest {
    IsAruleChanged: boolean = false;
    Profile: ProfilesClass = new ProfilesClass();
    groups: ProfileGroups[] = [];
    Removedgroups: ProfileGroups[] = [];
    Addedgroups: ProfileGroups[] = [];
    PFAgents: AgentAccClass[] = [];
}
export class ProfilesClass {
    ClientID: string = '';
    ProfileID: number = 0;
    AccountID: number = 0;
    ServerID: number = 0;
    ServerName: string = '';
    ServerType: string = "";
    ARuleID: number = 0;
    STPID: number = 0;
    CopierSTPID: number = 0;
    MasterLogin: string = '';
    ProfileCloseSubPositions: boolean = false;
    ProfileWDClosure: boolean = false;
    ProfileEnabled: boolean = false;
    ProfilePFEnabled: boolean = false;
    ProfileStrategyName: string = '';
    ProfileIsMinLot: boolean = false;
    ProfileEnableMarkup: boolean = false;
    ProfileEnableCommission: boolean = false;
    ProfileEnableSwaps: boolean = false;
    ProfileMarkupAccount: number = 0;
    ProfileSyncBalance: boolean = false;
    PFPID: number = 0;
    ProfileMaxLot: number = 0;
    ProfileDefaultAmount: number = 0;
    AutoForcePFOnExclude: boolean = false;
    IsRoundVol: boolean = false;
    DeductPF: boolean = false;
    DeletePFRecords: boolean = false;

    ProfileEnableRegistrationFee: boolean = false;
    ProfileEnableManagementFee: boolean = false;

    //ManagementPFAmount: boolean = false;
    //RegistrationPFAmount: boolean = false;
}
export class GetApplicationTypeResult {
    KTID: number = 0;
    TypeName: string = "";
    RequireKYC: boolean = false;
    IsEnabledType: boolean = false;
    PoolID: number = 0;
    KTCreationDate: Date | string | null = "";
    MainJson: string = "";
}
export class AddApplicationSetting {
    ApplicationType: GetApplicationTypeResult = new GetApplicationTypeResult();
    ApplicationSetting: ApplicationSettingsClass = new ApplicationSettingsClass();
}
export class ApplicationSettingsClass {//try edit name in import next time 
    KTID: number = 0;
    KPID: number = 0;
    Salutation: boolean = false;
    FirstName: boolean = false;
    MiddleName: boolean = false;
    LastName: boolean = false;
    DateOfBirth: boolean = false;
    Phone: boolean = false;
    SecondPhone: boolean = false;
    EmailPerm: boolean = false;
    CountryOfBirth: boolean = false;
    Nationality: boolean = false;
    MaritalStatus: boolean = false;
    USAResidentQsn: boolean = false;
    USATaxPurposesQsn: boolean = false;
    PoliticallyPersonQsn: boolean = false;
    DeclaredBankruptcyQsn: boolean = false;
    Country: boolean = false;
    ResidentialAddress: boolean = false;
    City: boolean = false;
    State: boolean = false;
    StreetName: boolean = false;
    BuildingNumber: boolean = false;
    PostalCode: boolean = false;
    AccountType: boolean = false;
    BaseCurrency: boolean = false;
    Platform: boolean = false;
    Leverage: boolean = false;
    Education: boolean = false;
    AmountToInvest: boolean = false;
    LiquidNetWorth: boolean = false;
    AnnualNetIncome: boolean = false;
    AnnualIncome: boolean = false;
    TotalPortfolio: boolean = false;
    NetWorth: boolean = false;
    PlannedInvestment: boolean = false;
    PurposeInvesting: boolean = false;
    NumberOfDependents: boolean = false;
    AmountToInvest12Months: boolean = false;
    EmploymentStatus: boolean = false;
    CurrentEmployer: boolean = false;
    CurrentPosition: boolean = false;
    Industry: boolean = false;
    IndustryRetired: boolean = false;
    IndustryStudent: boolean = false;
    NatureOfBusiness: boolean = false;
    Occupation: boolean = false;
    EmploymentAddress: boolean = false;
    EmploymentCity: boolean = false;
    EmploymentCountry: boolean = false;
    EmploymentPostalCode: boolean = false;
    SourceOfIncome: boolean = false;
    SourceOfIncomeStudent: boolean = false;
    PreviousEmployer: boolean = false;
    PreviousPosition: boolean = false;
    TaxResidencyCountry: boolean = false;
    TaxNumber: boolean = false;
    SourceOfWealth: boolean = false;
    SecondaryIncomeQsn: boolean = false;
    WorkExperienceQsn: boolean = false;
    AttendedCoursesQsn: boolean = false;
    NationalPassportQsn: boolean = false;
    MonthlyAverageVolume: boolean = false;
    PurposeAndNatureTrading: boolean = false;
    PurposeOpeningAcc: boolean = false;
    LevelKnowledgeFinancialMarkets: boolean = false;
    LongHaveTraded: boolean = false;
    TradesPerMonth: boolean = false;
    TimeframeHeldPositions: boolean = false;
    TypeInstrumentsFamiliar: boolean = false;
    WhatExtentTradedFunds: boolean = false;
    WhatExtentTradedDerivatives: boolean = false;
    WhatExtentTradedWithMargin: boolean = false;
    AverageYearlyVolume: boolean = false;
    AverageLevelLeverage: boolean = false;
    UnderstandLeveragedProductsQsn: boolean = false;
    UnderstandConceptsVolatilityQsn: boolean = false;
    QualificationLeveragedQsn: boolean = false;
    UnderstandRisksWithTradingQsn: boolean = false;
    LevelEducationOurProducts: boolean = false;
    EducationalFinancial: boolean = false;
    TradingHigherLeverageQns: boolean = false;
    InitialPositionQns: boolean = false;
    FuturesMarketQns: boolean = false;
    OTCDerivativesDifferentQns: boolean = false;
    OTCDerivativesPlacedQns: boolean = false;
    StopLossOrderUsedQns: boolean = false;
    RiskiestInvestmentQns: boolean = false;
    MarginRequiredQns: boolean = false;
    StoppedOutSituationsQns: boolean = false;
    TradingHigherLeverage: boolean = false;
    ForeignExchange: boolean = false;
    Shares: boolean = false;
    MutualFundsOfShares: boolean = false;
    GovernmentAndCorporateBonds: boolean = false;
    CFDs: boolean = false;
    CommoditiesPreciousMetals: boolean = false;
    OptionsOrFutures: boolean = false;
    OtherInvestments: boolean = false;
    HowTradedTheseProducts: boolean = false;
    PassportID: boolean = false;
    NationalCardID: boolean = false;
    DriverLicenseID: boolean = false;
    CountryIssuance: boolean = false;
    CountryIssuanceNationalID: boolean = false;
    CountryIssuanceDriverID: boolean = false;
    PassportNumber: boolean = false;
    PassportNumberNationalID: boolean = false;
    PassportNumberDriverID: boolean = false;
    ExpiryDate: boolean = false;
    ExpiryDateNationalID: boolean = false;
    ExpiryDateDriverID: boolean = false;
    BankStatement: boolean = false;
    LetterFromGovernmentAgency: boolean = false;
    Utilitybill: boolean = false;
    OtherProofOfAddressType: boolean = false;
    ProofAddressValid: boolean = false;
    PersonalDetails: boolean = false;
    InvestorInfo: boolean = false;
    TradingExperience: boolean = false;
    TradingKnowledge: boolean = false;
    TraderDeclaration: boolean = false;
    Documents: boolean = false;
    BrokerApplication: boolean = false;
    PersonalDetailsDesc: boolean = false;
    InvestorInfoDesc: boolean = false;
    TradingExperienceDesc: boolean = false;
    TradingKnowledgeDesc: boolean = false;
    TraderDeclarationDesc: boolean = false;
    DocumentsDesc: boolean = false;
    BrokerApplicationDesc: boolean = false;
    BankName: boolean = false;
    CardHolderName: boolean = false;
    BankCardID: boolean = false;
    IsFillDocumentRequired: boolean = false;
    ProfessionalClientStatus: boolean = false;
    ProfessionalClientStatusDesc: boolean = false;
    ProfessionalClientStatusDescTxt: string = "";
    MonthlyExpenses: boolean = false;
    EstimatedAnnualInvestment: boolean = false;
    NetValueWealth: boolean = false;
    SourceOfFunds: boolean = false;
    PlannedInitialInvestment: boolean = false;
    ValueRentalProp: boolean = false;
    MonthlyRentalIncome: boolean = false;
    Profession: boolean = false;
    NameEmployerOrganisation: boolean = false;
    CountryBankAccount: boolean = false;
    ProfessionRelateFinancialIndustryQsn: boolean = false;
    UsedLeveragedProductsQsn: boolean = false;
    MaximumLeverageUsed: boolean = false;
    StopOutBeTriggered: boolean = false;
    PassportID2: boolean = false;
    NationalCardID2: boolean = false;
    DriverLicenseID2: boolean = false;
    CountryIssuance2: boolean = false;
    CountryIssuanceNationalID2: boolean = false;
    CountryIssuanceDriverID2: boolean = false;
    PassportNumber2: boolean = false;
    PassportNumberNationalID2: boolean = false;
    PassportNumberDriverID2: boolean = false;
    ExpiryDate2: boolean = false;
    ExpiryDateNationalID2: boolean = false;
    ExpiryDateDriverID2: boolean = false;
    TrueAboutFuturesQns: boolean = false;
    WhySpreadFloatingQns: boolean = false;
    PersonalDetailsDescTxt: string = "";
    InvestorInfoDescTxt: string = "";
    TradingExperienceDescTxt: string = "";
    TradingKnowledgeDescTxt: string = "";
    TraderDeclarationDescTxt: string = "";
    DocumentsDescTxt: string = "";
    BrokerApplicationDescTxt: string = "";
    IdentificationDoc1: boolean = false;
    IdentificationDoc2: boolean = false;
    ProofAddDoc: boolean = false;
    BankDetailsDoc: boolean = false;
}
export interface LoadIBClientAccountsParams {
    ClientID: string;
    AccTypeID: number;
}
export interface LoadIBClientAccountsResult {
    AccountID: number;
    Login: string;
}
export interface GetRegistrationLinks {
    RLID: number;
    RegDescription: string;
    RegLink: string;
}

export interface RegistrationLinks {
    RLID: number;
    RegDescription: string;
    RegLink: string;
}
export class GetProfilesDataResult {
    ProfileID: number = 0;
    ServerName: string = '';
    ServerID: number = 0;
    ServerType: string = "";
    MasterLogin: string = '';
    CloseSubPositions: number = 0;
    ProfileWDClosure: number = 0;
    ProfileEnabled: number = 0;
    PFIsEnabled: number = 0;
    ProfileStrategyName: string = '';
    ProfileGroups: string = '';
    ProfileLogins: string = '';
    ProfileEnableMarkup: number = 0;
    ProfileEnableCommission: number = 0;
    ProfileEnableSwaps: number = 0;
    ProfileMarkupAccount: number = 0;
    ProfileIsMinLot: boolean = false;
    SyncBalance: boolean = false;
    ARuleID: number = 0;
    ARuleName: string = '';
    STPText: string = '';
    STPID: number = 0;
    CopierSTPText: string = '';
    CopierSTPID: number = 0;
    AccountID: number = 0;
    Email: string = '';
    AccTypeID: number = 0;
    PFPID: number = 0;
    ProfileMaxLot: number = 0;
    IsRoundVol: boolean = false;
    DeductPF: boolean = false;
    DeletePFRecords: boolean = false;
    ProfileDefaultAmount: string | number = '';
}
//#region IBBanner 
export interface IBDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}
export interface GetBannersTableResult {
    BID: number;
    BannerName: string;
    RegName: string;
    Type: string;
    Size: string;
    CampaignType: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface GetBannerDropdownResult {
    BannerTypeList: BannerType[];
    RegLinkList: GetRegistrationLinks[];
    IBList: GetIBs[];
    PoolList: GetPoolsResult[];
}
export interface BannerType {
    BannerTypeID: number;
    TypeName: string;
}
export interface GetRegistrationLinks {
    RLID: number;
    RegDescription: string;
    RegLink: string;
}
export interface GetIBs {
    ClientID: string;
    ClientName: string;
    ClientRangeID: string;
    IBCode: string;
}
export interface GetIBBanners {
    BID: number;
    PoolID: number;
    RLID: number;
    ClientID: string[];
    BannerTypeID: number;
    BannerName: string;
    BannerPath: string;
    Size: string;
    Type: string;
    BannerDoc: string;
}
export interface IBBanners {
    BID: number;
    PoolID?: number | null;
    RLID: number;
    ClientID: string;
    BannerTypeID: number;
    BannerName: string;
    Size: string;
    Type: string;
    BannerDoc: string;
    BannerOrder: number;
    CustomLink: string;
}
export interface SelectType<T> {
    value: number;
    label: string;
    Data: T;
}
//#endregion

export interface LogsStorageResult {
    ActionRequest: string;
    ModuleName: string;
    ActionName: string;
    AUID: string;
    BUID: string;
    IP: string;
    Email: string;
    ActionTime: Date | string;
    ActionTimeStr: string;
    Result: string;
    ActionDescription: string;
    ClientName: string;
}
export interface TraderDateParams {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
    FromDay: number;
    ToDay: number;
    FromMonth: number;
    ToMonth: number;
    FromYear: number;
    ToYear: number;
}
export class GetKYCCardResult {
    CardPerm: GetKYCCardPermsResult = new GetKYCCardPermsResult();
    CardStatus: GetKYCCardStatusResult = new GetKYCCardStatusResult();
    CardCompliance: GetKYCComplianceAnswersResult = new GetKYCComplianceAnswersResult();
    UpdateKYCReqStatus: number | null = 1;
}
export class GetKYCCardPermsResult {
    PersonalDetails: boolean = false;
    InvestorInfo: boolean = false;
    TradingExperience: boolean = false;
    TradingKnowledge: boolean = false;
    TraderDeclaration: boolean = false;
    Documents: boolean = false;
    ProfessionalClientStatus: boolean = false;
    BrokerApplication: boolean = false;
    IsFillDocumentRequired: boolean = false;
}
export class GetKYCCardStatusResult {
    PersonalInfoStatus: number = 2;
    WorkExpStatus: number = 2;
    TradingExpStatus: number = 2;
    TraderDecStatus: number = 2;
    DocStatus: number = 2;
    Status: number = 2;
}
export interface ProfileGroups {
    ProfileID: number;
    GroupName: string;
}
export class LoadProfilesDataSBResult {
    PD: GetProfilesDataResult = new GetProfilesDataResult();
    PAPF: GetPAPFSBResult[] = [];
    PGps: ProfileGroups[] = [];
    LoadWallets: GetClientWalletsResult[] = [];
}
export interface PersonalInfoCard {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    FirstName: string;
    LName: string;
    Phone: string;
    Salutation: number;
    SalutationTxt: string;
    DOB: string | null;
    CountryOfBirthID: number;
    NationalityID: number;
    MaritalStatus: string;
    ResidentialAddress: string;
    State: string;
    City: string;
    MiddleName: string;
    SecondPhone: string;
    StreetName: string;
    PostalCode: string;
    Country: number;
    TaxResidencyCountry: number;
    TaxNumber: boolean;
    TaxNumberTxt: string;
    TINUnavailable: string;
    TINUnavailableTxt: string;
    USAResidentQsn: boolean;
    PoliticallyPersonQsn: boolean;
    DeclaredBankruptcyQsn: boolean;
}
export interface WorkEducationalExperienceCard {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    EstimatedAmountInvest: string;
    Education: string;
    EmploymentStatus: string;
    BusinessOwnershipSF: boolean;
    FamilyIncomeRelationshipSF: boolean;
    InsurancePayoutSF: boolean;
    ReturnInvestmentsSharesDividendsSF: boolean;
    PropertyLandInvestmentsSF: boolean;
    InheritanceSF: boolean;
    IncomeEmploymentSF: boolean;
    BankingFacilitiesSF: boolean;
    PersonalSavingSF: boolean;
    WorkExperienceQsn: boolean;
    AttendedCoursesQsn: boolean;
    CurrentEmployer: string;
    CurrentPosition: string;
    Industry: string;
    NatureOfBusiness: string;
    Occupation: string;
    EmploymentAddress: string;
    EmploymentCity: string;
    EmploymentCountry: string;
    EmploymentPostalCode: string;
    ValueRentalProp: string;
    MonthlyRentalIncome: string;
    SourceOfIncome: string;
    PreviousEmployer: string;
    PreviousPosition: string;
    IndustryRetired: string;
    SourceOfIncomeStudent: string;
    IndustryStudent: string;
    HoldProfessionalQualification: boolean;
    ReceivedHigherFinanceEducation: boolean;
    HoldBachelorOtherSubjects: boolean;
    WorkedRoleRelevantTrading: boolean;
    NoneAbove: boolean;
    EducationalFinancial: string;
    EducationalFinancialTXT: string;
}

export interface TradingExperienceKnowledgeCard {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    IFCurrencies: boolean;
    IFCommodities: boolean;
    IFIndices: boolean;
    IFStocks: boolean;
    IFCryptoCurrency: boolean;
    PurposeNatureTrading: string;
    RiskiestInvestmentQns: string;
    WhySpreadFloatingQns: string;
    UsedLeveragedProductsQsn: boolean;
    UnderstandConceptsVolatilityQsn: boolean;
    UnderstandLeveragedProductsQsn: boolean;
    QualificationLeveragedQsn: boolean;
    UnderstandRisksWithTradingQsn: boolean;
    ProfessionRelateFinancialIndustryQsn: boolean;
    InitialPositionQns: boolean;
    FuturesMarketQns: boolean;
    TrueAboutFuturesQns: string;
    TradingHigherLeverageQns: boolean;
    LevelKnowledgeFinancialMarkets: string;
    LongHaveTraded: string;
    MonthlyAverageVolume: string;
    TimeframeHeldPositions: string;
    WhatExtentTradedDerivatives: string;
    WhatExtentTradedWithMargin: string;
    AverageYearlyVolume: string;
    AverageLevelLeverage: string;
    MaximumLeverageUsed: string;
    StopLossOrderUsedQns: string;
    MarginRequiredQns: string;
    StoppedOutSituationsQns: string;
    TradingHigherLeverageApplyQns: string;
    StopOutBeTriggered: string;
    ForeignExchangeLevel: string;
    ForeignExchangeYears: string;
    ForeignExchangeTradesNo: string;
    SharesLevel: string;
    SharesYears: string;
    SharesTradesNo: string;
    MutualFundsOfSharesLevel: string;
    MutualFundsOfSharesYears: string;
    MutualFundsOfSharesTradesNo: string;
    GovernmentCorporateBondsLevel: string;
    GovernmentCorporateBondsYears: string;
    GovernmentCorporateBondsTradesNo: string;
    CFDsLevel: string;
    CFDsYears: string;
    CFDsTradesNo: string;
    CommoditiesPreciousMetalsLevel: string;
    CommoditiesPreciousMetalsYears: string;
    CommoditiesPreciousMetalsTradesNo: string;
    OptionsOrFuturesLevel: string;
    OptionsOrFuturesYears: string;
    OptionsOrFuturesTradesNo: string;
    OtherInvestmentsLevel: string;
    OtherInvestmentsYears: string;
    OtherInvestmentsTradesNo: string;

}

export interface TraderDeclarationCardClass {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    TraderDec: TraderDeclaration;
    ProfClntStatus: ProfClntStatus;
}

export interface TraderDeclaration {
    DID: number;
    Answer: boolean;
}

export interface ProfClntStatus {
    TradedSignificantSize: boolean;
    SizeFinancialPortfolio: boolean;
    WorkFinancialSector: boolean;
    ProvideAverageNumber: string;
    RelevantEmployerName: string;
    EmployerRegulatedFinancial: boolean;
    HowRoleProvidedExperience: string;
    RoleHoldWithEmployer: string;
    RoleHoldWithEmployerOther: string;
    IUnderstand: boolean;
}
export interface CreateFirstUser {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    CompanyID: string;
    UserAuthID: string;
}
export interface IdentificationDocuments {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    DocType: number;
    IDType: string;
    DocCountryIssuanceID: string;
    DocIDNumber: string;
    DocExpiryDate: string | null;
    FrontIDName: string;
    BackIDName: string;
}

export interface ProofAddressDocuments {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    ProofAddressType: string;
    ProofAddressValid: boolean;
    ProofAddrName: string;
}

export interface BankDetailsDocuments {
    ClientID: string;
    Status: number;
    TotalScore: number;
    IsAdmin: boolean;
    AdminUsername: string;
    BankName: string;
    CardHolderName: string;
    BankCardID: string;
    InfrontBankName: string;
    BackBankName: string;
}
export interface Language {
    Code: string;
    Name: string;
    LanguageID: string;
}
export interface EmailTemplateType {
    ETTypeID: string;
    ETType: string;
    IsMultiple: boolean;
}
export interface GetEmailTemplateDropDownsResult {
    Languages: Language[];
    EmailTemplateTypes: EmailTemplateType[];

}
export interface GetEmailTemplatesTableResult {
    ETID: number;
    ETTypeID: number;
    ETType: string;
    ETDescription: string;
    IsMultiple: boolean;
}

export interface GetEmailTemplateParams {
    ETID: number;
    ETTypeID: number;
    LanguageID: number;


}
export class GetEmailTemplateResult {
    ETIID: number = 0;
    ETID: number = 0;
    LanguageID: number = 0;
    ETSubject: string = '';
    ETContent: string = '';
    ETAdminSubject: string = '';
    ETAdminContent: string = '';
    ETIsEnabled: boolean = false;
    ETTypeID: number = 0;
    ETName: string = '';
    ETDescription: string = '';
    BOEmails: string = '';
    ClientBOEmails: string = '';
}

export interface GetDefaultEmailTemplateResult {
    ETTID: number;
    LanguageID: number;
    ETSubject: string;
    ETContent: string;
}
export interface ApplicationFormReq {
    ClientID: string;
    ESignature: string;
    IsAdmin: boolean;
    RiskWarning: boolean;
    AdminUsername: string;
}
export interface ApplicationFormClass {
    ClientID: string;
    IsAdmin: boolean;
    Status: number;
    AdminUsername: string;
    ESignature: string;
    RiskWarning: boolean;
    TotalScore: number;
    PersonalInfo: PersonalInfoCard;
    WorkEducationalExperience: WorkEducationalExperienceCard;
    TradingExperienceKnowledge: TradingExperienceKnowledgeCard;
    TraderDeclaration: TraderDeclarationCardClass;
    IdentificationTypeDoc1: IdentificationDocuments;
    IdentificationTypeDoc2: IdentificationDocuments;
    ProofAddDoc: ProofAddressDocuments;
    BankDetailsDoc: BankDetailsDocuments;
}
export class GetBannerLink {
    BannerName: string = '';
    TypeName: string = '';
    YoonitURL: string = '';
    BannerPath: string = '';
    Size: string = '';
    Type: string = '';
    BannerDoc: string = '';
    RegLink: string = '';
    PoolID: string = '';
    ReferralIDName: string = '';
    IBBannerDetails: IBBannerDetails[] = [];
}

export class IBBannerDetails {
    ClientID: string = '';
    ClientName: string = '';
    IBCode: string = '';
}

export interface UpdateSignalsProfileRequest {
    ServerName: string;
    ServerType: string;
    AccountID: number;
    SignalID: number;
    SignalProfile: SignalsProfile[];

}
export interface Signals {
    ClientID: string;
    SignalID: number;
    ServerID: number;
    AccountID: number;
    SignalName: string;
    Login: string;
    Currency: string;
    Balance: number;
    Equity: number;
    ServerType: string;
    ServerName: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface SignalConfigurations {
    SCID: number;
    SignalID: number;
    SCName: string;
    SCSymbols: string;
    SCSecurities: string;
    SCAmount: number;
}
export interface GetTraders {
    AccountID: number;
    Login: string;
    Name: string;
}
export interface SignalCopiers {
    CopierID: number;
    SignalID: number;
    AruleName: string;
    CopierAccID: number;
    CopierLogin: string;
    STPID: number;
    Amount: number;
    IsReverse: boolean;
    IsMinLot: boolean;
}
export class GetSignalDataResult {
    ServerID: number = 0;
    AccountID: number = 0;
    ServerType: string = "";
    ServerName: string = "";
}
export interface Copiers {
    CopierID: number;
    Amount: number;
    IsReverse: boolean;
    ARuleID: number;
    STPID: number;
}
export interface PushNotification {
    PNID: number;
    PNDescription: string;
    ParentPNID: number;
    OrderNumber: number;
    IsEnabled: boolean;
}
export interface EmailNotification {
    ENID: number;
    ENDescription: string;
    ParentENID: number;
    OrderNumber: number;
    IsEnabled: boolean;
}
export interface AddEditEmailTemplateParams {
    ETID: number;
    ETTypeID: number;
    ETName: string;
    ETDescription: string;
    ETIID: number;
    LanguageID: number;
    ETSubject: string;
    ETContent: string;
    ETAdminContent: string;
    ETAdminSubject: string;
    BOEmails: string;
    ClientBOEmails: string;
    ETIsEnabled: boolean;
}
export interface ClaimBonusParams {
    CompanyName: string;
    ServerName: string;
    ServerType: string;
    Login: string;
    Amount: string;
}
export interface AddBonusCreditParams {
    CompanyName: string;
    ServerName: string;
    ServerType: string;
    ServerID: number;
    AccountID: number;
    Login: string;
    Amount: number;
    Currency: string;
    ClientID: string;
    Comment: string;
    MPID: number;
    BonusType?: string;
}
export interface GetIBsTableResult {
    ClientRangeID: number;
    Name: string;
    Wallet: string;
    IBCode: string;
    ClientID: string;
    MasterIB: string;
    CreationDateStr: string;
    CreationDate: Date | string | null;
    IBLevels: number;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface IBAccountInfo {
    ServerID: number;
    AUID: string;
    PoolIDs: string;
    IBs: string;
    AccountID: number;
    SubAccountID: number;
    IBAccountID: number;
    LinkedAccountID: number;
    Levels: number;
    UserWLs: string;
    CommissionType: number;
    IsDirect: number;
    Symbol: string;
    IBCode: string;
    GroupsName: string;
    Page: number;
    FromDay: number;
    ToDay: number;
    FromMonth: number;
    ToMonth: number;
    FromYear: number;
    ToYear: number;
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
    ToDateString: string;
}
export interface LoadIBWalletResult {
    WalletID: string;
    ClientID: string;
    ClientRangeID: string;
    WalletRangeID: string;
    WalletCurrency: string;
    IBCode: string;
    FullName: string;
}
export interface IBAccountClass {
    WalletID: string;
    ClientID: string;
    MasterClientID: string;
    ServerID: number | null;
    Logins: string;
    Groups: string;
}
export class Wallet {
    ClientID: string = "";
    CurrencyID: number = 0;
    ServerID: number | null = -1;
    MirroredLogin: string = "";
    IsAutoSync: boolean = false;
}
export class ActionCreateClientClass {
    Client: ActionClient = new ActionClient();
    Contact: ActionContact = new ActionContact();
    Trader: ActionTraderClass | null = new ActionTraderClass();
    LicPerms: ActionPermsLic[] = [];
    CompanyID: string = "";
    Password: string | null = "";
    IsLicensed: boolean = false;
    Wallet: Wallet | null = new Wallet();   //new interface publicLicPerms: PermsLic[];
}
export interface GetAccCurrencyResultClass {
    AccCurrencyID: number;
    AccCurrency: string;
}
export class WalletRanges {
    WRID: number = 0;
    WRDescription: string = '';
    WRStart: number = 0;
    WREnd: number = 0;
    WRCurrent: number = 0;
    PoolID: number = 0;
}
export interface LoadTraderClientListResult {
    ClientRangeID: number;
    ClientID: string;
    text: string;
    PoolID: number;
}
export interface GetWalletsTableResult {
    WalletID: string;
    ClientID: string;
    ClientName: string;
    WalletRangeID: string;
    WalletCurrency: string;
    PoolName: string;
    ClientRangeID: string;
    Balance: number;
    Digits: number;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface WalletCurrency {
    PoolID: number;
    CurrencyIDs: string; //all checked currency separated by comma
    CurrencyPerm: boolean;
}
export interface EmailNotificationStatus {
    ENID: number;
    IsEnabled: boolean;
}
export interface PushNotificationStatus {
    PNID: number;
    IsEnabled: boolean;
}
export interface GetIBDetailedReportTableResult   //remove email field
{
    ClientName: string;
    ClientID: number;  // changed from AccountID -> ClientID
    IBCode: string;
    PoolIDs: string;
    CountryIDs: string;
    Wallet: string;  //changed from Account -> Wallet
    NumberOfSubIBs: number | null;
    IndirectComm: number | null;
    DirectComm: number | null;
    TotalComm: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface EditRefferalID {
    AccountID: number;
    ClientID: string;
}
//Function name: GetEditAccType
export interface GetAccTypeResult {
    AccType: string;
    Description: string;
    ByPassAccReq: boolean;
    DeleteAccOnClose: boolean;
    ByPassAdminReq: boolean;
    IsDemo: boolean;
    DemoAccBalance: number;
    ByPassNewAcc: boolean;
    ByPassAccInfo: boolean;
    ByPassDeleteAcc: boolean;
    ByPassAutoCopy: boolean;
    ByPassWithdrawal: boolean;
    ByPassDeposit: boolean;
    ByPassCredit: boolean;
    ByPassTransfer: boolean;
    ByPassExternalTransfer: boolean;
    AllowMultipleAccReq: boolean;
    AllowDeposit: boolean;
    AllowTransfer: boolean;
    AllowCloseAcc: boolean;
    AllowWithdrawal: boolean;
    AllowAccNmbr: number;
    AccTypePaymentSett: AccTypePaymentSetting[];
}
//Function Name: AddAccType  and  UpdateAccType
export interface AccTypesClass {
    AccTypeID: number;
    ServerID: number;
    AccType: string;
    Description: string;
    ByPassAccReq: boolean;
    DeleteAccOnClose: boolean;
    ByPassAdminReq: boolean;
    IsDemo: boolean;
    DemoAccBalance: number;
    AUID: string;
    ByPassNewAcc: boolean | null;
    ByPassAccInfo: boolean | null;
    ByPassDeleteAcc: boolean | null;
    ByPassAutoCopy: boolean | null;
    ByPassWithdrawal: boolean | null;
    ByPassDeposit: boolean | null;
    ByPassCredit: boolean | null;
    ByPassTransfer: boolean | null;
    AllowDeposit: boolean | null;
    AllowWithdrawal: boolean | null;
    AllowTransfer: boolean | null;
    AccTypePaymentSett: AccTypePaymentSetting[];
}

export interface ClientAccTypePerms {
    AccTypeIDs: string;
    ContactID: string;
}
export interface DDLSidebar {
    Countries: GetCountriesResult[];
    AccTypes: AccTypes[];
}
export interface GetCountriesResult {
    CountryID: number;
    CountryCode: string;
    CountryName: string;
    CountryFlag: string;
    CountryNameCN: string;
    CountryPhone: string;
}
export interface AccTypes {
    AccTypeID: number;
    AccType: string;
}
export interface GetDemoDropDown {
    CurrencyList: GetAccCurrencyResult[];
    LeverageList: AccLeverages[];
}
export interface GetAccCurrencyResult {
    AccCurrencyID: number;
    AccCurrency: string;
}
export interface AccLeverages {
    AccLeverageID: number;
    AccLeverage: number;
    AccTypeID: number;
}
export interface LoadMMClientAccountsResult {
    Accounts: LoadMMClientAccounts[];
}
export interface LoadMMWallet {
    WalletID: string;
    WalletCurrency: string;
    IBCode: string;
}
export interface IBDetails {
    MasterClientID: string;
    WalletID: string;
    IBName: string;
    IBWallets: LoadIBWalletResult[];
}

export interface SignalsProfile {
    SCID: number;
    SCName: string;
    SCAmount: number;
    SCSymbols: string;
    SCSecurities: string;
}
export interface UpdateSignalsProfileRequest {
    ServerName: string;
    ServerType: string;
    AccountID: number;
    SignalID: number;
    IsEnabled: boolean;
    STPID: number;
    SignalProfile: SignalsProfile[];
    SignalPCommission: SignalsProfile[];
    SignalIsEnabled: boolean;
}
export interface AddSignalsAccProfileRequest {
    ServerName: string;
    ServerType: string;
    WalletID: string | null;
    AccountID: number;
    IsEnabled: boolean;
    IsRoundVol: boolean;
    STPID: number;
    SignalProfile: SignalsProfile[];
    SignalPCommission: SignalsProfile[];
    PFAgents: any[];


}
export interface ActivationClass {
    Email: string;
    FullName: string;
}
//#region Wallet
export interface GetWalletTransactionDataRequest {
    ClientID: string;
    AUID: string;
    WalletID: string;
    IsWallet: boolean | null;
}

export interface GetWalletTransactionDataResult {
    InternalLogins: WalletLogins[];
    ExternalLogins: WalletLogins[];
    MyWallet: MyWallet;
    walletPayments: WalletPayments[];
    BankAccountList: any[];
    PoolID: number;
}
export interface MyWallet {
    Currency: string;
    Wallet: string;
}
export interface WalletLogins {
    AccountID: number;
    Login: string;
}

export interface WalletPayments {
    PaymentName: string;
    PMDescription: string;
    Instruction: string;
    PMID: number;
    PaymentIcon: string;
    Currencies: string;
    IsDeposit: boolean;
    PoolID: boolean;
}
export interface GetPaymentCurrenciesResult {
    CurrencyID: number;
    AccCurrency: string;
    CreationDate: Date | string;
    DWRID: number;
    PMID: number;
    Rate: number;
    IsDefault: boolean;
}
export interface PaymentRequest {
    PMID: number;
    AccountID: number;
    BID: number;
    ClientID: string;

}
//#endregion

export interface LoadSignalsClientAccountsResult {
    Accounts: LoadSignalClientAccounts[];
    Wallet: LoadSignalWallet[];
}

export interface LoadSignalWallet {
    WalletID: string;
    WalletCurrency: string;
    IBCode: string;
}
export interface LoadSignalClientAccounts {
    AccountID: number;
    Login: string;
}
export interface SignalConfigurationsResult {
    configurations: SignalConfigurations[];
    wallets: LoadWallets[];
    STPS: LoadSTPValuesResult[];
    Signal: SignalRes;
}
export interface SignalRes {
    SignalIsEnabled: boolean;
    IsRoundVol: boolean;
}
export interface SignalConfigurations {
    SCID: number;
    SignalID: number;
    SCName: string;
    SCSymbols: string;
    SCSecurities: string;
    SCAmount: number;
}
export interface LoadWallets {
    WalletID: string;
    WalletCurrency: string;
    IsSelected: boolean;
}
export interface SignalConfigurationsRequest {
    ClientID: string;
    SignalID: number;
}
export interface GetMyWalletsListResult {
    WalletID: string;
    Balance: number;
    Digits: number;
    ClientID: string;
    AccCurrencyIcon: string;
    WalletLabel: string;
    AllowDeposit: boolean;
    AllowTransfer: boolean;
    AllowWithdrawal: boolean;
}
export interface IMessage {
    title: string;
    content: string;
}
export interface GetWalletTransactionHistoryTableResult {
    TransactionType: string;
    TicketID: number;
    Wallet: string;
    AccCurrency: string;
    Amount: string;
    Comment: string;
    WTCreationDate: boolean;
    rowTot: number;
    rowFilter: number;
    rn: number | null;
}
export interface GetMyWalletPaymentsResult {
    PaymentName: string;
    PMDescription: string;
    Instruction: string;
    PMID: number;
    PaymentIcon: string;
    Currencies: string;
    HasTTCopy: boolean;
}
export interface WalletTransactionReq {
    IsFromWallet: boolean;
    IsToWallet: boolean;
    IsAdmin: boolean;
    Status: number;
    ClientID: string;
    FromWalletID: string;
    PMID: number;
    ReqType: string;
    ToWalletID: string;
    ToAccID: number;
    Login: string;
    CurrencyID: number;
    Amount: number;
    OrderID: string;
    Currency: string;
    Comment: string;
    Note: string;
    ReqStatus: number;
    // Payments
    PaymentName: string;
    IsBankWire: number;
    BankName: string;
    BID: number;
    BankAddress: string;
    AccountNumber: string;
    BeneficiaryAddress: string;
    BeneficiaryName: string;
    IBAN: string;
    Swift: string;
    IntermediaryBank: string;
    CorrespondentBank: string;
    ID: string;
    ttCopy: string;
    BAID: number;
    Email: string;
}
//Payments
export interface SticpayDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    Note: string;
    isWallet: boolean;
    ttCopy: string | null;
}
//#endregion
export interface GetWalletFilterOnTableResult {
    Pools: Pools[];
    Currencies: Currencies[];
    Clients: Clients[];
}
export interface Pools {
    PoolName: string;
    PoolID: number;
}
export interface Currencies {
    AccCurrency: string;
    AccCurrencyID: number;
}
export interface Clients {
    ClientRangeID: number;
    ClientID: string;
}
export interface MT4TradeService {
    ServerName: string;
    MT4TradeID: number;
    ServerID: number;
    Ticket: number;
    Login: string;
    Symbol: string;
    Digits: number;
    cmd: number;
    Volume: number;
    Opentime: Date | string;
    OpenPrice: number;
    SL: number;
    TP: number;
    CloseTime: Date | string;
    Expiration: Date | string;
    Reason: number;
    ConvRate1: number;
    ConvRate2: number;
    Commission: number;
    CommissionAgent: number;
    Swaps: number;
    ClosePrice: number;
    Profit: number;
    Taxes: number;
    Comment: string;
    InternalID: number;
    MarginRate: number;
    TimeStamp: number;
    Magic: number;
    GWVolume: number;
    GWOpenPrice: number;
    GWClosePrice: number;
    ModifyTime: Date | string;
}
export interface MT5PositionService {
    ServerName: string;
    MT5PositionsID: number;
    ServerID: number;
    Position: number;
    ExternalID: string;
    Login: string;
    Dealer: number;
    Symbol: string;
    Action: number;
    Digits: number;
    DigitsCurrency: number;
    Reason: number;
    ContractSize: number;
    TimeCreate: Date | string;
    TimeUpdate: Date | string;
    TimeCreateMsc: Date | string;
    TimeUpdateMsc: Date | string;
    PriceOpen: number;
    PriceCurrent: number;
    PriceSL: number;
    PriceTP: number;
    VolumeExt: number;
    Profit: number;
    Storage: number;
    RateProfit: number;
    RateMargin: number;
    ExpertID: number;
    ExpertPositionID: number;
    Comment: string;
    ActivationMode: number;
    ActivationTime: number;
    ActivationPrice: number;
    ActivationFlags: number;
    Volume: number;
    APIData: string;
}
export interface RegisterDemoAccClass {
    FirstName: string; //from redux
    LastName: string; //from redux
    CountryID: number | null; //from redux
    PhoneNumber: string; //from redux
    Email: string; //from redux
    AccTypeID: number;
    CurrencyID: number;
    LeverageID: number;
    ReferralID: string;
}
export interface GetDemoAccountsTableResult {
    AccType: string;
    Login: string;
    Leverage: number;
    Balance: number;
    Currency: string;
    DACreationDate: Date | string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface loadTradeHistoryTableResult {
    Symbol: string;
    Swaps: number | null;
    Commission: number | null;
    Profit: number | null;
    //NetPips: number | null;
    LongLots: number | null;
    ShortLots: number | null;
    TotalLots: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface LoadAllTradersTableResult {
    ClientName: string;
    ClientID: string;
    Login: string;
    SignalID: number;
    AccountID: number;
    Equity: number | null;
    Balance: number | null;
    CopierID: number | null;
    CopyVal: number | null;
    CopyStat: string;
    AutoCopy: string;
    Digits: number;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface CloseTrades {
    Order: number;
    Deal: number;
    TradeDuration: string;
    Type: string;
    MT4Type: string;
    Login: string;
    OpenTime: string;
    Entry: string;
    CloseTime: string;
    Symbol: string;
    Volume: number;
    Lots: number;
    OpenPrice: number;
    Price: number;
    ClosePrice: number;
    Swaps: number;
    Commission: number;
    commissions: number;
    //Pips: number;
    Profit: number;
    PriceDelta: number;
    Taxes: number;
    //ProfitPips: number;
}
//GetPendingTrades:
export interface OrdersTable {
    Deal: number;
    OpenTime: Date | string;
    OpenTimeStr: string;
    ClosedTime: Date | string;
    ClosedTimeStr: string;
    Type: string;
    Lots: number;
    Symbol: string;
    Price: number;
    StopLoss: number;
    TakeProfit: number;
    Gain: number;
    //Pips: number;
    CurrentPrice: number;
    //Placed: number;
    OpenPrice: number;
    ClosePrice: number;
}
export interface IBGroups {
    IBGroupsList: IBGroupsList[];
    ExcludedLogin: string[];
}
export interface IBGroupsList {
    IsSelected: boolean;
    GroupName: string;
}

export interface GetIBBannerLinks {
    BannerName: string;
    TypeName: string;
    BannerPath: string;
    Size: string;
    Type: string;
    BannerDoc: string;
    RegLink: string;
    IBCode: string;
    ReferralIDName: string;
    PoolID: any;
    YoonitURL: string;
    CustomLink: string;
}
export class DashboardItems {
    DIID: number = 0;
    ItemName: string = "";
    IsPinned: boolean = false;
    XAxis: number = 0;
    YAxis: number = 0;
}
export interface AllItem {
    ContactID: string;
    PinItem: PinItem[];
}
export interface PinItem {
    DIID: number;
    IsPinned: boolean | null;
    XAxis: number;
    YAxis: number;
}
//#region updateCompanySettings
export class LoadCompanyLogoResult {
    CompanyName: string = ''; //2za 7tejte bl saf7a to3rde l company name sta5dme hayda (if companyname is null or empty then 3rodi 2esem l sherke l 2elna ykon default)
    CompanySite: string = ''; //lama ya3mol click 3l image bya3mol redirect 3ala hayda l url
    IsCircular: boolean = false;
    LightLogo: string | null = null; // if null or empty 3rode logo l 2elna by default
    DarkLogo: string | null = null;
    Icon: string | null = null;
    SecondaryIcon: string | null = null;
}
export interface LogoSett {
    BinaryLightDocument: string;
    BinaryDarkDocument: string;
    BinaryIcon: string;
    BinarySecondaryIcon: string;
    IsCircular: boolean;
    JsonData: string;
}
export interface SMTPSett {
    Host: string;
    Port: number;
    EnableSSL: boolean;
    TestMail: string;
    Email: string;
    Password: string;
    BOEmail: string;
    EmailUserName: string;
    EmailDisplayName: string;
}
export interface PortalSett {
    CompanyAddress: string;
    CompanyName: string;
    CompanySite: string;
    CompanyPhone: string;
    ClientSite: string;
}
//#endregion
//GetBUTradingAccounts



/*return: */export interface GetBULoginsResult {
    Login: string;
    CountryCode: string;
    CountryName: string;
    AccountID: number;
    AllowDeposit: boolean;
    AllowTransfer: boolean;
    AllowWithdrawal: boolean;

}
//#region Activities 
export interface ActivityStorageResult {
    ActivityType: string;
    ActivityName: string;
    AUID: string;// who did the activity
    BUID: string; // who did the activity
    NoteID: string; // activity id
    TaskID: string;// activity id
    LeadID: string; // who is the activity done on
    ContactID: string;// who is the activity done on
    ClientID: string;// who is the activity done on
    IP: string;
    Email: string;
    ActivityTime: Date | string;
    ActivityTimeStr: string;
    ActivityDescription: string;
}
export interface GetActivity {
    OwnerID: string;  //ContactID or ClientID or LeadID
    OwnerType: string; //Contact or Client or Lead
}
//#endregion
export interface AccountDetailsResult {
    AccountDetails: Account;
}
export interface Account {
    Type: string;
    zipCode: string;
    ServerName: string;
    ServerID: string;
    Login: string;
    Name: string;
    Email: string;
    Phone: string;
    Address: string;
    Country: string;
    CountryCode: string;
    City: string;
    State: string;
    Zip: string;
    LeadDate: Date | string | null;
    CreationDate: Date | string | null;
    Equity: number | null;
    EquityUSD: number | null;
    Currency: string;
    GroupName: string;
    IBCode: string;
    IDNumber: string;
    LeadSource: string;
    MetaQuotesID: string;
    Status: string;
    AgentAccount: string;
    Margin: number | null;
    MarginLevel: number | null;
    FreeMargin: number | null;
    Credit: number | null;
    Balance: number | null;
    UnrealizedPL: number | null;
    Leverage: number | null;
    AccType: string;
    AccTypeID: string;
    MasterFloatingPnl: number | null;
    Digits: number;
}
export interface GetActiveInActiveClientsTableResult {
    ClientID: string;
    ClientRangeID: string; //column1
    ClientName: string; //column2
    PhoneNumber: string; //column3
    CountryName: string; //column4
    CreationDate: Date | string | null; //column5
    CreationDateStr: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
//#region chnage leverage 
export interface AccInfoReqClient { // using other fields in admin 
    ReqType: string; // send value ‘changeLeverage’
    ClientID: string;
    BUID: string;
    AccountID: number;
    AccLeverageID: number | null;
    AccLeverage: number;
    Status: number; //set to 0 
    IsAdmin: boolean;
}
export interface OperationReqSBReq {
    ReqID: number;
    RequestType: string;
}
export interface RequestsDataSBClient {
    Account: string;
    AccLeverage: string;
    OldAccLeverage: string;
}
export interface TraderRequestsClient {
    ReqType: string;
    ReqID: number;
    Status: number;
    Note: string;
}

//#endregion
//assgin client to contact 
export interface AssignContactToClient {
    ContactID: string;
    ClientID: string;
    Email: string;
}
export interface AllContact {
    ContactID: string;
    FirstName: string;
    LastName: string;
    Email: string;
}
export interface UserLicPassData {
    ContactID: string;
    Password: string;
    Email: string;
    Perms: ActionPermsLic[];
}
export interface InvestorTrackerResult {
    Login: string;
    InitialInvestment: number;
    InitialInvestmentDate: Date | string;
    Balance: number;
    Credit: number;
    Equity: number;
    AvailableMargin: number;
    FloatingPL: number;
    Status: string;
    Currency: string;
    Deposits: number;
    Withdrawals: number;
    RealisedPL: number;
    Name: string;
}


export interface GetClosedTradesFiltration {
    Servers: ServerDetails[];
    Accounts: Accounts[];
}

export interface Accounts {
    AccountID: number;
    Login: string;
}
export interface ClosedTrades extends TraderDataTable {
    AccountIDs: string;
    ServerIDs: string;
}
export interface TraderDataTable {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
    fromDate: Date | string;
    toDate: Date | string;
}
export interface OrdersTable {
    Account: string;
    Deal: number;
    OpenTime: Date | string;
    OpenTimeStr: string;
    ClosedTime: Date | string;
    ClosedTimeStr: string;
    Type: string;
    Lots: number;
    Symbol: string;
    Price: number;
    StopLoss: number;
    TakeProfit: number;
    Gain: number;
    //Pips: number;
    CurrentPrice: number;
    //Placed: number;
    OpenPrice: number;
    ClosePrice: number;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface GetInvestorClosedTradesByDealRequest {
    FromDate: string;
    ToDate: string;
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
    ARuleName: string;
    Deal: number;
    Login: string;
}
export interface OrdersTable {
    Account: string;
    Comment: string;
    Deal: number;
    OpenTime: Date | string;
    OpenTimeStr: string;
    ClosedTime: Date | string;
    ClosedTimeStr: string;
    Type: string;
    Lots: number;
    Symbol: string;
    Price: number;
    StopLoss: number;
    TakeProfit: number;
    Gain: number;
    //Pips: number;
    CurrentPrice: number;
    //Placed: number;
    OpenPrice: number;
    ClosePrice: number;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface SendUpdateAppFormReq {
    ClientID: string;
    IsAdmin: boolean;
}
export interface GetApplicationRequestsTableResult {
    ClientID: string;
    KYCID: number;
    UKYCID: number; // update approved
    ClientName: string;
    Country: string;
    CreationDate: Date | string | null;
    CreationDateStr: string;
    Type: string;
    RiskWarning: boolean | null;
    TotalScore: number | null;
    IsAdmin: boolean | null;
    Status: number | null;
    StatusTxt: string;
    Reason: string;
    VersionName: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface KYCApprvDecline {
    KYCID: number;
    ClientID: string;
    UserName: string;
    IsApproved: number;
    Note: string;
}
export interface PoolsResult {
    PoolID: number;
    PoolName: string;
    IsDefault: boolean | null;
    RequireUserActivation: boolean | null;
    CardValidation: boolean | null;
    AllowDeposit: boolean | null;
    AllowWithdrawal: boolean | null;
    AllowTransfer: boolean | null;
    ByPassWalletDeposit: boolean | null; // new field  (Label => ByPass Wallet Deposit Client Request)
    ByPassWalletWithdrawal: boolean | null; // new field  (Label => ByPass Wallet Withdrawal Client Request)
    ByPassWalletTransfer: boolean | null; // new field  (Label => ByPass Wallet Transfer Client Request)
    ByPassWalletAdminReq: boolean | null; // new field  (Label => ByPass Wallet Admin Request)
    ByPassWalletExternalTransfer: boolean | null;
    RiskStatementReminder: string; // new field Text Area (Label => Risk Statement Reminder) 
    AllowAddDemoAcc: boolean | null; // new field  (Label => Allow Client to Add Demo Account)
    AUID: string;
}
export interface GetPoolsTable {
    PoolID: number;
    PoolName: string;
    IsDefault: boolean | null;
    RequireUserActivation: boolean | null;
    CardValidation: boolean | null;
    ByPassWalletDeposit: boolean | null; // new field  
    ByPassWalletWithdrawal: boolean | null; // new field  
    ByPassWalletTransfer: boolean | null; // new field  
    ByPassWalletAdminReq: boolean | null; // new field  
    RiskStatementReminder: string; // new field  
    AllowAddDemoAcc: boolean | null; // new field  
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface WLAccSettings {
    ServerID: number;
    AccLeverage: AccLeverages[];
    IBCodeRanges: IBCodeRanges[];
    AccCurrency: Currency[];
    AccTypes: AccTypes[];
    LoginRanges: LoginRanges[];
    AllocRules: LoadAllocationRulesResult[];
    STPVals: LoadSTPValuesResult[];
    PFPeriods: PFPeriodResult[];
}
export interface PFPeriodResult {
    PFPID: number;
    PeriodValue: string;
}
export interface BURequest {
    ContactID?: string;
    BUID: string;
    ClientID?: string;
}
export interface ProfileInfoAction {
    ContactID: string;
    TimeZone: number;
    TimeZoneCode: string;
    Language: string;
}
export interface SubIBs {
    ClientName: string;
    IBCode: string;
    ClientID: string;
    ParentClientID: string;
}
export interface GetMMAccListResult {
    Login: string;
    ProfileID: number;
    text: string;
    rowFilter: number | null;
}
export interface GetMMAccListParams {
    SearchKey: string;
}
//ForcePerformanceFee
export interface PerfFeeClass {
    ProfileID: number;
    SlaveLogin: string;
    MasterLogin: string;
    MasterAccountID: number;
}
export interface ValidateUserClass {
    ContactID: string;
    IsValid: boolean;
    Email: string;
}
export interface NoteCommentClass {
    NoteID: number;
    ContactID: string;
    Comment: string;
    NoteTitle: string;
}
export interface NoteCommentGetclass {
    NCID: number;
    Comment: string;
    FullName: string;
    CreationDate: Date | string;
}
export class GetCompanyDataResult {
    CompanyID: string = '';
    CompanyCode: string = '';
    DefaultLanguage: string = '';
    PortalSite: string = '';
    HeaderTitle: string = '';
    CompanyName: string = '';
    versionKey: string = '';
    DefaultTheme: string = '';
    GoogleAdsCode: string = '';
    GoogleAnalyticsCode: string = '';
    GoogleAdsEventCode: string = '';
    CompanySite: string = '';
    YoonitGetURL: string = '';
    YoonitActionURL: string = '';
    CDNURL: string = '';
    BannerCDNURL: string = '';
    HandBookURL: string = '';
    SecretKey: string = '';
    SiteKey: string = '';
}
export interface LoginClientResponse {
    CompanyData: any;
    UserPermissions: IPerms[];
    Items: DashboardItems[];
    UserData: IUserData;
    Servers: any[];
    Language: string;
    isAuth: boolean;
    CompanyAuth: boolean;
    isTokenValid: boolean;
    isTokenExpired: boolean;
    isPrimarySalt: boolean;
    GUARules: any[];
    defaultpage: string;
    UserID: string;
    isAdmin: boolean;
    ClientData: any;
    AdminData: any;
}
export class GetDWInfoByAccountIDResult {
    Name: string = "";
    Balance: number = 0;
    FreeMargin: number = 0;
    Credit: number = 0;
    CurrencyID: number = 0;
    Currency: string = "";
    PMID: number = 0;
    PaymentName: string = "";
    IsDeposit: boolean = false;
}
export interface MAMSettingsClass {
    TradeComment: string;
    AccountComment: boolean | null;

}
export interface GetClientWalletsResult {
    WalletID: string;
    text: string;
    IsSelected: boolean;
}
export interface AgentAccClass {
    WalletID: string;
    PF: number;
}
export interface GetPAPFSBResult {
    PAID: number;
    WalletID: string;
    PAPF: number;
}
export class GetSAPFSBResult {
    WalletID: string = "";
    SAPF: number = 0;
    IsDefault: boolean = false;
}
export class GetSAPFSBResultClass extends GetSAPFSBResult {
    PAID: number = 0;
}
export interface LoginGroup {
    GroupName: string;
    Login: string;
}
export interface IMarginTiles {
    level: string;
    Trenches: string;
    Leverage: string;
}
export interface IPromoCode {
    Email: string;
    Login: string;
    ReferralPromoID: number;
}
export interface AllocateCashAccountParams {
    CPID: number;
    ServerID: number;
    ServerType: string;
    ServerName: string;
    Login: string;
    ExternalClientID: string;
}
export class GetCashPromotionClass {
    CPID: number = 0;
    Enabled: boolean = false;
    isMargin: boolean = false;
    Title: string = '';
    StartingDate: string = '';
    EndingDate: string = '';
    CashAmount: number = 0;
    CashLots: number = 0;
    CashScalping: number = 0;
    CurrencyID: number = 0;
    BonusComment: string = '';
}
export class ReferralPromotion {
    RPID?: number = 0;
    Enabled: boolean = false;
    Title: string = '';
    StartingDate: string = '';
    EndingDate: string = '';
    CashBackAmount: number = 0;
    LotAmount: number = 0;
    CashScalping: number = 0;
    BonusComment: string = '';
    CurrencyID: number = 0;
    ReferralNb: number = 0;
    RPConfig: RPConfig[] = [];

}
export class RPConfig {
    RPID?: number = 0;
    RPCID: number = 0;
    RPCName: string = '';
    RPCSymbols: string = '';
    RPCSecurities: string = '';
    RPCRatio: number = 1;
}
export interface GetReferralDetails {
    RPConfigListResult: RPConfig[];
    ReferralPromotionResult: ReferralPromotion;
}
export interface IGenerateClientPromoCode {
    ClientID: string;
    Email: string;
    Login: number;
}
export interface UpdateApplicationTypeResult {
    KTID: number;
    TypeName: string;
    RequireKYC: boolean | null;
    IsEnabledType: boolean | null;
    PoolID: number | null;
    KTCreationDate: Date | string | null;
    MainJson: string;
}
export interface UpdateApplicationFieldResult {
    KTID: number;
    MainJson: string;
}
export interface KYCInfoRequest {
    ClientID: string;
    KYCID: number;
}

export interface ClientAppFormDetailsResult {
    KYCID: number;
    Status: number;
    SubmittedJson: string;
    MainJson: string;
    AnswersJson: string;
}
export interface SubmitAppForm {
    ClientID: string;
    IsAdmin: boolean;
    Status: string;
    AdminUsername: string;
    SubmittedJson: string;
    Score: number;
    ExpDocsJson?: any;
}
export interface SubmitAppFormAnswers {
    KYCID: number;
    AnswersJson: string;
    ClientID: string;
}
export interface GetWalletSBResult {
    IsAutoSync: boolean;
    MirroredLogin: string;
    ServerID: number;
    ServerName: string;
    ServerType: string;
}
export interface EditWallet {
    ClientID: string;
    Currency: string;
    ServerID: number | null;
    MirroredLogin: string;
    IsAutoSync: boolean;
    WalletID: string;
}
export interface GetDocumentRequest {
    ClientID: string;
    DocName: string;
    DocType: string;
}
export interface AccTypePaymentSetting {
    APID: number;
    PMID: number;
    PaymentName: string;
    IsAutoApprove: boolean;
    MaxAmount: number;
    IsDeposit: boolean;
}
export interface GetIBTreePathes {
    Path: TreePath[];
    PathID: string;
}
export interface TreePath {
    Depth: number;
    ClientID: string;
    IBCode: string;
    ClientName: string;
}
export interface GetDefaultProfile {
    Path: TreePathDefaultProfile[];
    PID: number;
}
export interface TreePathDefaultProfile {
    Depth: number;
    ClientID: string;
}
//#region payment
export interface TappayDepositParams {
    GUEmail: string;
    GUName: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    Note: string;
    isWallet: boolean;
    ttCopy: string | null;
}
export interface SkrillDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    fromEmail: string;
    Note: string;
    isWallet: boolean;
}
export interface EComPayDepositParams {
    paymentType: string;
    paymentText: string;
    companyName: string;
    token: string;
    merchant_id: string;
    depositAmount: number;
    depositCurrencyID: string;
    depositCurrencyText: string;
    language: string;
    display_text: string;
    txt1: string;
    test_mode: string;
    to_account: string;
    dep_paymentID: string;
    session_id: string;
    host: string;
    Note: string;
    isWallet: boolean;
    ttCopy: string | null;
}
export interface CachuDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    merchant_id: string;
    depositAmount: number;
    depositCurrencyID: string;
    depositCurrencyText: string;
    language: string;
    display_text: string;
    txt1: string;
    test_mode: string;
    to_account: string;
    dep_paymentID: string;
    session_id: string;
    isWallet: boolean;
    ttCopy: string | null;
}
export interface RPNP2PDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    depositAmount: number;
    to_account: string;
    dep_paymentID: string;
    host: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    paymentText: string;
    CardAccName: string;
    BankAccNumber: string;
    Note: string;
    isWallet: boolean;
    ttCopy: string | null;
}
export interface RPNCUPDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    depositAmount: number;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    dep_paymentID: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
}
export interface BridgerPayDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    Note: string;
    countryCode: string;
    isWallet: boolean;
    ttCopy: string | null;
}
export interface NetellerSafePayDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    fromEmail: string;
    Note: string;
    countryCode: string;
    IsWallet: boolean;
}
//#endregion
export interface ClientCount {
    TotalNumber: number;
    UsedNumber: number;
}
export interface CheckBulkClientResult {
    ExistDefaultPool: boolean;
    ExistRangeID: boolean;
    ClientsNbrExceedLimit: boolean;
    ClientCount: number;
    Message: string;
    ExistingLogins: GetServerDataByAccountIDResult[];
    NotExistingLogins: GetServerDataByAccountIDResult[];
    NotExistingPools: string[];
    InValidClients: InvalidClients[];
    ExistAccDiffExternal: GetServerDataByAccountIDResult[];
}
export interface GetServerDataByAccountIDResult {
    ServerName: string;
    ServerType: string;
    Login: number;
}
export interface CSVIBAccounts {
    CSVBinaryData: string;
    ClientID: string;
    IsCheck: boolean;
}
export interface WalletSyncTimeFrame {
    TimeFrame: number;
    IsAutoSync: boolean;
}
export interface GetWalletSyncTimeFrameDetail {
    TimeFrame: number;
    IsAutoSync: boolean;
}
export interface CSVClientAcc {
    CSVBinaryData: string;
    IsCheck: boolean;
}
export interface MAMMSettings {
    IsUserComment: boolean | null;
    MAMComment: string;
    SignalComment: string;
    OldIsUserComment: boolean | null;
    OldMAMComment: string;
    OldSignalComment: string;
}
export interface InvalidClients {
    Email: string;
    ExternalID: string;
}
export interface GetPriorityGroupPromotionsResult {
    PGID: number;
    PGName: string;
    PGPriority: number;
    Title: string;
    StartingDate: Date | string;
    EndingDate: Date | string;
}
export interface PriorityGroup {
    PGID?: number | null;
    PGName: string;
    PGPriority: number;
}
export interface GetRegistrationLinksTable {
    RTID: number;
    RTDescription: string;
    RegistrationURL: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface GetDynamicMarginOptionsResult {
    SecType: BasicSelectType[];
    VolType: BasicSelectType[];
    NOPTypes: BasicSelectType[];
}
export interface SecuritiesResult {
    SecurityID: number;
    ServerID: number | null;
    SecurityName: string;
    SecurityIndex: number | null;
}
//#region dynamic margin
export class AddProfileRequest {
    ProfileID: number = 0;
    ServerID: number = 0;
    SecTypeID: number = 0;
    VolTypeID: number = 0;
    ProfileEnabled: boolean = false;
    ProfileStrategyName: string = '';
    ProfileGroups: string = '';
    ProfileLogins: string = '';
    ProfileSymbols: string = '';
    ProfileSecurities: string = '';
    ProfileExpLimit: number = 0;
    ProfileHedgeCon: boolean = false;
    ProfileMargTiers: string = '';
    ProfilePending: boolean = false;
    ProfileOldTrades: boolean = false;
    ProfilePriority: number = 0;
    ProfileDefaultPref: boolean = false;
    ProfileFixedTiers: boolean = false;
    ProfilePendingMargin: boolean = false;
    PSessions: ProfileSesssions[] = [];
    ProfileIsDefaultMargin = false;
    ProfileIsOverrideTiers = false;
}
export interface ProfileSesssions {
    ServerID: number | null;
    SessionID: number | null;
    ProfileID: number | null;
    FromDay: number | null;
    ToDay: number | null;
    FromHour: number | null;
    ToHour: number | null;
    FromMinute: number | null;
    ToMinute: number | null;
    Tiers: string | null;
    TiersJson: string | null;
}
export interface LoadDyanmicTableResult {
    ProfileID: number;
    ServerID: number;
    SecTypeID: number;
    VolTypeID: number;
    ProfileEnabled: boolean;
    ProfileStrategyName: string;
    ProfileGroups: string;
    ProfileLogins: string;
    ProfileSymbols: string;
    ProfileSecurities: string;
    ProfileExpLimit: number;
    ProfileHedgeCon: boolean;
    ProfileMargTiers: string;
    ProfilePending: boolean;
    ProfileOldTrades: boolean;
    ProfilePriority: number;
    ProfileDefaultPref: boolean;
    ProfileFixedTiers: boolean;
    ProfilePendingMargin: boolean;
    OverrideSession: boolean;
}
export interface BulkProfilesRequestClass {
    ProfileIDs: string;
    UpdateProfileExpLimit: boolean;
    ProfileExpLimit: number;
    UpdateProfileOldTrades: boolean;
    ProfileOldTrades: boolean;
    UpdateProfileEnabled: boolean;
    ProfileEnabled: boolean;
    ProfileHedgeCon: boolean;
    UpdateHedgeControl: boolean;
}
export class AccountExposureRequestClass {
    AccExpID: number | null = 0;
    ServerID: number = 0;
    NTID: number = 0;
    MargTiers: string = "";
    SecTypeIDs: string = "";
    VolTypeID: number = 0;
    AccExpPending: boolean = false;
    AccExpEnabled: boolean = false;
    AccExpStrategyName: string = "";
    AccExpGroups: string = "";
    AccExpLogins: string = "";
    AccExpSymbols: string = "";
    AccExpSecurities: string = "";
    AccExpLimit: number = 0;
    AccExpHedgeCon: boolean = false;

    AccExpMargTiers: string = "";
    AccSecType: AccSecTypeClass[] = [];
    AccExpPriority: number = 0;
    AccExpOldTrades: boolean = false;
    AccExpFixedTiers: boolean = false;
    AccExpIsAccGr: boolean = false;
    AccExpIsOverrideTiers: boolean = false;
    AccExpSesssions: AccExpSesssions[] = [];
}
export interface AccExpSesssions {
    ServerID: number | null;
    SessionID: number | null;
    AccExpID: number | null;
    FromDay: number | null;
    ToDay: number | null;
    FromHour: number | null;
    ToHour: number | null;
    FromMinute: number | null;
    ToMinute: number | null;
    Tiers: string | null;
    TiersJson: string | null;
}
export interface AccSecTypeClass {
    AccExpID: number;
    SecTypeID: number;
    MargTiers: string;
}
export interface AccExp {
    AccExpID?: number;
    ServerID: number;
    NTID: number;
    MargTiers: string;
    SecTypeIDs: string;
    VolTypeID: number;
    AccExpPending: boolean;
    AccExpEnabled: boolean;
    AccExpStrategyName: string;
    AccExpGroups: string;
    AccExpLogins: string;
    AccExpSymbols: string;
    AccExpSecurities: string;
    AccExpLimit: number;
    AccExpHedgeCon: boolean;
    AccExpMargTiers: string;
    AccExpPriority: number;
    AccExpOldTrades: boolean;
    AccExpFixedTiers: boolean;
    AccExpIsAccGr: boolean;
    AccExpPendingMargin: boolean;
    AccExpIsOverrideTiers: boolean;
}
export interface BulkAccExpClass {
    AccountIDs: string;
    UpdateAccExpLimit: boolean;
    AccExpLimit: number;
    UpdateAccExpEnabled: boolean;
    AccExpEnabled: boolean;
    AccExpHedgeCon: boolean;
    UpdateHedgeControl: boolean;
}
export interface DMActivation {
    ServerID: number;
    ActivationLogins: string;
}
//#endregion


export interface TemplateClass {
    DocName: string;
    DocData: string;
}
export interface PFSettings {
    PFCalculationWay?: string;
}
export interface ClientListResult {
    ClientRangeID: number;
    ClientID: string;
    text: string;
    rowFilter?: number | null;
}
export interface Logos {
    LightLogo: string;
    DarkLogo: string;
    Icon: string;
    SecondaryIcon: string;
    IsCircular: boolean;
}
export class OperationLogDetails {
    //AutoCopy
    CopierAccount: string = "";
    MasterAccount: string = "";
    ARule: string = "";
    IsReverse: boolean = false;
    CopyIsReversed: number = 0;
    ReverseDateStr: string = "";

    //Deposit - Withdrawal
    PaymentMethod: string = "";
    BankName: string = "";
    BenifName: string = "";
    BenifAddress: string = "";
    BenifAccNumber: string = "";
    BankAddress: string = "";
    Swift: string = "";
    IBAN: string = "";
    ttCopy: string = "";
    ClientEmail: string = "";
    MerchantID: string = "";
    PaymentName: string = "";
    OrderID: string = "";
    //Withdrawal
    CardNumber: string = "";
    BankCurrency: string = "";
    BenifBankName: string = "";
    //account info
    OldAccType: string = "";
    AccType: string = "";
    AccountType: string = "";
    OldAccLeverage: string = "";
    AccLeverage: string = "";

    ttcopy: string = "";
    //Transfer
    ToAcc: string = "";

    //New Account
    CreationReason: string = "";
    ReferralCode: string = "";
    AccLeverageID: number = 0;
    AccCurrencyID: number = 0;
    AccTypeID: number = 0;
    ClientID: string = "";
    Email: string = "";
    Platform: string = "";
    ServerName: string = "";
    AutomaticLoginTxt: string = "";
    AccCurrency: string = "";


    //Admin Request
    AutomaticLogin: boolean = false;
    ServerID: number = 0;
    Login: number = 0;
    CountryName: string = "";
    NewLogin: boolean = false;

    //Common fields
    Account: string = "";
    Amount: number = 0;
    AmountCurrency: string = "";
    ConvertedAmount: number = 0;
    ConvertedAmountCurrency: string = "";
    Reason: string = "";
    StatuxTxt: string = "";
    CreationDateStr: string = "";
    ActionDateStr: string = "";
    Note: string = "";


    CardHolderName: string = "";
    Province: string = "";
    City: string = "";
    Branch: string = "";

    Name: string = "";
    Phone: string = "";
    Governorate: string = "";
}

export interface SendPassword {
    IsAdmin: boolean;
    Email: string;
    UserEmail: string;
    Password: string;
}
export interface PowercashDepositParams {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    Note: string;
    Email: string;
    IP: string;
    isWallet: boolean;
    ttCopy: string | null;
}
export interface RiskMeasuresRequest {
    AccountID: number;
    //Login: stringn req.login;as i
    Year: number;
    Months: string;
}
export class AddUpdateStopOutRequest {
    StopOutID: number = 0;
    ServerID: number = 0;
    StopOutEnabled: boolean = false;
    StopOutStrategyName: string = '';
    StopOutGroups: string = '';
    StopOutLogins: string = '';
    StopOutABook: boolean = false;
    StopOutHedge: boolean = false;
    ReadOnlyAccounts: boolean = false;
    StopOutPriority: number = 0;
}
export interface BulkUpdateStopOutRequestClass {
    StopOutIDs: string;
    UpdateStopOutEnabled: boolean;
    StopOutEnabled: boolean;
}
export interface LoadStopOutStopOutsResult {
    StopOutID: number;
    ServerID: number;
    StopOutEnabled: boolean;
    StopOutStrategyName: string;
    StopOutPriority: number;
}
export interface GetStopOutDetailsResult {
    ServerID: number;
    StopOutEnabled: boolean;
    StopOutStrategyName: string;
    StopOutGroups: string;
    StopOutLogins: string;
    StopOutABook: boolean;
    StopOutHedge: boolean;
    ReadOnlyAccounts: boolean;
    StopOutPriority: number;
}
export class AllCharts {
    BSTradesPerSymbol: DashboardBSTradesPerSymbol = new DashboardBSTradesPerSymbol();
    TradesPerSymbol: DashboardTradesPerSymbol[] = [];
    BestWorst: string = '';
    DepWith: string = '';
    StandardDeviationDownsideDeviation: string = '';
    AllocPerLogins: string = '';
    YTD: string = '';
    GainValue: number = 0;
    NetAssetValue: string = '';
    GainBody: string = '';
    VolumeAlloc: string = '';
    AvgDuration: string = '';
    WinLoose: string = '';
    NegativePositivePeriods: string = '';
    CumulativeROIPer: string = '';
    ExposureLevel: string = '';
    HistoricalPerformance: string = '';
    ROI: number = 0;
    AverageGain: number = 0;
    AverageLoss: number = 0;
    NetAssetNumber: number = 0;
    MeanReturn: number = 0;
    TraderPerformance: TraderPerformance = new TraderPerformance();
    TraderInstrumentsChart: TraderInstrumentsChart = new TraderInstrumentsChart();
    GetInstrumentsPieChart: InstrumentsPieChart[] = [];
}
export class TraderAccountSummary {
    Currency: string = '';
    AllTimePerfROI: number = 0;
    PL: number = 0;
    UnrealisedPL: number = 0;//equity - balace
    MaxDD: number = 0;
    SharpeRatio: number = 0;
    PipsMade: number = 0;
    Balance: number = 0;
    Equity: number = 0;
    Deposit: number = 0;
    Withdrawals: number = 0;
    AccountAgeDays: number = 0;
    ClosedTrades: number = 0;
    WinningTrades: number = 0;
    LosingTrades: number = 0;
    WinRatio: number = 0;
    AverageTradeLength: string = ''; /*1h 11m*/
    AvgTradesPerWeek: number = 0;
}
export class TraderPerformance {
    AccountSummary: TraderAccountSummary = new TraderAccountSummary();
    //ROIPipsChart: TraderROIPipsChart[]; pips removed 
    ROIPipsChartString: string = '';
}
export class TraderInstrumentsChart {
    Symbols: string[] = [];
    Profit: number[] = [];
    Loss: number[] = [];
}
export class InstrumentsPieChart {
    name: string = '';//Symbol
    y: number = 0;//SymbolPer
}
export interface LoadMarginBonusPromotionsResult {
    PID: number;
    Title: string;
    //GroupServerID?: number;
    //GroupServerType?: string;
    Countries?: string;
    Groups?: string;
    Referrals?: string;
    isAnd: boolean;
}
export interface IBManualSync {
    ServerID: number;
    From: Date | string;
    To: Date | string;
    ClientID: string | null;
    Type: string;
}
export interface IBManualSyncResult {
    IsSuccessed: boolean;
    FailureReason: string;
}
export interface AssignContactToClientMulti {
    ContactID: string;
    ClientID: string;
    Email: string;
    IsBulk: boolean;
}
export interface TransactionRate {
    FromCurrency: string;
    ToCurrency: string;
    PMID: number;
    AccountID: number;
}
//#region filter IbDetialedReport
export interface IBDetailedFiltrationResult {
    Pools: GetPoolsResult[];
    Countries: GetCountriesResult[];
    IBs: LoadIBWalletResult[];
}
//#endregion
export interface DeleteSignalConfigurationRequest {
    SCID: number;
    SignalID: number;
}
export interface CopiersData {
    //AutoCopy
    CopierAccount: string;
    MasterAccount: string;
    ARule: string;
    IsReverse: boolean;
    Amount: boolean;
    IsMinLot: boolean;
}
export interface GetSignalAccListResult {
    Login: string;
    Name: string;
    AccountID: number;
}
//#region PF 
export interface GetForcedPFValuesResult {
    Result: number;
    ManagerDate: Date | string;
    ToBeForced: RetMonthlyFee[];
    ToBeUpdated: MonthlyFees[];
    ForcedSlaves: ForcedSlaves;
}
export interface RetMonthlyFee {
    AccountID: number;
    ServerID: number;
    LiveEquity: number;
    LiveEquityDate: Date | string;
    ProfileID: number;
}
export interface MonthlyFees {
    ProfileID: number;
    Date: Date | string | null;
    AccountID: number;
    ServerID: number;
    PeriodGL: number;
    PF: number;
    NetReturn: number;
    NetReturnPer: number;
    BeginningNAV: number;
    EndingNAV: number;
    EndingGAV: number;
    HWM: number;
    CFloss: number;
    DW: number;
    InitialInvestments: number;
}
export interface ForcedSlaves {
    forcedSlaves: ActionSelectProfileSlavesResult[];
    MasterLogin: number;
    MasterAccountID: number;
    ServerID: number;
    ServerName: string;
    Type: string;
    ProfileID: number;
}
export interface ActionSelectProfileSlavesResult {
    AccountID: number;
    InvestorLogin: number;
}
//#endregion
export class ImmutableAccountInfoStates {
    manageIsReadOnly: boolean = false;
    manageName: string = "";
    manageEmail: string = "";
    managePhone: string = "";
    manageMTStatus: string = "";
    manageIDNumber: string = "";
    manageCountry: string = "";
    manageCity: string = "";
    manageState: string = "";
    manageAddress: string = "";
    manageZipCode: string = "";
    manageMetaQuotesID: string = "";
    manageTaxRate: string = "";
    manageLeadSource: string = "";
    manageComment: string = "";
    selectedLeverage: number = 0;
}
export interface BankAccount {
    BAID: number;
    PMID: number;
    PTID: number;
    BenifName: string;
    BenifBankName: string;
    BenifAddress: string;
    BenifAccNumber: string;
    BankName: string;
    BankAddress: string;
    SwiftCode: string;
    IBAN: string;
    Branch: string;
    BankCurrency: string;
    CardNumber: string;
    ClientEmail: string;
    CardHolderName: string;
    CreationDate: Date | string;
}
export class GetWalletInfoResult {
    IBWalletInfo: GetIBWalletInfo = new GetIBWalletInfo();
    WalletStrategies: string[] = [];
}
export class GetIBWalletInfo {
    Name: string = '';
    Wallet: string = '';
    IBCode: string = '';
    MasterIB: string = '';
}
export interface AccCurrency {
    AccountID: number;
    AccTypeID: number;
    Currency: string;
}
export interface EditInvestorOldLog {
    Login: string;
    ProfileID: number;
    AruleName: string;
    IsReverse: boolean;
    Amount: number;
    InvType: number;
}
export interface CashDepositParams {
    ttCopy: string;
    username: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    Note: string;
    isWallet: boolean;
}
export interface PFSettingsAction {
    Method: string;
    OldMethod: string;
    PFECSPnlSharePerm: boolean | null;
    OldPFECSPnlSharePerm: boolean | null;
}
export interface GetEmailLayoutResult {
    ELID: number;
    LanguageID: number;
    Content: string;
    BOEmails: string;
    ClientBOEmails: string;
    IsDefault: boolean;
    EmailTypeParams: GetEmailTypeParams[];
}
export interface EmailLayout {
    ELID?: number;
    LanguageID: number;
    Content: string;
    BOEmails: string;
    ClientBOEmails: string;
}
export interface GetEmailTemplateParamsResult {
    EmailTemplate: GetEmailTemplateResult;
    EmailTypeParams: GetEmailTypeParams[];
}
export interface GetEmailTypeParams {
    ParamName: string;
    ParamDescription: string;

}
export enum ERemovalBonusTypes {
    Off = 1,
    Date = 2,
    Days = 3

}
export interface ContactUsClass {
    ContactID: string;
    Message: string;
}
export interface GetPFMethodResult {
    PFCalculationWay: string;
    PFECSPnlSharePerm: boolean | null;
}
export interface GetFilledEmailLayout {
    Subject: string;
    LanguageID: number;
}
export interface ManagePoolBulkParams {
    PoolID: number;
    ClientIDs: string;
    Emails: string[];
}
export interface GetAccountprofile {
    AccountID: number;
    ClientID: string;
}
export enum EProfTypes {
    CTL = 1,
    CTP = 2,
    CPA = 3,
    CTSM = 4,
}
export enum EDistProfTypes {
    Pips = 1,
    USDPerLot = 2,
    Commission = 3,
    Markup = 4,
    Swaps = 5,
    FirstDeposit = 6,
    AnyDeposit = 7,
    BothOfThem = 8,
    CTPercents = 9
}
export interface DeleteIB {
    WalletID: string;
    ClientID: string;
}
//#region GBPrimeWithdrawal
export interface GBPrimePayWithdrawalParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    depositAmount: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    Note: string;
    isWallet: boolean;
    BankCode: string;
    BankAccount: string;
    AccountHolderName: string;
}
//#endregion
//#region  webhook
export interface WebHookData {
    WebHookTypes: WebHookTypes[];
    WebHookPayments: WebHookPayments[];
    WebHookSB: WebHookSB;
}
export interface WebHookTypes {
    WHTypeID: number;
    WHType: string;
}
export interface WebHookPayments {
    PMID: number;
    Payment: string;
    IsDeposit: boolean;
}
export interface WebHookSB {
    IsEnabled: boolean;
    Name: string;
    Token: string;
    Receiver: string;
    Target: string;
    WHTypeID: number;
    PMID: number;
    PaymentName: string;
}

export interface GetWebHookTableRequest {
    SearchTerm: string;
    OrderByTerm: string;
    OrderByDir: number;
    SkipTerm: number;
    TakeTerm: number;
}

export interface WHTableLoad {
    WHID: number;
    Target: string;
    Type: string;
    Name: string;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}

export interface AddUpdateWebHook {
    WHID: number;
    PMID: number;
    URL: string;
    key: string;
    Name: string;
    Receiver: string;
    IsEnabled: boolean;
    WHTypeID: number;
}
//#endregion
export interface B2BinpayDepositParams {
    isWallet: boolean;
    ttCopy: string;
    depositCurrencyID: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: string;
    depositCurrencyText: string;
    fullCurrencyNetworkText: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
}
export interface B2BinPayWithdrawalParams {
    walletAddress: string;
    walletID: string;
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    paymentText: string;
    withdrawalAmount: string;
    withdrawalCurrencyID: string;
    withdrawalCurrencyText: string;
    to_account: string;
    host: string;
    Note: string;
    isWallet: boolean;
    BankCode: string;
    BankAccount: string;
    AccountHolderName: string;
}
//#region EasyWire
export interface EasyWireDepositParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: string;
    depositCurrencyText: string;
    customerReference: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
}
//#endregion 
//#region easyWire
export interface EasyWireWithdrawalParams {
    BranchNumber: string;
    BankName: string;
    BankAccountNumber: string;
    paymentType: string;
    companyName: string;
    token: string;
    key: string;
    dep_paymentID: string;
    withdrawalAmount: string;
    withdrawalCurrencyText: string;
    CustomerName: string;
    withdrwalAccountType: string;
}
//#endregion

export interface AssignClientOwner {
    ContactID: string;
    ClientID: string; //it can be multiple client id
}
//#endregion
//#region B2B
export const B2B_PAYMENTS = [
    { "label": "Bitcoin", "value": "BTC", "parent": "BTC" },
    { "label": "Litecoin", "value": "LTC", "parent": "LTC" },
    { "label": "Dash", "value": "DASH", "parent": "DASH" },
    { "label": "Bitcoin Cash", "value": "BCH", "parent": "BCH" },
    { "label": "Monero", "value": "XMR", "parent": "XMR" },
    { "label": "Ripple", "value": "XRP", "parent": "XRP" },
    { "label": "Dogechain", "value": "DOGE", "parent": "DOGE" },
    { "label": "Zcash", "value": "ZEC", "parent": "ZEC" },
    { "label": "Stellar", "value": "XLM", "parent": "XLM" },
    { "label": "TRON", "value": "TRX", "parent": "TRX" },
    { "label": "TRON", "value": "USDC-TRX", "parent": "USDC" },
    { "label": "TRON", "value": "USDT-TRX", "parent": "USDT" }
];
//#endregion

//#region Dynamic MArgin 
export class TierClass {
    SessionID: number = 0;
    ProfileExpLimit: string | number = '';
    levels: string | number = 0;
    MarginTiles: any /*IMarginTiles[]*/ = [];
    MarginTiers: any = '';
    FromDay: number = 0;
    ToDay: number = 0;
    FromHour = "00";
    FromMinute = "00";
    ToHour = "00";
    ToMinute = "00";
    isEdit = false;
};
export interface DMProfilesData {
    DMProfiles: GetDyanmicMarginProfilesDetailsResult;
    DMSessions: DMSessionsResult[];
}
export interface GetDyanmicMarginProfilesDetailsResult {
    ProfileID: number;
    ServerID: number;
    SecTypeID: number;
    VolTypeID: number;
    ProfileEnabled: boolean;
    ProfileStrategyName: string;
    ProfileGroups: string;
    ProfileLogins: string;
    ProfileSymbols: string;
    ProfileSecurities: string;
    ProfileExpLimit: number;
    ProfileHedgeCon: boolean;
    ProfileMargTiers: string;
    ProfilePending: boolean;
    ProfileOldTrades: boolean;
    ProfilePriority: number;
    ProfileDefaultPref: boolean;
    ProfileIsOverrideTiers: boolean;
    ProfileIsDefaultMargin: boolean;
}
export interface DMSessionsResult {
    SessionID: number;
    FromDay: number;
    FromHour: number;
    FromMinute: number;
    ToDay: number;
    ToHour: number;
    ToMinute: number;
    Tiers: string;
    TiersJson: string;
    isEdit: boolean;
}
export interface GetDyanmicMarginAccountDetailsResult {
    AccExp: AccExp;
    AccSecType: AccSecTypeClass[];
    DMSessions: DMSessionsResult[];

}
//#endregion
export interface BecomeAnIBOrMAM {
    ClientName: string;
    ClientRangeID: string;
    ClientEmail: string;
    Type: string;
}
export interface DusuPayDepositParam {
    ttCopy: string;
    country: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    AccountName: string;
    AccountEmail: string;
    MobileAccountNumber: string;
    Description: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    PaymentMethod: string;
    ProviderID: string;
}
export interface SurepayDepositParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    customerID: string;
    depositAmount: number;
    to_account: string;
    dep_paymentID: string;
    host: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    CustomerName: string;
}
export interface StripeDepositParams {
    isWallet: boolean;
    ttCopy: string;
    depositCurrencyID: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: string;
    depositCurrencyText: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
}
export interface Fast2paysDepositParam {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    UserID: string;
    BankCode: string;
    DepositType: string;
}
export interface NineParams {
    ttCopy: string;
    ClientID: string;
    country: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: string;
    depositCurrencyText: string;
    customerReference: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    CryptoCurrencyCode: string;
}
export interface NinePayValidateAccountParam {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    BankCode: string;
    AccountNumber: string;
    AccountType: number;
}
export interface Match2PayDepositParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    CryptoCurrencyCode: string;
    PaymentGatewayName: string;
    CustomerReference: string;
}
export interface Help2PayDepositParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: string;
    depositCurrencyText: string;
    customerReference: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    BankCode: string;
}
export interface FeeAgentsClass {
    ManagementWalletID: string;
    ManagementPer: number;
    RegistrationAmount: string;
    RegistrationWalletID: number;
}
export interface GetFeesSBResult {
    FAID: number;
    ManagementWalletID: string;
    RegistrationWalletID: string;
    ManagementPer: number | null;
    RegistrationAmount: number | null;
}
export interface BigPayWithdrawalParams {
    paymentType: string;
    companyName: string;
    token: string;
    key: string;
    dep_paymentID: string;
    withdrawalAmount: number;
    currencyText: string;
    Host: string;
    BankNameDest: string;
    BankAccountNumberDest: string;
    BankAccountNameDest: string;
    CustomerRefernce: string;
    Order: string;
}
export interface CapitalWalletDepositResponse {
    WalletAddress: string;
    DepositCryptoAmount: string;
    IsSuccess: boolean;
    Message: string;
}
export interface GateToPayDeposit {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    CustomerReference: string;
    CardExpirationDate: string;
    OTP: string;
    OrderID: string;

}
export interface NaceDeposit {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    CryptoCurrencyText: string;
}
export enum EPayments {
    TapPay_KNET = 'tappay-knet',
    TapPay_CreditCard_USD = 'tappay-creditcard-usd',
    TapPay_CreditCard_KWD = 'tappay-creditcard-kwd',
    TapPay_KNET_WG = 'tappay-knet-wg',
    TapPay_Creditcard_KWD_WG = 'tappay-creditcard-kwd-wg',
    TapPay_Creditcard_USD_WG = 'tappay-creditcard-usd-wg',
    TapPay_Creditcard_USD_WG_ِِA = 'tappay-creditcard-usd wg_a',
    SticPay = 'sticpay',
    SKRILL = 'skrill',
    SKRILL_USD = 'skrill-usd',
    SKRILL_GBP = 'skrill-gbp',
    SKRILL_EUR = 'skrill-eur',
    SKRILL_JPY = 'skrill-jpy',
    RPN_P2P = 'rpn-p2p',
    PaySafeNeteller = 'paysafeneteller',
    GateToPay = 'gatetopay',
    GateToPay_A = 'gatetopay_a',
    FasaPay = 'fasapay',
    Alogateway = 'alogateway',
    NACE = 'nace',
    BankWire = 'bankwire',
    BankWire_A = 'bankwire_a',
    Bankwire2 = 'bankwire2',
    Bankwire2_A = 'bankwire2_a',
    Cash = 'cash',
    NETELLER = 'neteller',
    NowPayments = 'nowpayments',
    myfatoorah = 'myfatoorah-knet',
    myfatoorah_a = 'myfatoorah_a-knet',
    myfatoorah_creditcard = 'myfatoorah-credit card',
    myfatoorah_a_creditcard = 'myfatoorah_a-credit card',
    ONE_GLOBAL = 'oneglobal',
    ONE_GLOBAL_KNET = 'oneglobal-knet',
    HESABE = 'hesabe',
    HESABE_KNET = 'hesabe-knet',
    HESABE_WG = 'hesabe-wg',
    HESABE_WG_A = 'hesabe-wg_a',
    Hesabe_Knet_WG_A = 'hesabe-knet-wg_a',
    HESABE_KNET_WG = 'hesabe-knet-wg',
    virtualpay = 'virtualpay',
    VIRTUALPAY_CARD = 'virtualpay-card',
    VIRTUALPAY_MOBILE = 'virtualpay-mobile',
    NGN_BANK_TRANSFER = 'ngnbanktransfer',
    DUSUPAY = 'dusupay',
    DUSUPAY_BANK = 'dusupay-bank',
    DUSUPAY_MOBILE = 'dusupay-mobile',
    ASIA_PAYMENT = 'asiapayment',
    UPay = 'UPay',
};
export const MARKET_EQUITY_PAYMENT_POOLS: any = {
    DefaultPool: [
        EPayments.NGN_BANK_TRANSFER,
        EPayments.SticPay,
        EPayments.SKRILL,
        EPayments.SKRILL_EUR,
        EPayments.SKRILL_GBP,
        EPayments.SKRILL_USD,
        EPayments.SKRILL_JPY,
        EPayments.PaySafeNeteller,
        EPayments.GateToPay,
        EPayments.FasaPay,
        EPayments.BankWire,
        EPayments.Bankwire2,
        EPayments.NETELLER,

        EPayments.TapPay_KNET,
        EPayments.TapPay_CreditCard_USD,
        EPayments.TapPay_CreditCard_KWD,


        EPayments.Cash,
        EPayments.ONE_GLOBAL,
        EPayments.ONE_GLOBAL_KNET,
        EPayments.HESABE,
        EPayments.HESABE_KNET,

    ],
    DefaultWG: [
        EPayments.SticPay,
        EPayments.SKRILL,
        EPayments.SKRILL_EUR,
        EPayments.SKRILL_GBP,
        EPayments.SKRILL_USD,
        EPayments.SKRILL_JPY,
        EPayments.PaySafeNeteller,
        EPayments.GateToPay,
        EPayments.FasaPay,
        EPayments.BankWire,
        EPayments.Bankwire2,
        EPayments.NETELLER,

        EPayments.TapPay_KNET_WG,
        EPayments.TapPay_Creditcard_USD_WG,
        EPayments.TapPay_Creditcard_KWD_WG,



        EPayments.Cash,
        EPayments.ONE_GLOBAL,
        EPayments.ONE_GLOBAL_KNET,

        EPayments.HESABE_WG,
        EPayments.HESABE_KNET_WG,
    ],
    ROW: [
        EPayments.SticPay,
        EPayments.SKRILL,
        EPayments.SKRILL_EUR,
        EPayments.SKRILL_GBP,
        EPayments.SKRILL_USD,
        EPayments.SKRILL_JPY,
        EPayments.PaySafeNeteller,
        EPayments.GateToPay,
        EPayments.FasaPay,
        EPayments.BankWire,
        EPayments.Bankwire2,
        EPayments.NETELLER,

        EPayments.TapPay_CreditCard_USD,
        EPayments.SticPay,

        EPayments.RPN_P2P,
        EPayments.Alogateway,
        EPayments.NACE,
        EPayments.myfatoorah,
        EPayments.myfatoorah_creditcard,


        EPayments.NowPayments,
        EPayments.virtualpay,
        EPayments.VIRTUALPAY_CARD,
        EPayments.VIRTUALPAY_MOBILE,

        EPayments.DUSUPAY,
        EPayments.DUSUPAY_BANK,
        EPayments.DUSUPAY_MOBILE,
    ],
    GCC: [
        EPayments.TapPay_CreditCard_USD,
        EPayments.SticPay,
        EPayments.SKRILL,
        EPayments.PaySafeNeteller,
        EPayments.GateToPay,
        EPayments.FasaPay,
        EPayments.myfatoorah,
        EPayments.myfatoorah_creditcard,
        EPayments.BankWire,
        EPayments.NETELLER,
        EPayments.HESABE_WG,
        EPayments.VIRTUALPAY_CARD,
    ],
    FEE_FREE: [
        EPayments.myfatoorah_a,
        EPayments.myfatoorah_a_creditcard,
        EPayments.TapPay_Creditcard_USD_WG_ِِA,
        EPayments.HESABE_WG_A,
        EPayments.Hesabe_Knet_WG_A,
        EPayments.GateToPay_A,
        EPayments.BankWire_A,
        EPayments.Bankwire2_A,
    ],
    IB_USER: [
        EPayments.BankWire,
        EPayments.Bankwire2,
        EPayments.SKRILL,
        EPayments.SKRILL_EUR,
        EPayments.SKRILL_USD,
        EPayments.SKRILL_GBP,
        EPayments.SKRILL_JPY,
        EPayments.PaySafeNeteller,
        EPayments.NETELLER,
        EPayments.SticPay,
        EPayments.FasaPay,
        EPayments.GateToPay,
       
    ],
};
export const IQX_PAYMENT_POOLS: any = {
    PH_Default: [EPayments.ASIA_PAYMENT] // withdrawal wallet
};
export enum E_IQX_ACCOUNT_TYPES {
    PH_Standard = 13,
    PH_ECN = 14,
    PH_Markup_1 = 15,
}
export enum E_IQX_POOLS {
    PH_Default = 9,
}
export enum EMARKET_EQUITY_POOLS {
    DefaultPool = 1,
    DefaultWG = 7,
    ROW = 6,
    IB_USER = 3,
    Bonus = 4,
    GCC = 8,
    FEE_FREE = 9,
}
export interface NaceDepositResponse {
    address: string;
    price: number;
}
export interface MyFatoorahPaymentMethod {
    PaymentMethodId: number;
    PaymentMethodAr: string;
    PaymentMethodEn: string;
    PaymentMethodCode: string;
    IsDirectPayment: boolean;
    ServiceCharge: number;
    TotalAmount: number;
    CurrencyIso: string;
    ImageUrl: string;
    IsEmbeddedSupported: boolean;
    PaymentCurrencyIso: string;
}
export interface MyFatoorahDeposit {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
    PaymentMethodId: number;
    CustomerName: string;
}
export interface DepositBaseParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: number;
    depositCurrencyText: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
}
export interface DepositEmailParams extends DepositBaseParams {
    Email: string;
}
export interface FlutterwaveBank {
    id: number;
    code: string;
    name: string;
}
export interface FlutterwaveGetParams {
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    countryCode: string;
}
export interface HesabeDepositParams extends DepositBaseParams {
    IsKent: boolean;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
}
export interface EUPaymentzDepositParams extends DepositBaseParams {
    Email: string;
    FirstName: string;
    LastName: string;
    Address: string;
    City: string;
    ZipCode: string;
    State: string;
    Country: string;
    Phone: string;
}
export class SignalRSingleton {
    private static instance: SignalRSingleton;

    constructor() {
        if (SignalRSingleton.instance) {
            throw new Error("Error - use Singleton.getInstance()");
        }
        this.inProgress = false;
        this.token = '';
        this.failedInRowCount = 0;
    }
    static getInstance(): SignalRSingleton {
        SignalRSingleton.instance = SignalRSingleton.instance || new SignalRSingleton();
        return SignalRSingleton.instance;
    }
    inProgress: boolean;
    token: string;
    failedInRowCount: number;
}
export interface CFXDepositParams {
    ttCopy: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    depositAmount: string;
    depositCurrencyText: string;
    customerReference: string;
    depositCurrencyID: string;
    to_account: string;
    host: string;
    email: string;
    cryptoCurrency: string;
    paymentText: string;
    Note: string;
    isWallet: boolean;
}
//#region UPay
export interface PaymentSettingsReq {
    host: string;
    paymentText: string;
    paymentType: string;
    to_account: string;
    token: string;
    dep_paymentID: string;
    companyName: string;
    depositAmount: string;
    isWallet: boolean;
    ttCopy: string;
    Note: string;
    depositCurrencyID: string;
    depositCurrencyText: string;
}
export interface UPaySupportChainsRes {
    chainName: string;
    chainFullName: string;
}
export interface UPayWalletAddressesReq extends PaymentSettingsReq {

    customerId: string;
    chainName: string;
    mt5Id: string;
}
export interface UPayWalletAddressesRes {
    chainName: string;
    address: string;
    merchantOrderID: string;
    result: string;
}

export interface UPayDepositRes {
    customerId: string;
    orderId: string;
    orderType: string;
    chainName: string;
    txHash: string;
    amount: number;
    status: string;
    createdAt: string;
    secretKey: string;
}
export interface CreateWithdrawalOrderReq extends PaymentSettingsReq {
    customerId: string;
    orderRef: string;
    chainName: string;
    toAddress: string;
    amount: number;
}
export interface CreateWithdrawalOrderResponse {
    id: string;
    orderRef: string;
    merchantCode: string;
    customerId: string;
    toAddress: string;
    amount: number;
    status: string;
    portaStatus: number;
    createdAt: string;
}

//#endregion

//#region NGN BANK TRANSFER
export interface NGNBankTransferInitiatePaymentReq extends PaymentSettingsReq {
    merchantTransactionID?: string;
    currencyCode: string;
    requestAmount: number;
    countryCode: string;
    accountNumber: string;
    dueDate: string;
    requestDescription?: string;//optional
    customerFirstName: string;
    customerLastName: string;
    msisdn: string;
    customerEmail: string;
    //payment settings
    serviceCode?: string;
    paymentWebhookUrl?: string;
    successRedirectUrl?: string;//optional
    FailRedirectUrl?: string;//optional


}
export interface NGNStatus {
    statusCode: number;
    statusDescription: string;
}
export interface NGNResults {
    checkoutRequestID: number;
    merchantTransactionID: string;
    originalCurrencyCode: string;
    convertedCurrencyCode: string;
    requestAmount: number;
    convertedAmount: number;
    conversionRate: number;
    paymentOptions: PaymentOption[];
}


export interface NGNBankTransferInitiateResponse {
    resp: NGNBankTransferInitiateResp;
    referenceKey: string;
}
export interface NGNBankTransferInitiateResp {
    statusCode: number;
    statusDescription: string;
    checkoutRequestID: number;
    merchantTransactionID: string;
    originalCurrencyCode: string;
    convertedCurrencyCode: string;
    requestAmount: number;
    convertedAmount: number;
    conversionRate: number;
    paymentOptions: PaymentOption[];
}
export interface NGNBankTransferChargeReq extends PaymentSettingsReq {
    merchantTransactionID?: string;
    checkoutRequestID: number;
    chargeMsisdn: string;
    chargeAmount: number;
    currencyCode: string;
    payerModeID: number;
    payerMode: string;
    languageCode?: string;//"en"


    //to Send to backoffice
    countryCode: string;
    accountNumber: string;
    customerFirstName: string;
    customerLastName: string;
    msisdn: string;
}
export interface QueryRequestParameters {
    merchantTransactionID: string;
    serviceCode: string;
    checkoutRequestID: string;
}
export interface NGNBankTransferQueryResponse {
    statusCode: number;
    statusDescription: string;
    checkoutRequestID: number;
    merchantTransactionID: string;
    requestStatusCode: number;
    MSISDN: number;
    serviceCode: string;
    serviceName: string;
    accountNumber: string;
    amountPaid: number;
    requestAmount: number;
    paymentCurrencyCode: string;
    requestCurrencyCode: string;
    requestDate: string;
    session: string;
    redirectURL: string;
    redirectTrigger: string;
    shortUrl: string;
    payments: Payment[];
    failedPayments: Payment[];
    rejectedPayments: Payment[];
    paymentInstructions: string;
    offline: boolean;
    customerNationalID: string;
    customerPassportNumber: string;


}
export interface NGNBankTransferAcknowledgeRequest {
    checkoutRequestID: number;
    merchantTransactionID: string;
    statusCode: number;
    statusDescription: string;
    receiptNumber: string;
    currencyCode: string;
    acknowledgeAmount: number;
}
export interface NGNBankTransferInitiateResponse {
    resp: NGNBankTransferInitiateResp;
    referenceKey: string;
}
export interface NGNBankTransferChargeResponse {
    portalStatus: number;
    statusCode: number;
    statusDescription: string;
    checkoutRequestID: number;
    merchantTransactionID: string;
    chargeRequestID: number;
    paymentInstructions: string;
    languageCode: string;
    chargeMsisdn: number;
    chargeAmount: number;
    chargeRequestDate: string;
    thirdPartyResponse: ThirdPartyResponse;
    paymentRedirectUrl: string;
}
export interface ThirdPartyResponse {
    tinggVirtualAccountID: string;
    tinggAccount: string;
    tinggBank: string;
    tinggName: string;
}
export interface PaymentOption {
    paymentModeName: string;
    paymentModeID: number;
    serviceCode: string;
    payerClientName: string;
    payerModeID: number;
    paymentOptionCode: string;
    payerClientCode: string;
    countryCode: string;
    clientLogo: string;
    serviceName: string;
    minChargeAmount: number;
    maxChargeAmount: number;
    currencyCode: string;
    paymentInstructions: string;
    languageCode: string;
}
export interface NGNBankAcknowledgementResponse {
    StatusCode: number;
    StatusDescription: string;
    CheckoutRequestID: number;
    MerchantTransactionID: string;
    RequestStatusCode: number;
    Payments: Payment[];
}


export interface Payment {
    paymentID: number;
    checkoutRequestID: number;
    cpgTransactionID: string;
    payerTransactionID: string;
    mSISDN: number;
    accountNumber: string;
    customerName: string;
    amountPaid: number;
    merchantReceipt: string;
    payerNarration: string;
    receiverNarration: string;
    hubOverallStatus: number;
    statusCodeDesc: string;
    currencyID: number;
    payerClientCode: string;
    payerClientName: string;
    ownerClientCode: string;
    ownerClientName: string;
    ownerClientDisplayName: string;
    merchantTransactionID: string;
    convertedAmount: number;
    totalPayableAmount: number;
    datePaymentReceived: Date | string;
    datePaymentAcknowledged: Date | string | null;
    dateCreated: Date | string;
    currencyCode: string;
    countryCode: string;
}
//#endregion
export interface PaymentRes<T> {
    portalStatus: number;
    Result: T;
}
export interface DusuPayGetMethodsParam {
    country: string;
    paymentType: string;
    companyName: string;
    token: string;
    dep_paymentID: string;
    host: string;
    paymentMethod: string;
}
export interface SandboxTestAccounts {
    success: string | null;
    failure: string | null;
}

export interface IDusupayBankOption {
    id: string | null;
    name: string | null;
    transaction_currency: string | null;
    min_amount: number | null;
    max_amount: number | null;
    available: boolean | null;
    bank_code: string | null;
    sandbox_test_accounts: SandboxTestAccounts | null;
}
//#region Signals-PF
export class StringSelectOption {
    value: string = "";
    label: string = "choose";
}
export interface GetSignalMonthlyFeesTableResult {
    Digits: number;
    PFDate: Date | string;
    CopierLogin: string;
    CFloss: number | null;
    ProfitPF: number | null;
    ChargedPF: number | null;
    rowTot: number | null;
    rowFilter: number | null;
    rn: number | null;
}
export interface GetSignalCopierlistResult {
    Login: string;
    AccountID: number;
    SignalID: number;
    text: string;
}
//#endregion
//#region  AsiaPayment
export interface AsiaPaymentDepositParameters extends PaymentSettingsReq {
    merchant_reference: string;
    currency: string;
    amount: string;
    sign: string;
    return_url: string;
    customer_ip: string;
    customer_first_name: string;
    customer_last_name: string;
    customer_address: string;
    customer_phone: string;
    customer_email: string;
    customer_state: string;
    customer_country: string;
    network: string;
}

export interface AsiaPaymentWithdrawalPayout {
    request_reference: string;

    beneficiary_first_name: string;
    beneficiary_last_name: string;

    beneficiary_name: string;

    bank_name: string;
    beneficiary_email: string;
    beneficiary_city: string;
    beneficiary_phone: string;
    beneficiary_identity_card: string;
    account_number: string;
    currency: string;
    amount: number;
    datafeed_url: string;
    sign: string;
}

//#endregion

//#region WhishMoney
export interface WhishMoneyGetRate extends PaymentSettingsReq {
    amount: number;
    currency: string;
}
export interface WhishMoneyCollectRequest extends PaymentSettingsReq {
    amount: number;
    currency: string;
    externalId?: number | null;
    invoice?: string | null;
    successCallbackUrl?: string | null;
    failureCallbackUrl?: string | null;
    successRedirectUrl?: string | null;
    failureRedirectUrl?: string | null;
}
export interface WhishMoneyGetRateClientResponse {
    rate?: number | null;
    IsSuccess: boolean;
    Message?: string | null;
}
//#endregion
//#region Suyool
export interface SuyoolRequest extends PaymentSettingsReq {
    Amount: number;
    Currency: string;
    TranID?: string;
    TranTS?: string;
    MerchantAccountID?: number;
    CallBackURL?: string;
    TS?: number;
    AdditionalInfo?: string;
    SecureHash?: string;
    Browsertype: string;
    currentUrl: string;
    isMobile: boolean;
}
//#endregion